import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";

export const EmailVerification = createAsyncThunk(
  "login_registration/EmailVerification",
  async (otp, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/customer-email-verification",
        otp
      );
      return response.data;
    } catch (errors) {
      if (errors.response) {
        return rejectWithValue(errors.response.data);
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const LoginRegistration = createAsyncThunk(
  "login_registration/LoginRegistration",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/customer-registration",
        userData
      );
      return response.data;
    } catch (errors) {
      if (errors.response) {
        return rejectWithValue(errors.response.data);
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);
export const LoginUser = createAsyncThunk(
  'login_registration/login',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/login', userData);
      const token = response.data.Login.token;
      localStorage.setItem('token', token);
      // window.location.reload();
      return response.data;
    } catch (errors) {
      if (errors.response) {
        return rejectWithValue(errors.response.data); // Return error response data
      }
      return rejectWithValue('An error occurred while processing your request.');
    }
  }
);

export const forgetemailaddress = createAsyncThunk(
  "login_registration/forgetemailaddress",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/forgot-password",
        email
      );
      return response?.data?.ForgotPassword;
    } catch (errors) {
      if (errors.response) {
        return rejectWithValue(errors.response.data); // Return error response data
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);
export const forgetemailotppassword = createAsyncThunk(
  "login_registration/forgetemailotppassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/verify-otp",
        data
      );
      return response.data;
    } catch (errors) {
      if (errors.response) {
        return rejectWithValue(errors.response.data); // Return error response data
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);