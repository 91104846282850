import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "../utils/ProtectedRoute";
import Address from "../pages/afterlogin/AddressBook";
import Dashboard from "../pages/afterlogin/Dashboard";
import TrackShipment from "../pages/afterlogin/TrackShip";
import OrderConfirmation from "../pages/OrderConfirmation";
import ServicePage from "../pages/withoutlogin/ServicePage";
import ContactUs from "../pages/ContactUs";
import FAQquestionPage from "../pages/FAQquestionPage";
import Profile from "../pages/afterlogin/profile/Profile";
import MultiShipment from "../pages/afterlogin/multiShipment/MultiShipment";
import PickupandDrop from "../pages/footerPages/pickupAndDrop/PickupandDrop";
import Error404 from "../pages/Error404";
import Error500 from "../pages/Error500";
import CartPreview from "../pages/afterlogin/cartPreview/CartPreview";
import BulkOrders from "../pages/afterlogin/bulkOrders/BulkOrders";
import OrderPreview from "../pages/afterlogin/cartPreview/OrderPreview";
import BulkOrderPreview from "../pages/afterlogin/bulkOrders/BulkOrderPreview";
import Canada from "../pages/footerPages/DeliveryTo/Canada";

const LsnRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<Navigate to="/page-not-found" />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/admin-login/:id" element={<Dashboard />} />
        <Route path="/address" element={<ProtectedRoute> <Address /> </ProtectedRoute>} />
        <Route path="/trackshipment" element={<TrackShipment />} />
        <Route path="/footer/trackshipment" element={<TrackShipment />} />
        <Route path="/orderconfirmation" element={<ProtectedRoute> <OrderConfirmation /> </ProtectedRoute>} />
        <Route path="/user" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />
        <Route path="/user/:section" element={<ProtectedRoute> <Profile /> </ProtectedRoute>} />
        <Route path="/user/myorders" element={<ProtectedRoute> <Profile />  </ProtectedRoute>} />
        <Route path="/user/myorders/:id" element={<ProtectedRoute>  <Profile />  </ProtectedRoute>} />
        <Route path="/cartpreview" element={<ProtectedRoute>  <CartPreview />  </ProtectedRoute>} />
        <Route path="/multishipment" element={<MultiShipment />} />
        <Route path="/footer/multishipment" element={<MultiShipment />} />
        <Route path="/orderpreview" element={<ProtectedRoute>  <OrderPreview />  </ProtectedRoute>} />
        <Route path="/bulk-orders" element={<ProtectedRoute> <BulkOrders />  </ProtectedRoute>} />
        <Route path="/bulkorderPreview" element={<ProtectedRoute> <BulkOrderPreview /> </ProtectedRoute>} />
        <Route path="/service" element={<ServicePage />} />
        <Route path="/pickup-drop-locations" element={<PickupandDrop />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/footer/:id" element={<Canada />} />
        <Route path="/footer/faq-questions" element={<FAQquestionPage />} />
        <Route path="/page-not-found" element={<Error404 />} />
        <Route path="/server-error" element={<Error500 />} />
        
        {/* <Route path="/package-delivery-to-united-kingdom" element={<UnitedKingdom />} />
        <Route path="/package-same-day-delivery" element={<SameDayDelivery />} />
        <Route path="/package-next-day-delivery" element={<NextDayDelivery />} /> */}
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} /> */}
        {/* <Route path="/faq-questions" element={<FAQquestionPage />} /> */}
        {/* <Route path="/package-delivery-to-united-states-of-america" element={<UnitedStatesOfAmerica />} /> */}
        {/* <Route path="/cookies-Page" element={<CookiesPage />} /> */}
        {/* <Route path="/packaging-guidelines" element={<PackagingGuidelines />} /> */}
        {/* <Route path="/prohibited-items" element={<ProhibitedItems />} /> */}
        {/* <Route path="/weighing-measuring" element={<WeighingandMeasuring />} /> */}
        {/* <Route path="/our-couriers" element={<OurCouriers />} /> */}
        {/* <Route path="/package-delivery-to-india" element={<India />} /> */}
        {/* <Route path="/package-delivery-to-germany" element={<Germany />} /> */}
        {/* <Route path="/package-delivery-to-russia" element={<Russia />} /> */}
        {/* <Route path="/package-delivery-fedEx" element={<FedEx />} /> */}
        {/* <Route path="/package-delivery-UPS" element={<UPS />} /> */}
        {/* <Route path="/package-express-delivery" element={<ExpressDelivery />} /> */}
        {/* <Route path="/door-step-pickup" element={<DoorStepPickupPage />} /> */}
      </Routes>
    </>
  );
};

export default LsnRoutes;
