import React, { useEffect, useState, useMemo } from "react";
import { Container } from "react-bootstrap";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqsDetails } from "../redux/landing/landingThunk";
import "../assets/styles/FAQquestionPage.css";
import { Box, Skeleton } from "@mui/material";
import Button from "react-bootstrap/Button"; // Import Button properly from react-bootstrap

const FAQquestionPage = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [filteredFaqs, setFilteredFaqs] = useState([]); // State to hold filtered FAQs

  const FAQquestion = useSelector(
    (state) => state.landingReducer?.landingFaqsDetails?.data
  );
  const FAQquestionLoading = useSelector(
    (state) => state.landingReducer?.faqLoading
  );

  useEffect(() => {
    dispatch(fetchFaqsDetails());
  }, [dispatch]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term on typing
    if (e.target.value === "") {
      setFilteredFaqs(FAQquestion); // Show all FAQs when input is empty
    }
  };

  // Handle search button click
  const faqsearch = () => {
    if (searchTerm === "") {
      setFilteredFaqs(FAQquestion); // Show all FAQs when search term is empty
    } else {
      const filtered = FAQquestion?.filter((faq) =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredFaqs(filtered); // Filter the FAQs based on search term
    }
  };

  // Ensure filteredFaqs is set to all FAQs initially
  useEffect(() => {
    if (FAQquestion) {
      setFilteredFaqs(FAQquestion);
    }
  }, [FAQquestion]);

  const [expandedItems, setExpandedItems] = useState([]);

  // Update expandedItems when filteredFaqs changes
  useEffect(() => {
    setExpandedItems(new Array(filteredFaqs?.length).fill(false));
  }, [filteredFaqs]);

  const toggleExpand = (index) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.map((item, i) => (i === index ? !item : false))
    );
  };

  return (
    <div className="faq-main-holder py-5">
      <Container className="px-4 px-sm-0 px-lg-0">
        <div className="faqfolder">
          {!FAQquestionLoading ? (
            <>
              {filteredFaqs && (
                <div className="d-flex flex-column gap-3">
                  <h3 className="footer-mobile-head m-0">
                    Frequently Asked Questions
                  </h3>
                  <div  className="d-flex align-items-center justify-content-center w-100">
                    <div className="w-25  input-group" style={{ height: "50px" }}>
                      <input
                        type="text"
                        className="form-control  user-address-placeholder py-2 is-valid-paper position-relative  user-address-padding"
                        name="addressInput"
                        placeholder="Enter your text"
                        onChange={handleSearchChange}
                        value={searchTerm} // Sync with searchTerm state
                        aria-label="Text input with button"
                      />
                      <Button
                        style={{
                          right: "5px",
                          top: "5px",
                          zIndex: "999",
                          borderRadius: "6px",
                          height: "40px",
                        }}
                        className="blue-text primarybtn btn savebtn-user position-absolute"
                        onClick={faqsearch} // Trigger search on button click
                      >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <Accordion className="p-0">
                {filteredFaqs?.map((faq, index) => (
                  <Accordion.Item
                    className="shadow-none"
                    style={{ backgroundColor: "#f5f5f5" }}
                    key={faq.id}
                    eventKey={index}
                  >
                    <div className="faq-que-ans">
                      <Accordion.Header
                        className="py-3"
                        onClick={() => toggleExpand(index)}
                      >
                        <div className="faq-que">
                          <p className="paragraph1 faq-que">{faq.question}</p>
                        </div>
                        <div className="faq-icon">
                          <span className="add-icon">
                            {expandedItems[index] ? (
                              <RemoveCircleOutlineIcon
                                style={{ color: "#222222" }}
                              />
                            ) : (
                              <ControlPointIcon style={{ color: "#222222" }} />
                            )}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body style={{ padding: "0px 0px 0px 20px" }}>
                        <div className="faq-ans">
                          <p className="paragraph1 py-2">
                            <div
                              dangerouslySetInnerHTML={{ __html: faq.answer }}
                            />
                          </p>
                        </div>
                      </Accordion.Body>
                    </div>
                  </Accordion.Item>
                ))}
              </Accordion>
            </>
          ) : (
            <Box className="d-flex flex-column gap-3 w-100">
              <Box className="d-flex justify-content-center">
                <Skeleton
                  animation="wave"
                  className="w-50 height-44"
                  height="px"
                  variant="rounded"
                />
              </Box>
              <Box className="d-flex justify-content-center">
                <Skeleton
                  animation="wave"
                  className="w-25 height-44"
                  height="px"
                  variant="rounded"
                />
              </Box>
              {[...Array(5)].map((_, i) => (
                <Skeleton
                  key={i}
                  animation="wave"
                  className="w-100 height-44"
                  height="px"
                  variant="rounded"
                />
              ))}
            </Box>
          )}
        </div>
      </Container>
    </div>
  );
};

export default FAQquestionPage;
