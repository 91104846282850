
import React from "react";
// import { Button } from "react-bootstrap";
import "../../assets/styles/ShippingSmarter.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

const ShippingSmarter = () => {
  return (
    <>
      <div className="container p-0 d-flex justify-content-center ship-container">
        <div className="smartcard-holder">
          <div className="d-flex gap-2 gap-sm-3 smartercard position-relative" >
            <div className="" style={{ flex: "0" }}>
              <div className="ship-smart-rounddiv d-flex align-items-end align-items-lg-center">
                <div className="round"></div>
              </div>
            </div>
            <div className="my-lg-5 py-3 my-4 d-flex justify-content-center " style={{ flex: "8" }}>
              <div className="smart-next d-flex flex-column gap-3 ">
                <div className="smart-ship-ready-to-take">
                  <h5 className="">Ready to take your shipping to the next level?</h5>
                </div>
                <div className="w-75 smart-ship-get-start smarttext">
                  <p className="">
                    Get Started Now and Experience Seamless Global Shipping with Lets Ship Now
                  </p>
                </div>
                <div className="start-smart-btn m-0">
                  <Link to="/multishipment" >
                    <Button className="primarybtn btn start-smart text-nowrap">Start Shipping Smarter</Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-xxl-block d-xl-block d-lg-block d-md-block d-none  ship-smart-boy-holder" style={{ flex: "5" }}>
              <div className="smartdiv">
                <div className="smartimage">
                  <img className="position-absolute bottom-0 ship-smart-img" alt="shipping-smart-boy" src={`${window.location.origin}/assets/smartboy.png`} />
                </div>
              </div>
            </div>
            <div className=" d-xxl-none d-xl-none d-lg-none d-md-none d-sm-flex d-flex h-auto align-items-center " style={{ flex: "0" }}>
              <div className="h-50 d-flex align-items-end">
                <div className="round2">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingSmarter;
