import axios from 'axios';
import { navigateToHome } from '../utils/navigation';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     if (error?.response?.data?.code === 401 && error?.response?.data?.status === "fail") {
//       localStorage.removeItem('token');
//       // localStorage.removeItem("user");
//       localStorage.removeItem("cartdetail_id");
//       localStorage.removeItem("cart_id");

//       navigateToHome();

//       return Promise.reject(error);
//     }

//   }
// );

export default axiosInstance;
