import React from 'react'
import { Button, Card, Container } from 'react-bootstrap'
import "../../assets/styles/failedPayment.css"
import { Modal } from 'react-bootstrap';
import { useModel } from '../../utils/modalContext';

const FailedPayment = ({ failpayment }) => {
    const { showFailedPaymentModal, setShowFailedPaymentModal } = useModel();


    const handleTryAgain = () => {
        setShowFailedPaymentModal(false);
    };

    return (
        <div className='failedPaymentComponent'>
            <>
                <div className='failed-payment'>
                    <Container>
                        <Modal show={showFailedPaymentModal}
                            onHide={() => setShowFailedPaymentModal(false)}
                            backdrop="static"
                            keyboard={false}
                            className='failed-payment-modal'>

                            <Modal.Body className='mt-2 mt-lg-5 p-0'>
                                <div className='px-4 px-lg-5 py-4 py-lg-5 d-flex flex-column-reverse flex-lg-row gap-2' style={{ backgroundColor: "white", borderRadius: "10px" }}>
                                    <div className='failedpayment-text-left d-flex flex-column gap-2 justify-content-center' style={{ flex: "3" }}>
                                        <div className=' blue-text'>
                                            <h4 className='m-0'>Payment failed</h4>
                                        </div>
                                        <div className=' blue-text fail-payment-text'>
                                            <p className='paragraph2'>We apologize, but it seems there was an issue with processing your payment. Kindly review the payment information and card details you provided.</p>
                                        </div>
                                        {failpayment?.length && <div className=' blue-text fail-payment-text ' >
                                            <p className='paragraph2' style={{ color: 'red' }}>{failpayment}</p>
                                        </div>}
                                        <div className='mt-2 mt-lg-5'>
                                            <Button className='primarybtn tryagainbtn px-4 py-2' onClick={handleTryAgain}>
                                                Try Again
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='payment-fail-image ' style={{ flex: "2" }}>
                                        <div style={{ height: "100%" }}>
                                            <img className='img-fluid' src={`${window.location.origin}/assets/payment_failed.svg`} alt="Payment Failed" />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
            </>
        </div>
    )
}

export default FailedPayment