import React, { useEffect, useRef, useState } from 'react';
import "../../../assets/styles/deliveryToPlaces/unitedStatesOfAmerica.css";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHerosectionDetails, fetchfooterdata } from '../../../redux/landing/landingThunk';
import { Box, Skeleton } from '@mui/material';
import HeroSection from '../../../components/hero_section/HeroSection';

const Canada = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const data = useSelector((state) => state?.landingReducer?.footerData?.StaticPage?.data?.description);
  const dataCountry = useSelector((state) => state?.landingReducer?.footerData?.StaticPage?.data?.title);
  const footerLoading = useSelector((state) => state?.landingReducer?.isFooterLoading);
  const params = useParams();

  const [packageTo, setPackageTo] = useState()
  const handleClick = (event) => {
    event.preventDefault();
    const url = event.currentTarget.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];

    const allowedBaseUrls = [
      'http://localhost:3000',
      'https://letsshipnow.com',
      'https://letsshipnow.cerebulb.com'
    ];

    const isAllowedBaseUrl = allowedBaseUrls.some(baseUrl => url.startsWith(baseUrl));

    if (isAllowedBaseUrl) {
      navigate(`/footer/${lastPart}`);
    } else {
      window.location.href = url;
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(fetchfooterdata(params?.id)).unwrap()
      .then((response) => {
        if (response?.StaticPage?.data) {
          if (contentRef.current) {
            const content = contentRef.current.innerHTML;
            if (window.location.host === 'letsshipnow.cerebulb.com') {
              const updatedContent = content.replace(/https:\/\/letsshipnow\.cerebulb\.com\/backend\/images\/frontend\//g, 'https://letsshipnow.cerebulb.com/backend/images/frontend/');
              contentRef.current.innerHTML = updatedContent;
            }
            if (window.location.host === 'letsshipnow.com') {
              const updatedContent = content.replace(/https:\/\/letsshipnow\.com\/backend\/images\/frontend\//g, 'https://letsshipnow.com/backend/images/frontend/');
              contentRef.current.innerHTML = updatedContent;
            }

            const links = contentRef.current.querySelectorAll('a');
            links.forEach(link => {
              link.addEventListener('click', handleClick);
            });

            return () => {
              links.forEach(link => {
                link.removeEventListener('click', handleClick);
              });
            };
          }
        }
      });
    dispatch(fetchHerosectionDetails());
  }, [params?.id, dispatch]);

  useEffect(() => {
    if (dataCountry === "United states of america") {
      setPackageTo("United States")
    }
    else if (dataCountry === "Canada") {
      setPackageTo("Canada")
    }
    else if (dataCountry === "United Kingdom") {
      setPackageTo("United Kingdom")
    }
    else if (dataCountry === "India") {
      setPackageTo("India")
    }
    else if (dataCountry === "Germany") {
      setPackageTo("Germany")
    }
    else if (dataCountry === "Russia") {
      setPackageTo("Russia")
    }
  }, [dataCountry]);


  return (
    <>
      {(dataCountry === "United states of america" ||
        dataCountry === "Canada" ||
        dataCountry === "United Kingdom" ||
        dataCountry === "India" ||
        dataCountry === "Germany" ||
        dataCountry === "Russia") && (
          <>
            <HeroSection packageToFooter={packageTo} FooterHeroSection={true} />
          </>
        )}


      {footerLoading ? (
        <Container className='p-0'>
                <Skeleton animation="wave" className='w-100' height="300px" variant="rounded" />

          <div className='d-flex gap-4 py-5'>
            <Card className='card-border' style={{ flex: "3" }}>
              <Box className="p-3 d-flex flex-column gap-4 justify-content-center">
                <Skeleton animation="wave" className='w-100' height="100px" variant="rounded" />
                <Skeleton animation="wave" className='w-100' height="100px" variant="rounded" />
                <Skeleton animation="wave" className='w-100' height="100px" variant="rounded" />
                <Skeleton animation="wave" className='w-100' height="200px" variant="rounded" />
              </Box>
            </Card>
            <Card className='card-border' style={{ flex: "1" }}>
              <Box className="p-3 d-flex flex-column gap-4">
                <Box className="gap-3 w-100 d-flex justify-content-center flex-column gap-4">
                  <Skeleton animation="wave" className='w-100' height="300px" variant="rounded" />
                </Box>
              </Box>
            </Card>
          </div>
        </Container>
      ) : (
        <>
          <div ref={contentRef} dangerouslySetInnerHTML={{ __html: data }} />
        </>
      )}
    </>
  );
};

export default Canada;
