/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/addressBook/collectionDetails.css";
import { Container } from "react-bootstrap";
import AccordianAddressBook from "../AccordianAddressBook";

const AddressBook = () => {
  const addressBookRef = useRef(null);
  const hasShownAlert = useRef(false);
  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    // Check if the click was inside SweetAlert2 popup
    const isClickInsideSwal = event.target.closest('.swal2-container');
  
    // Check if the click was on the scrollbar
    const clickedOnScrollbar =
      event.clientX >= document.documentElement.offsetWidth ||
      event.clientY >= document.documentElement.offsetHeight;
  
    if (
      !clickedOnScrollbar && 
      addressBookRef.current &&
      !addressBookRef.current.contains(event.target) &&
      !isClickInsideSwal
    ) {
      if (!hasShownAlert.current) {
        hasShownAlert.current = true;
        const userConfirmed = window.confirm(
          "Your data will be lost if you leave this page. Do you want to proceed?"
        );
        if (userConfirmed) {
          navigate("/"); // replace '/another-page' with your target route
        } else {
          hasShownAlert.current = false; // Reset to allow future alerts
        }
      }
    }
  };
  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={addressBookRef} className="addressbook-outer-holder">
      <div className="background-holder">
        <div className="address-banner-bg">
          <Container className="px-4 px-sm-0 px-lg-0 px-xl-0">
            <div className="background-title">
              <h4>Address Details</h4>
            </div>
          </Container>
        </div>
      </div>
      <div className="address-component">
        <div className="py-5">
          <AccordianAddressBook />
        </div>
      </div>
    </div>
  );
};

export default AddressBook;
