import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';


// CustomEventIcon Component
const CustomEventIcon = ({ showIcon }) => {
    if (showIcon) {
        const iconClass = showIcon ? 'event-icon-visible' : 'event-icon-hidden';
        return <EventIcon className={iconClass} />;
    }
};

const DynamicDatePicker = ({ label, value, onChange, onClear, dateFormat, shouldDisableDate, shortDateError, incoShow, startDate, endDate }) => {
    const [showIcon, setShowIcon] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (!inputValue || inputValue.toString().trim() === '') {
            setShowIcon(true);
        } else {
            setShowIcon(false);
        }
    }, [inputValue]);

    const handleInputChange = (newValue) => {
        setInputValue(newValue);
        onChange(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box className="d-flex justify-content-start position-relative p-0 dynamic-custom" style={{ height: "44px" }}>
                <DatePicker
                    style={{ width: '100%', height: "100%" }}
                    className={`p-0 calender-child ${shortDateError && "is-invalid-paper"}`}
                    label={label}
                    value={value}
                    onChange={handleInputChange}
                    format={dateFormat}
                    shouldDisableDate={shouldDisableDate}
                    slots={{
                        openPickerIcon: () => <CustomEventIcon showIcon={showIcon} />,
                    }}
                    slotProps={{
                        field: {
                            clearable: true,
                            onClear: onClear,
                            sx: {
                                '& .MuiIconButton-root': { opacity: '1' },
                                '& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd': { display: !showIcon && "none" },
                                '& .MuiInputBase-root': { paddingRight: !showIcon ? "0px" : "14px" },
                            }
                        }
                    }}
                />
            </Box>
        </LocalizationProvider>
    );
}

export default DynamicDatePicker;
