import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { Container, Col, Row, Button, Carousel } from "react-bootstrap";
import "../assets/styles/services.css";
import { Link } from "react-router-dom";
import { Virtual, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useSelector } from "react-redux";

const Services = () => {
  const Landing_Services = useSelector(
    (state) => state.landingReducer?.landingServicesDetails?.data
  );
  const serviceTitle = useSelector(
    (state) => state.landingReducer?.homepageserviceTitle?.data
  );
  const appendNumber = useRef(3);
  const prependNumber = useRef(1);
  const [swiperRef, setSwiperRef] = useState(null);
  const [services, setServices] = useState();
  const [title, settitle] = useState();
  const [slides, setSlides] = useState(
    Array.from({ length: 3 }).map((_, index) => `Slide ${index + 1}`)
  );

  const toggleCard = (index) => {
    setServices((prevServices) =>
      prevServices.map((service) => {
        if (service.id === index) {
          return { ...service, isOpen: !service.isOpen };
        } else {
          return { ...service, isOpen: false };
        }
      })
    );
  };

  useEffect(() => {
    if (Landing_Services?.length > 0) {
      setServices(Landing_Services);
    }
    if (serviceTitle) {
      settitle(serviceTitle);
    }
  }, [Landing_Services, serviceTitle]);




  const prepend = () => {
    setSlides([
      `Slide ${prependNumber.current - 2}`,
      `Slide ${prependNumber.current - 1}`,
      ...slides,
    ]);
    prependNumber.current = prependNumber.current - 2;
    swiperRef.slideTo(swiperRef.activeIndex + 2, 0);
  };

  const append = () => {
    setSlides([...slides, 'Slide ' + ++appendNumber.current]);
  };

  const slideTo = (index) => {
    swiperRef.slideTo(index - 1, 0);
  };


  return (
    <Container className="service-container-holder ">
      <div className=" gap-4 services-card-holder service-card-responsive-display-card1 d-xl-flex  flex-lg-column flex-xl-column flex-nowrap gap-4 d-none d-sm-none d-lg-flex d-md-flex flex-md-column">
        <div>
          {title && (
            <>
              <h4 className="serviceh4 m-0">{title[0]?.heading}</h4>
              <h5 className="serviceh5 m-0">{title[0]?.description}</h5>
            </>
          )}
        </div>
        <div className="card-width flex-nowrap gap-4 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex ">
          {services?.map((data, index) => (
            <div key={data.id} className="card-holder" style={{ flex: "1" }}>
              {data.isOpen ? (
                <Card className={`after-services-card px-3 py-3 animate__animated ${data.isOpen ? "animate__pulse" : "animate__headShake"}`}>
                  <div className="card-icon " style={{ cursor: "pointer" }}>
                    <img onClick={() => toggleCard(data.id)} alt="close" src={`${window.location.origin}/assets/close.svg`} width="32" />
                  </div>
                  <div className="card-content">
                    <Card.Body className="card-body-page p-0 d-flex flex-column gap-3">
                      <Card.Title className="lh-5">
                        <Card.Text className="service-heading">
                          {data.heading}
                        </Card.Text>
                      </Card.Title>
                      <Card.Text className="service-description lh-5">
                        <p className="">
                          {data.description}
                        </p>
                      </Card.Text>
                      <div>
                        <Link to={data?.link}>
                          <Button className="primary_btn_link text-white text-nowrap">
                            {data?.link_text}
                          </Button>
                        </Link>
                      </div>
                    </Card.Body>
                  </div>
                </Card>
              ) : (
                <Card className={`services-cards px-3 py-3 animate__animated ${data.isOpen ? "animate__pulse" : "animate__headShake"}`}>
                  <div className="card-icon" style={{ cursor: "pointer" }}>
                    <img onClick={() => toggleCard(data.id)} src={`${window.location.origin}/assets/plus.svg`} width={32} height={25.78} alt="Toggle Icon" />
                  </div>
                  <div className="card-content">
                    <Card.Body className="p-0 d-flex flex-column gap-4">
                      <div>
                        <img className="card-center-icons " src={`data:image/svg+xml,${encodeURIComponent(data.icon)}`} width="70" alt="Icon" />
                      </div>
                      <div>
                        <Card.Text className="service-heading service-card-title">
                          {data.heading}
                        </Card.Text>
                      </div>
                      <div>
                        <Card.Text className="service-description">
                          <p className="">{data.short_description}</p>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </div>
                </Card>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="services-card-holder service-card-responsive-display d-xl-none d-lg-none d-md-none d-flex flex-column  pt-0 gap-4">
        <div>
          {title && (
            <>
              <div className="px-4">
                <h4 className="serviceh4 mb-0">
                  {title[0]?.heading}
                </h4>
                <p className="serviceh5">
                  {title[0]?.description}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="service-card-slider-holder">
          <Swiper
            modules={[Virtual, Navigation, Pagination]}
            onSwiper={setSwiperRef}
            slidesPerView={1}
            centeredSlides={true}
            spaceBetween={10}
            navigation={false}
            virtual >
            <Row className="card-width">
              {services?.map((data, index) => {
                return (

                  <Col key={data.id}>
                    <SwiperSlide key={index} virtualIndex={index}>
                      <div className="card-holder">
                        {data.isOpen ? (
                          <Card className={`after-services-card px-3 py-3 animate__animated ${data.isOpen ? "animate__pulse" : "animate__headShake "}`} >
                            <div className="card-icon " style={{ cursor: "pointer" }}>
                              <img onClick={() => toggleCard(data.id)} src={`${window.location.origin}/assets/close.svg`} width="32" />
                            </div>
                            <div className="card-content">
                              <Card.Body className="card-body-page">
                                <Card.Title>
                                  <Card.Text className="service-heading">
                                    {data.heading}
                                  </Card.Text>
                                </Card.Title>
                                <Card.Text className="service-description">
                                  <p className="">
                                    {data.description}
                                  </p>
                                </Card.Text>
                                <Link to={data?.link}>
                                  {data?.link_text}
                                </Link>
                              </Card.Body>
                            </div>
                          </Card>

                        ) : (
                          <Card className={`services-cards px-3 py-3 animate__animated ${data.isOpen ? "animate__pulse" : "animate__headShake "}`}  >
                            <div className="card-icon" style={{ cursor: "pointer" }}>
                              <img onClick={() => toggleCard(data.id)} src={`${window.location.origin}/assets/plus.svg`} width={32} height={25.78} alt="Toggle Icon" />
                            </div>
                            <div className="card-content px-0 px-lg-2">
                              <Card.Body className="p-0 d-flex flex-column gap-4">
                                <div className="mt-4">
                                  <img className="card-center-icons " src={`data:image/svg+xml,${encodeURIComponent(data.icon)}`} width="70" alt="Icon" />
                                </div>
                                <div>
                                  <Card.Text className="service-heading service-card-title">
                                    {data.heading}
                                  </Card.Text>
                                </div>
                                <div>
                                  <Card.Text className="service-description">
                                    <p className="">{data.short_description}</p>
                                  </Card.Text>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        )}
                      </div>
                    </SwiperSlide>
                  </Col>
                );
              })}
            </Row>
          </Swiper>
        </div>
      </div>
    </Container >
  );
};

export default Services;
