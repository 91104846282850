import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const fetchSlidingDetails = createAsyncThunk(
    "ladding/fetchSlidingDetails",
    async () => {
        const response = await axiosInstance.get('/get_landingpage_flowsteps');
        return response.data;
    }
);
export const fetchHerosectionDetails = createAsyncThunk(
    "ladding/fetchHerosectionDetails",
    async () => {
        const response = await axiosInstance.get('/get-landing-hero-section');
        return response.data;
    }
);
export const fetchlandingServices = createAsyncThunk(
    "ladding/fetchlandingServices",
    async () => {
        const response = await axiosInstance.get('/get-landing-services');
        return response.data;
    }
);
export const fetchFaqsDetails = createAsyncThunk(
    "ladding/fetchFaqsDetails",
    async () => {
        const response = await axiosInstance.get('/get-landing-faqs');
        return response.data;
    }
);
export const fetchCountryDetails = createAsyncThunk(
    "ladding/fetchCountryDetails",
    async () => {
        const response = await axiosInstance.get('/get-country-list');
        return response.data;
    }
);
// export const fetchStateName = createAsyncThunk(
//     "ladding/fetchStateName",
//     async () => {
//         const response = await axiosInstance.get('/get-state-list/US');
//         return response.data;
//     }
// );
export const fetchStateName = createAsyncThunk(
    "ladding/fetchStateName",

    async (stateName, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/get-state-list/${stateName}`);
            return response?.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors.response) {
                    return rejectWithValue(errors.response);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);
export const fetchServiceTitle = createAsyncThunk(
    "ladding/fetchServiceTitle",
    async () => {
        const response = await axiosInstance.get('/get-landing-services-header');
        return response.data;
    }
);
export const fetchPostalDetails = createAsyncThunk(
    "ladding/fetchPostalDetails",
    async (postal_Data) => {
        const response = await axiosInstance.post('/get-pincode-list', postal_Data);
        return response.data;
    }
);
export const fetchPostalDetailsTo = createAsyncThunk(
    "ladding/fetchPostalDetailsTo",
    async (postal_DataTo) => {
        const response = await axiosInstance.post('/get-pincode-list', postal_DataTo);
        return response.data;
    }
);


export const fetchShippingRates = createAsyncThunk(
    "ladding/fetchShippingRates",

    async (shipping_rates, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/get-shipping-rates', shipping_rates);
            return response?.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors.response) {
                    return rejectWithValue(errors.response);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);

export const fetchShippingRatescoupon = createAsyncThunk(
    "ladding/fetchShippingRatescoupon",

    async (shipping_rates, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/get-shipping-rates', shipping_rates);
            return response?.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors.response) {
                    return rejectWithValue(errors.response);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);
export const deletecartdetail = createAsyncThunk(
    "ladding/deletecartdetail",

    async (shipping_rates, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post('/delete-cart-detail', shipping_rates);
            return response?.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors.response) {
                    return rejectWithValue(errors.response);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);

// export const fetchfooterdata = createAsyncThunk(
//     "ladding/fetchfooterdata",
//     async () => {
//         const response = await axiosInstance.get('/static-page/parcel-delivery-canada');
//         return response.data;
//     }
// );
export const fetchfooterdata = createAsyncThunk(
    "ladding/fetchfooterdata",

    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/static-page/${id}`);
            return response?.data;
        } catch (errors) {
            if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
                localStorage.removeItem("user");
                localStorage.removeItem("cartdetail_id");
                localStorage.removeItem("cart_id");
                localStorage.removeItem("token");
            CustomAlert('Please log in and try again.')

                localStorage.clear()
            }
            else
                if (errors.response) {
                    return rejectWithValue(errors.response);
                }
            return rejectWithValue(
                "An error occurred while processing your request."
            );
        }
    }
);

export const loginadminuser = createAsyncThunk(
    'login_registration/login',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/admin-login/${userData}`);
            const token = response.data.Login.token;
            localStorage.setItem('token', token);
            // window.location.reload();
            return response.data;
        } catch (errors) {
            if (errors.response) {
                return rejectWithValue(errors.response.data); // Return error response data
            }
            return rejectWithValue('An error occurred while processing your request.');
        }
    }
);