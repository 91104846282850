import React, { useEffect, useState } from 'react'
import { Container, Dropdown, Nav, NavDropdown, Navbar, Row, Col, Card, Image, Form, Spinner } from "react-bootstrap";
import "../../../assets/styles/footer/pickupDrop.css"
import { Button, FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import CustomAutocomplete from '../../../components/common/CustomCountryPostal';
import { useDispatch, useSelector } from 'react-redux';
import { resetErrors } from '../../../redux/landing/landingSlice';
import { fetchCountryDetails, fetchPostalDetails, fetchStateName } from '../../../redux/landing/landingThunk';
import { Formik } from 'formik';
import * as Yup from "yup";
import MapComponent from './MapComponent';
import { fetchdropdata } from '../../../redux/services/ServicesThunk';
import MapComponentStatic from './MapCompenentStatic';
const formValidation = Yup.object().shape({
  address_line1: Yup.string().required("This field is required"),
  country: Yup.string().required("This field is required"),
  postalCode: Yup.string().required("This field is required"),
  // stateCode: Yup.string().required("This field is required"),
  city: Yup.string().required("This field is required"),
  Courier_Partners: Yup.string()
    .required("This field is required")
    .test('is-not-select', 'This field is required', value => value !== 'Select')
})
const PickupandDrop = () => {

  const dispatch = useDispatch();

  const country_Name = useSelector(
    (stateCode) => stateCode.landingReducer?.countryDeatils?.data
  );

  const country_Postal = useSelector(
    (stateCode) => stateCode.landingReducer?.countryPostal?.data
  );

  const state_name = useSelector(
    (stateCode) => stateCode.landingReducer?.stateName?.data
  );

  const isPostalLoading = useSelector(
    (stateCode) => stateCode.landingReducer.isPostalLoading
  );

  const DropOffLocations = useSelector(
    (stateCode) => stateCode?.servicedata?.dropoffdata?.data
  );

  const DropOffLocationsLoading = useSelector(
    (stateCode) => stateCode?.servicedata?.isDropofLoading
  );

  const DropOffLocationsError = useSelector(
    (stateCode) => stateCode?.servicedata?.dropoffdata?.error
  );

  const CourierPartners = ["Select", "FEDEX", "UPS"]
  const courierIdMap = {
    "FEDEX": 1,
    "UPS": 2,
    "DHL": 3,
    "USPS": 4,
  };
  const [countryName, setCountryName] = useState();
  const [countryId, setCountryId] = useState()
  const [postalOptions, setPostalOptions] = useState([]);
  const [frompostalLength, setFrompostalLength] = useState(10);
  const [formData, setFormData] = useState({
    address_line1: "",
    country: "",
    countryCode: "",
    postalCode: "",
    country_id: "",
    stateCode: state_name ? state_name[0]?.code : "" ,
    courier_id: "",
    Courier_Partners: CourierPartners[0]
  })
  const [formErrors, setFormErrors] = useState({
    address_line1: "",
    country: "",
    postalCode: "",
    stateCode: "",
    courierpartners: ""
  });
  const [errorsMulti, setErrorsmulti] = useState({
    frompostcode: false,
  });

  const [postcodemulti, setPostcodemulti] = useState({
    from: "",
  });

  const initialValues = {
    address_line1: formData?.address_line1,
    countryCode: formData?.countryCode,
    country: formData?.country,
    postalCode: formData?.postalCode,
    country_id: formData?.country_id,
    city: formData?.city,
    courier_id: formData?.courier_id,
    stateCode: formData?.stateCode,
    Courier_Partners: formData?.Courier_Partners
  }

  const formSubmit = (values) => {
    if (values?.countryCode !== undefined && values?.countryCode !== "") {
      dispatch(
        fetchdropdata({
          address_line1: formData?.address_line1,
          countryCode: formData?.countryCode,
          postalCode: formData?.postalCode,
          stateCode: formData?.stateCode,
          courier_id: formData?.courier_id,
          city: formData?.city,
        }))
        .unwrap()
        .then((response) => {
          // if (response?.statusCode === 200 && response?.status === true)

          // })
        })
        .catch((error) => {
        })
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        country: "Please enter valid country",
      }));
    }
  }

  useEffect(() => {
    dispatch(resetErrors());
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(fetchCountryDetails());
  }, [dispatch]);

  useEffect(() => {
    let dynamicFromCountry = formData?.country;

    if ((dynamicFromCountry && country_Name)) {
      const dataformatFrom = country_Name?.find((i) => {
        return i.name.toLowerCase() === dynamicFromCountry.toLowerCase();
      });
      setPostcodemulti(prevPostcodemulti => ({
        ...prevPostcodemulti,
        from: dataformatFrom?.postcode_format
      }));
      setFormData({
        ...formData,
        country_id: dataformatFrom?.id ? dataformatFrom?.id : "",
        countryCode: dataformatFrom?.code ? dataformatFrom?.code : "",
        // stateCode: stateName ? stateName.state_name : "",
        // city: stateName ? stateName.city_name : "",
      });
    }
  }, [formData?.country, country_Name]);


  useEffect(() => {
    if (country_Name) {
      const topCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries === 1
      );
      const remainingCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries !== 1
      );
      const filteredCountries = [...topCountries, ...remainingCountries];
      setCountryName(filteredCountries);
    }
  }, [country_Name]);
  useEffect(() => {
    if (country_Postal !== undefined && country_Postal !== null) {
      setPostalOptions(country_Postal);
    } else {
      setPostalOptions([]);
    }
  }, [country_Postal]);
  useEffect(() => {
    if (formData?.postalCode?.length > 0) {
      dispatch(
        fetchPostalDetails({
          country_id: countryId,
          search_pincode: formData.postalCode,
        })
      );
    }
  }, [formData?.postalCode]);

  // useEffect(() => {
  //   if (formData?.country_id) {
  //     const stateName = formData?.country_id;
  //     dispatch(fetchStateName(stateName));
  //   }
  // }, [formData?.country_id, dispatch]);

  useEffect(() => {
    formData.postalCode = ""
    const datad = country_Name?.find((i) => {
      return i.name === formData.country;
    });
    if (datad?.id) {
      const stateName = datad?.id;
      dispatch(fetchStateName(stateName));
    }
    setCountryId(datad?.id)
    setFormData({ ...formData, country_id: datad?.id, countryCode: datad?.code })
  }, [country_Name, formData.country])

  useEffect(() => {
    if (formData.country && country_Name && country_Postal) {
      const data = country_Name.find((cntr) => cntr.name === formData.country);
      const stateName = country_Postal.find((postal) => postal.state_name);
      setFormData({
        ...formData,
        country_id: data?.id ? data?.id : "",
        countryCode: data?.code ? data?.code : "",
        // stateCode: stateName ? stateName.state_name : "",
        // city: stateName ? stateName.city_name : "",
      });
    }
    else if (formData.country && formData?.country_id && formData?.postalCode) {
      dispatch(
        fetchPostalDetails({
          country_id: formData?.country_id,
          search_pincode: formData?.postalCode,
        })
      )
        .unwrap()
        .then((response) => {
          if (formData.country && country_Name && country_Postal) {
            const data = country_Name.find((cntr) => cntr.name === formData.country);
            const stateName = country_Postal.find((postal) => postal.state_name);

            setFormData({
              ...formData,
              country_id: data?.id ? data?.id : "",
              country_code: data?.code ? data?.code : "",
              // stateCode: stateName ? stateName.state_name : "",
            });
          }
        })
    }
  }, [formData.country, country_Postal, formData?.postalCode]);

  const handleFromCountryInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        country: "This field is required.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        country: "",
      }));
    }
    setFormData({ ...formData, country: newInputValue });
  };

  const handlePostalChange = (event, data) => {

    const isLengthConditionTrue = (postcodemulti?.from?.length || 10) < data?.length;
    setFrompostalLength(postcodemulti?.from?.length)
    setErrorsmulti(prevErrorsMulti => ({
      ...prevErrorsMulti,
      frompostcode: isLengthConditionTrue
    }));

    setFormData({
      ...formData, postalCode: data
    })
  }

  const handleSelectChange = (event) => {
    const selectedCourierPartner = event.target.value;
    const selectedCourierId = courierIdMap[selectedCourierPartner] || "";

    setFormData(prevFormData => ({
      ...prevFormData,
      Courier_Partners: selectedCourierPartner,
      courier_id: selectedCourierId
    }));
  };

  const handleSelectChangeState = (event,setFieldValue) => {
    const selectedState = event.target.value;
    setFormData({
      ...formData,
      state_sort_name: selectedState,
      stateCode: selectedState
    });
    setFieldValue('stateCode', selectedState);

  };



  const handleCustomChange = (event, setFieldValue) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFieldValue(name, value);
  };

  const locations = [
    { "latitude": 41.8900, "longitude": -87.6245, "static": true }, // FedEx Chicago
    { "latitude": 41.7681, "longitude": -88.1526, "static": true }, // FedEx Naperville
    { "latitude": 39.7817, "longitude": -89.6501, "static": true }, // FedEx Springfield
    { "latitude": 41.8883, "longitude": -87.6354, "static": true }, // UPS Chicago
    { "latitude": 42.0334, "longitude": -88.0834, "static": true }, // UPS Schaumburg
    { "latitude": 40.6936, "longitude": -89.5886, "static": true }  // UPS Peoria
  ];


  return (
    <>
      <div>
        <div className="pickupdrop-outer">
          <Container>
            <Row className="justify-content-center">
              <Col md={11}>
                <Card className="pickupdrop-parent-outer my-5">
                  <div className='p-4'>
                    <h5 className="blue-text mb-4">Find a Location for Pickup and Dropoff</h5>
                    <div className='d-flex flex-column flex-lg-row gap-4'>
                      <div style={{ flex: "1" }}>
                        <Formik enableReinitialize
                          initialValues={initialValues}
                          validationSchema={formValidation} onSubmit={formSubmit}>
                          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, isValid, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} autoComplete="off">
                              <div className='d-flex gap-3 flex-column'>
                                <div>
                                  <Form.Group>
                                    <Form.Label className="formlabel">
                                      <p className="of-black-dark">Address</p>
                                    </Form.Label>
                                    <Form.Control
                                      className={`user-address_line1-placeholder input-main-all my-paper-UserAddress ${errors.address_line1 && touched.address_line1 && errors.address_line1 ? "is-invalid-paper" : "is-valid-paper"}`}
                                      type="text"
                                      name="address_line1"
                                      placeholder="Address"
                                      value={values.address_line1}
                                      onChange={(e) => { handleCustomChange(e, setFieldValue) }}
                                      onBlur={handleBlur}
                                      isInvalid={errors.address_line1 && touched.address_line1}
                                      autoComplete="new-address"
                                      onKeyPress={(e) => {
                                        if (!/^[a-zA-Z0-9\s,]*$/.test(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                      <span>
                                        {errors.address_line1 && touched.address_line1 && errors.address_line1}
                                      </span>
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-4'>
                                  <div style={{ flex: "1" }}>
                                    <Form.Label className="formlabel">
                                      <p className="of-black-dark">Country</p>
                                    </Form.Label>
                                    <Paper className={`my-paper-UserAddress ${(formErrors?.country || (errors.country && touched.country && errors.country)) ? "is-invalid-paper" : ""}`}>
                                      <CustomAutocomplete
                                        onInputChange={handleFromCountryInputChange}
                                        options={countryName && countryName?.map((data) => data?.name)}
                                        value={formData.country}
                                        placeholder={"Country"}
                                        isInvalid={errors.country && touched.country && errors.country}
                                        lengthInput={20}
                                      />
                                    </Paper>
                                    {/* {errors?.country && (
                                      <Form.Text className="text-danger mt-0" type="invalid">
                                        {errors.country && touched.country && errors.country}
                                      </Form.Text>
                                    )}
                                    {formErrors?.country && (
                                      <Form.Text className="text-danger mt-0">
                                        {formErrors?.country}
                                      </Form.Text>
                                    )} */}
                                    {(formErrors?.country || (errors?.country && touched?.country)) && (
                                      <Form.Text className="text-danger mt-0" type="invalid">
                                        {formErrors?.country || errors?.country}
                                      </Form.Text>
                                    )}
                                  </div>
                                  <div style={{ flex: "1" }}>
                                    <Form.Group controlId="formGridlastname">
                                      <Form.Label className="formlabel">
                                        <p className="of-black-dark">Postal Code</p>
                                      </Form.Label>
                                      <Paper className={`my-paper-UserAddress ${formErrors?.postalCode || errorsMulti?.frompostcode || (errors.postalCode && touched.postalCode && errors.postalCode) ? "is-invalid-paper" : ""}`} style={{ borderRadius: "6px" }}>
                                        <CustomAutocomplete
                                          options={postalOptions && postalOptions?.map((pincodes) => pincodes?.pincode_no)}
                                          onInputChange={(event, newInputValue) => {
                                            setFormData({ ...formData, postalCode: newInputValue })
                                            if (newInputValue) {
                                              setFormErrors((prevErrors) => ({
                                                ...prevErrors, postalCode: ""
                                              }))
                                            } else {
                                              setFormErrors((prevErrors) => ({
                                                ...prevErrors, postalCode: "This field is required"
                                              }))
                                            }
                                            handlePostalChange(event, newInputValue);
                                          }}
                                          onBlur={(event) => {
                                            if (!event.target.value) {
                                              setFormErrors((prevErrors) => ({
                                                ...prevErrors, 
                                                postalCode: "This field is required 123",
                                              }));
                                            }
                                          }}
                                          value={formData?.postalCode || values?.postalCode}
                                          loading={isPostalLoading}
                                          placeholder={"Postal"}
                                          isInvalid={errors.postalCode && touched.postalCode}
                                          lengthInput={frompostalLength || 10}
                                        />
                                      </Paper>
                                      {/* {formErrors?.postalCode && (
                                        <Form.Text className="text-danger mt-0">
                                          {formErrors?.postalCode}
                                        </Form.Text>
                                      )}
                                      {errors?.postalCode && (
                                        <Form.Text className="text-danger mt-0" type="invalid">
                                          {errors.postalCode && touched.postalCode && errors.postalCode}
                                        </Form.Text>
                                      )} */}
                                      {(formErrors?.postalCode || (errors?.postalCode && touched?.postalCode)) && (
                                        <Form.Text className="text-danger mt-0" type="invalid">
                                          {formErrors?.postalCode || errors?.postalCode}
                                        </Form.Text>
                                      )}

                                    </Form.Group>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-4'>
                                  <div style={{ flex: "1" }}>
                                    <Form.Group>
                                      <Form.Label className="formlabel">
                                        <p className="of-black-dark">State</p>
                                      </Form.Label>
                                      {!state_name && (
                                        <Form.Group>
                                          <Form.Control
                                            className={`user-address_line1-placeholder input-main-all my-paper-UserAddress ${errors.stateCode && touched.stateCode && errors.stateCode ? "is-invalid-paper" : "is-valid-paper"}`}
                                            type="text"
                                            name="stateCode"
                                            placeholder="State"
                                            value={values.stateCode || formData.state_sort_name}
                                            onChange={(e) => { handleCustomChange(e, setFieldValue) }}
                                            onBlur={handleBlur}
                                            isInvalid={errors.stateCode && touched.stateCode}
                                            autoComplete="new-state"
                                          />
                                          <Form.Control.Feedback type="invalid">
                                            <span>
                                              {errors.stateCode && touched.stateCode && errors.stateCode}
                                            </span>
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                      )}
                                      {state_name && (
                                        <Form.Group controlId="formGridfirstname">
                                          <Form.Select
                                            placeholder='Select'
                                            name="stateCode"
                                            onBlur={handleBlur}
                                            className={`Pickup-select-input shadow-none  ${errors.state_sort_name && touched.state_sort_name && errors.state_sort_name ? "is-invalid-paper" : "is-valid-paper" && formData?.state_sort_name === "Select" && "pickup-placeholder-select"}`}
                                            onChange={(e)=>handleSelectChangeState(e,setFieldValue)}
                                            value={formData.state_sort_name}
                                            isInvalid={errors.state_sort_name && touched.state_sort_name && errors.state_sort_name}
                                            autoComplete="new-state">
                                            {state_name?.map((option, index) => (
                                              <option key={index} value={option?.code}>
                                                {option?.default_name}
                                              </option>
                                            ))}
                                          </Form.Select>
                                          {errors?.stateCode && (
                                            <Form.Text className="text-danger mt-0" type="invalid">
                                              {errors.state_sort_name && touched.state_sort_name && errors.state_sort_name}
                                            </Form.Text>
                                          )}
                                        </Form.Group>
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div style={{ flex: "1" }}>
                                    <Form.Group>
                                      <Form.Label className="formlabel">
                                        <p className="of-black-dark">City</p>
                                      </Form.Label>
                                      <Form.Control
                                        className={`user-address_line1-placeholder input-main-all my-paper-UserAddress ${errors.city && touched.city && errors.city ? "is-invalid-paper" : "is-valid-paper"}`}
                                        type="text"
                                        name="city"
                                        placeholder="City"
                                        value={values.city}
                                        onChange={(e) => { handleCustomChange(e, setFieldValue) }}
                                        onBlur={handleBlur}
                                        maxLength={29}
                                        isInvalid={errors.city && touched.city}
                                        autoComplete="new-city"
                                        onKeyPress={(e) => {
                                          if (!/^[a-zA-Z]$/.test(e.key)) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        <span>
                                          {errors.city && touched.city && errors.city}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row gap-4'>
                                  <div style={{ flex: "1" }}>
                                    <Form.Group controlId="formGridfirstname">
                                      <Form.Label className="formlabel">
                                        <p className="of-black-dark">Courier Partner</p>
                                      </Form.Label>
                                      <Form.Select
                                        placeholder='Select'
                                        onBlur={handleBlur}
                                        className={`Pickup-select-input shadow-none  ${errors.Courier_Partners && touched.Courier_Partners && errors.Courier_Partners ? "is-invalid-paper" : "is-valid-paper" && formData?.Courier_Partners === "Select" && "pickup-placeholder-select"}`}
                                        onChange={handleSelectChange}
                                        value={formData.Courier_Partners}
                                        isInvalid={errors.Courier_Partners && touched.Courier_Partners && errors.Courier_Partners}
                                        autoComplete="new-courier-partner">
                                        {CourierPartners.map((option, index) => (
                                          <option key={index} value={option} disabled={index === 0}>
                                            {option}
                                          </option>
                                        ))}
                                      </Form.Select>
                                      {errors?.Courier_Partners && (
                                        <Form.Text className="text-danger mt-0" type="invalid">
                                          {errors.Courier_Partners && touched.Courier_Partners && errors.Courier_Partners}
                                        </Form.Text>
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div style={{ flex: "1" }}>
                                    <Form.Label className="formlabel">
                                      <p className="of-black-dark">{""}&nbsp;</p>
                                    </Form.Label>
                                    <Button type="submit" className='primarybtn btn w-100 height-44'>
                                      {DropOffLocationsLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <>SUBMIT</>
                                      )}
                                    </Button>
                                  </div>
                                </div>
                                {DropOffLocationsError && (
                                  <>
                                    <p className='text-danger'> {DropOffLocationsError} </p>
                                  </>
                                )}
                              </div>
                            </Form>

                          )}
                        </Formik>
                        {DropOffLocations && (
                          <>
                            <div style={{ height: "176px", borderRadius: "6px", border: "1px solid #bababa" }} className=' mt-4 overflow-auto hide-scrollbar'  >
                              {DropOffLocations?.length && (
                                <>
                                  {DropOffLocations?.map((address_line1, index) => {
                                    return (
                                      <>
                                        <Card key={index} className='py-2 px-2 rounded-0  px-3' style={{ fontSize: "14px", fontWeight: "300", borderTop: "0px", borderBottom: index === DropOffLocations?.length - 1 ? "0px" : "1px solid #bababa", borderLeft: "0px", borderRight: "0px" }} >
                                          <span style={{ fontSize: "14px", fontWeight: "400" }}>{address_line1.name}</span>
                                          <div className='d-flex ' style={{ fontSize: "14px", fontWeight: "300" }}>
                                            <div className='d-flex gap-2'>
                                              {address_line1.company && <span className=''>{address_line1.company}{address_line1.company && <>,</>}</span>}
                                              {address_line1.address && <span className=''>{address_line1.address}</span>}
                                              {address_line1.city && <span className=''>,{address_line1.city}</span>}
                                              {address_line1.state && <span className=''>,{address_line1.state}</span>}
                                              {address_line1.postalcode && <span className=''>{address_line1.postalcode} {address_line1.postalcode && <>,</>}</span>}
                                            </div>
                                          </div>
                                          <div className='d-flex '>
                                            <div className='d-flex gap-2'>
                                              {address_line1.postalcode && <span className=''>{address_line1.postalcode} {address_line1.postalcode && <>,</>}</span>}
                                              {address_line1.countrycode && <span className=''> {address_line1.countrycode}{address_line1.countrycode && <>,</>}</span>}
                                              {address_line1.phonenumber && <span className=''> {address_line1.phonenumber}</span>}
                                            </div>
                                          </div>
                                        </Card>
                                      </>
                                    );
                                  })}

                                </>
                                // ) : (
                                //   <>
                                //     No Location Found........!
                                //   </>
                              )}

                            </div>
                          </>
                        )}
                      </div>
                      <div style={{ flex: "1" }}>
                        <Card className='card-border h-100' style={{ borderRadius: "10px" }}>
                          {DropOffLocations ? (
                            <MapComponent locations={DropOffLocations} />
                          ) : (
                            locations && <MapComponentStatic locations={locations} />
                          )}
                        </Card>

                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div >
    </>
  )
}

export default PickupandDrop	