import { Button } from "@mui/material";
import React from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Card, Form } from "react-bootstrap";
import "../assets/styles/footer/contactUs.css";

const Captcha = ({ setCaptchaText, captchaText, captchaTextgen, formErrors, setFormErrors, generateCaptcha, formData, rotate }) => {
    const handleCaptchaChange = (e) => {
        setCaptchaText(e.target.value);
        setFormErrors({ ...formErrors, captchaText: "" });
    };

    const handlePasteCaptcha = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <div className="d-flex gap-3">
                <Card className="height-44 card-border px-4 d-flex align-items-center justify-content-center captcha-bg cptchatxt" style={{ flex: "1" }}>
                    <p className="text-muted fs-6 fw-bolder captcha-text">
                        {captchaTextgen}
                    </p>
                </Card>
                <Button className="card-border height-44" onClick={generateCaptcha} style={{ flex: "0" }}>
                    <RefreshIcon className={`primary-color ${rotate ? 'rotate' : 'rotateOff'}`} />
                </Button>
                <Form.Group style={{ flex: "2" }} className="captchatxt">
                    <Form.Control
                        type="text"
                        name="captchaText"
                        placeholder="Enter captcha text"
                        className={`height-44  ${formErrors?.captchaText ? "is-invalid-paper" : "is-valid-paper"}`}
                        onChange={handleCaptchaChange}
                        onPaste={handlePasteCaptcha}
                        value={captchaText}
                        isInvalid={!!formErrors.captchaText}
                        maxLength={6}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formErrors?.captchaText}
                    </Form.Control.Feedback>
                </Form.Group>
            </div>
        </>
    );
};

export default Captcha;
