import { configureStore } from "@reduxjs/toolkit";
import landingReducer from "./landing/landingSlice";
import loginRegistration from "./login_registration/LoginRegistrationSlice";
import servicedata from "./services/ServicesSlice"
import profileData from "./user_profile/ProfileSlice";
import Trackshipdetails from "./trackshipment/TrashipSlice";
import addressValidation from "./address/addressSlice"
import createPayment from "./payment/PaymentSlice"
import WalletData from './wallet/walletSlice'

export const store = configureStore({
  reducer: {
    landingReducer,
    loginRegistration,
    servicedata,
    profileData,
    addressValidation,
    createPayment,
    Trackshipdetails,
    WalletData
  },
});
