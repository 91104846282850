import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const fetchShippingServices = createAsyncThunk(
  "Service/fetchShippingRates",

  async (ship_services, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/book-service', ship_services);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (error?.response) {
          return rejectWithValue(error?.response?.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

// export const fetchpickupdropdata = createAsyncThunk(
//   "Service/fetchpickupdropdata",

//   async (ship_services, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/drop-off-pickup-detail', ship_services);
//       return response?.data;
//     } catch (error) {
//       if (error?.response) {
//         return rejectWithValue(error?.response?.data);
//       }
//       return rejectWithValue(
//         "An error occurred while processing your request."
//       );
//     }
//   }
// );
export const fetchpickupdropdata = createAsyncThunk(
  "Service/fetchpickupdropdata",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/drop-off-pickup-detail",
        data
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const fetchdropdata = createAsyncThunk(
  "Service/fetchdropdata",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/drop-off-locations", data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const bulkOrders = createAsyncThunk(
  "Service/bulkOrders",
  async (bulk_orders, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post('/bulk-order/excel-upload', bulk_orders);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error?.response) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue("An error occurred while processing your request.");
    }
  }
);

export const bulkOrdersList = createAsyncThunk(
  "Service/bulkOrdersList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/bulk-order/list`, id);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error?.response) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue("An error occurred while processing your request.");
    }
  }
);


export const bulkOrdersEdit = createAsyncThunk(
  "Service/bulkOrdersEdit",
  async (bulkdata, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/bulk-order/edit`, bulkdata.data,);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error?.response) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue("An error occurred while processing your request.");
    }
  }
);

export const bulkOrdersDelete = createAsyncThunk(
  "Service/bulkOrdersDelete",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(`/bulk-order/delete/${id.cart_id}`, id);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error?.response) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue("An error occurred while processing your request.");
    }
  }
);
