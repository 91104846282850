/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Formik, Field, ErrorMessage, FieldArray } from "formik";
import { Card, Container, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { Paper, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  resetErrors,
  resetMultiErrors,
} from "../../../redux/landing/landingSlice";
import { useNavigate } from "react-router-dom";
import "../../../assets/styles/multishipment/multishipment.css";
import {
  fetchCountryDetails,
  fetchPostalDetails,
  fetchPostalDetailsTo,
  fetchShippingRates,
} from "../../../redux/landing/landingThunk";
import { useDebounce } from "use-debounce";
import CustomAutocomplete from "../../../components/common/CustomCountryPostal";
import { getParcels } from "../../../redux/user_profile/ProfileThunk";
import { stringify } from "uuid";
const MultiShipment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useriD = JSON.parse(localStorage.getItem("user"));
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );

  const country_Postal = useSelector(
    (state) => state.landingReducer?.countryPostal?.data
  );
  const country_PostalTo = useSelector(
    (state) => state.landingReducer?.countryPostalTo?.data
  );
  const isPostalLoading = useSelector(
    (state) => state.landingReducer.isPostalLoading
  );
  const isCountryLoading = useSelector(
    (state) => state.landingReducer?.isCountryLoading
  );
  const isLoading = useSelector(
    (state) => state.landingReducer.isLoading?.isServicesLoading
  );
  const parcel_details = useSelector(
    (state) => state?.profileData?.parcelData?.Parcel?.data
  );
  const postalerrorstore = useSelector(
    (state) => state?.landingReducer?.rates_error
  );
  const multierrors = useSelector(
    (state) => state?.landingReducer?.rates_error?.dimensions
  );
  const errorstore = useSelector(
    (state) => state?.landingReducer?.landingShipServices?.errors?.invalid_data
  );
  const errorserviceerrors = useSelector(
    (state) =>
      state?.landingReducer?.landingShipServices?.serviceerrors?.invalid_data
  );
  const fromPostalError = useSelector(
    (state) => state?.landingReducer?.landingShipServices?.serviceerrors
  );
  const [errors, setErrors] = useState({});
  const [errorsMulti, setErrorsmulti] = useState({
    frompostcode: false,
    toPostal: false,
  });
  const [postalerror, setpostalerror] = useState();

  const [selectData, setSelectData] = useState();
  const [countryName, setCountryName] = useState();
  const [postalToOptions, setPostalToOptions] = useState([]);
  const [postcodemulti, setPostcodemulti] = useState({
    from: "",
    to: "",
  });
  const [countryInput, setCountryInput] = useState({
    fromCountryInput: "United States",
    toCountryInput: "United States",
  });
  const [errorState, setErrorState] = useState();
  const [postalCode, setPostalCode] = useState({ pincode: "" });
  const [debouncedPostCode] = useDebounce(postalCode, 1000);
  const [postalToCode, setPostalToCode] = useState({ pincode: "" });
  const [debouncedPostToCode] = useDebounce(postalToCode, 1000);
  const [postalOptions, setPostalOptions] = useState([]);
  const [countryData, setCountryData] = useState(40);
  const [countryToData, setCountryToData] = useState(40);
  const [parcelData, setParcelData] = useState();
  const [frompostalLength, setFrompostalLength] = useState(10);
  const [topostalLength, setTopostalLength] = useState(10);
  const [countryDataService, setcountryDataService] = useState({
    fromCountryId: "",
    fromCountryCode: "",
    toCountryId: "",
    toCountryCode: "",
  });
  const [formErrors, setFormErrors] = useState({
    fromCountry: "",
    fromPostal: "",
    toCountry: "",
    toPostal: "",
    shipments: {
      weight: "",
      height: "",
      width: "",
      length: "",
      unit: "",
      weight_in: "LBS",
      size_in: "IN",
    },
  });
  const [quantity, setQuantity] = useState([]);
  const [toFromCountryData, setToFromCountryData] = useState({
    toCountry: "",
    fromCountry: "",
    toPostal: "",
    fromPostal: "",
  });
  const initialValues = {
    fromCountry: "",
    fromPostal: "",
    toCountry: "",
    toPostal: "",
    shipments: [
      {
        weight: "",
        height: "",
        width: "",
        length: "",
        unit: "",
        weight_in: "LBS",
        size_in: "IN",
      },
    ],
  };

  useEffect(() => {
    if (errorstore) {
      setErrorState(errorstore);
    } else {
      setErrorState(errorserviceerrors);
    }
  }, [errorstore, errorserviceerrors]);

  useEffect(() => {
    if (countryInput?.fromCountryInput !== "United States") {
      setCountryInput({ ...countryInput, toCountryInput: "United States" });
    }
  }, [countryInput.fromCountryInput]);

  useEffect(() => {
    if (countryInput?.toCountryInput !== "United States") {
      setCountryInput({ ...countryInput, fromCountryInput: "United States" });
    }
  }, [countryInput.toCountryInput]);

  useEffect(() => {
    if (postalerrorstore) {
      setpostalerror(postalerrorstore);
    }
    if (fromPostalError) {
      setpostalerror(fromPostalError);
    }
  }, [postalerrorstore, fromPostalError]);

  useEffect(() => {
    dispatch(resetErrors());
    localStorage.removeItem("searchData");
    localStorage.removeItem("cartdetail_id");
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    dispatch(fetchCountryDetails());
    if (useriD?.id) {
      dispatch(getParcels({ customer_id: useriD?.id }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (country_Postal !== undefined && country_Postal !== null) {
      setPostalOptions(country_Postal);
    } else {
      setPostalOptions([]);
    }
  }, [country_Postal]);

  useEffect(() => {
    if (country_PostalTo !== undefined && country_PostalTo !== null) {
      setPostalToOptions(country_PostalTo);
    } else {
      setPostalToOptions([]);
    }
  }, [country_PostalTo]);

  // useEffect(() => {
  //   if ((countryInput.fromCountryInput, country_Name)) {
  //     const todata = country_Name?.find((cntr) => {
  //       return cntr?.name === countryInput.fromCountryInput;
  //     });
  //     const state = country_Postal?.find((postal) => {
  //       return postal?.state_name;
  //     });
  //     const cityName = country_Postal?.find((postal) => {
  //       return postal?.city_name;
  //     });
  //     if (!countryInput.fromCountryInput || !country_Name || !country_Postal) {
  //       const todata = country_Name?.find((cntr) => {
  //         return cntr.name === countryInput.fromCountryInput;
  //       });
  //     }
  //   }
  // }, [countryInput.fromCountryInput, country_Name, country_Postal]);
  useEffect(() => {
    setPostalCode({ pincode: "" });
    if (countryInput.fromCountryInput) {
      const datad = country_Name?.find((i) => {
        return i.name === countryInput.fromCountryInput;
      });
      setCountryData(datad?.id);
      setToFromCountryData({ ...toFromCountryData, fromCountry: datad?.code });
    }
  }, [countryInput.fromCountryInput]);

  useEffect(() => {
    setPostalToCode({ pincode: "" });
    if (countryInput.toCountryInput) {
      const todata = country_Name?.find((cntr) => {
        return cntr?.name === countryInput.toCountryInput;
      });
      setCountryToData(todata?.id);
      setToFromCountryData({ ...toFromCountryData, toCountry: todata?.code });
    }
  }, [countryInput.toCountryInput]);

  useEffect(() => {
    if (postalCode?.pincode?.length > 0) {
      dispatch(
        fetchPostalDetails({
          country_id: countryData || 40,
          search_pincode: debouncedPostCode?.pincode,
          uniquesessionid: uniquesessionid,
        })
      );
    }
  }, [debouncedPostCode]);
  useEffect(() => {
    if (postalToCode?.pincode?.length > 0) {
      dispatch(
        fetchPostalDetailsTo({
          country_id: countryToData || 40,
          search_pincode: debouncedPostToCode?.pincode,
          uniquesessionid: uniquesessionid,
        })
      );
    }
  }, [debouncedPostToCode]);

  useEffect(() => {
    if (country_Name) {
      const topCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries === 1
      );
      const remainingCountries = country_Name?.filter(
        (selectedData) => selectedData?.top_countries !== 1
      );
      const filteredCountries = [...topCountries, ...remainingCountries];
      setCountryName(filteredCountries);
    }
  }, [country_Name]);

  useEffect(() => {
    if (parcel_details && parcel_details.length > 0) {
      // const descriptions = parcel_details.map(item => item.parcel_description);
      const select = {
        // created_at: "2024-04-10T06:10:57.000000Z",
        // customer_id: 123123123,
        // id: 111111111111111111111111111,
        // is_battery: "No",
        // is_default: "No",
        // pallet_size: null,
        // parcel_description: "select",
        // parcel_dimension_in: "IN",
        // parcel_height: 27,
        // parcel_length: 52,
        parcel_name: "Select",
        // parcel_weight: 17,
        // parcel_weight_measure: "KG",
        // parcel_width: 46,
        // updated_at: "2024-04-10T06:10:57.000000Z",
        // value_of_items: 111,
      };
      const updatedParcelDetails = [select, ...parcel_details];
      setSelectData(updatedParcelDetails);
      // parcel_details.unshift(select)
    }
  }, [parcel_details]);

  // newwwccc ytyutyututyutyutyu
  useEffect(() => {
    let dynamicFromCountry = countryInput.fromCountryInput;
    let dynamicToCountry = countryInput.toCountryInput;

    if (dynamicFromCountry && country_Name) {
      const dataformatFrom = country_Name?.find((i) => {
        return i.name.toLowerCase() === dynamicFromCountry.toLowerCase();
      });
      setPostcodemulti((prevPostcodemulti) => ({
        ...prevPostcodemulti,
        from: dataformatFrom,
      }));
    }
    if (dynamicToCountry && country_Name) {
      const dataformatTo = country_Name?.find((i) => {
        return i.name.toLowerCase() === dynamicToCountry.toLowerCase();
      });
      setPostcodemulti((prevPostcodemulti) => ({
        ...prevPostcodemulti,
        to: dataformatTo,
      }));
    }
  }, [
    countryInput.fromCountryInput,
    countryInput.toCountryInput,
    country_Name,
  ]);
  // newwwccc ytyutyututyutyutyu

  const validationSchema = Yup.object().shape({
    shipments: Yup.array().of(
      Yup.object().shape({
        height: Yup.string()
          .matches(/^\d{1,3}$/, "Max 3 digits")
          .required("Required"),
        width: Yup.string()
          .matches(/^\d{1,3}$/, "Max 3 digits")
          .required("Required"),
        length: Yup.string()
          .matches(/^\d{1,3}$/, "Max 3 digits")
          .required("Required"),
        weight: Yup.string()
          .matches(/^\d{1,2}(\.\d{1})?$/, "Invalid format. Example: 12.3")
          .required("Required"),
        unit: Yup.string()
          .matches(/^[1-9]$|^[1][0-5]$/, "Max 15 qty")
          .required("Required"),
      })
    ),
    fromPostal: Yup.string().required("This field is required"),
    toPostal: Yup.string().required("This field is required"),
  });

  const handleValidation = () => {
    let newErrors = {
      fromCountry: "",
      fromPostal: "",
      toCountry: "",
      toPostal: "",
      fromPostalerror: false,
      toPostalerror: false,
    };

    if (countryInput.fromCountryInput.trim() === "") {
      newErrors.fromCountry = "This field is required";
    }

    if (postalCode.pincode.trim() === "") {
      newErrors.fromPostal = "This field is required";
    }

    if (countryInput.toCountryInput.trim() === "") {
      newErrors.toCountry = "This field is required";
    }

    if (postalToCode.pincode.trim() === "") {
      newErrors.toPostal = "This field is required";
    }
    if (!errorsMulti.frompostcode) {
      newErrors.fromPostalerror = true;
    }
    if (!errorsMulti.toPostal) {
      newErrors.toPostalerror = true;
    }
    setFormErrors(newErrors);
    return (
      newErrors.fromCountry === "" &&
      newErrors.fromPostal === "" &&
      newErrors.toCountry === "" &&
      newErrors.toPostal === "" &&
      newErrors.fromPostalerror &&
      newErrors.toPostalerror
    );
  };

  const multisend = async (values) => {
    if (handleValidation()) {
      let countrydata;
      let fromdata;
      let todata;
      try {
        const response = await dispatch(fetchCountryDetails()).unwrap();

        if (response.status === true && response.statusCode === 200) {
          fromdata = response.data?.find((cntr) => {
            return cntr?.name === countryInput?.fromCountryInput;
          });
          todata = response.data?.find((cntr) => {
            return cntr?.name === countryInput?.toCountryInput;
          });
        }
        let data = {
          fromCountryCode: fromdata?.code || "US",
          fromPostalCode: toFromCountryData?.fromPostal,
          toCountryCode: todata?.code || "US",
          toPostalCode: toFromCountryData?.toPostal,
          fromCountryId: fromdata?.id || 40,
          toCountryId: todata?.id || 40,
          package_type: "Package",
          customer_id: useriD ? useriD?.id : "",
          uniquesessionid: uniquesessionid,
          fromcountry: fromdata?.name,
          tocountry: todata?.name,
        };
        let mixdata = {
          weight: [],
          height: [],
          width: [],
          length: [],
          unit: [],
          qty: [],
          size_in: [],
          weight_in: [],
        };
        values?.shipments?.forEach((shipment) => {
          mixdata.weight.push(String(shipment.weight));
          mixdata.height.push(String(shipment.height));
          mixdata.width.push(String(shipment.width));
          mixdata.length.push(String(shipment.length));
          mixdata.unit.push(String(shipment.unit));
          mixdata.qty.push(String(shipment.unit));
          mixdata.size_in.push(
            String(shipment.size_in === undefined ? "IN" : shipment.size_in)
          );
          mixdata.weight_in.push(
            String(
              shipment.weight_in === undefined ? "LBS" : shipment.weight_in
            )
          );
        });
        let finaldata = {
          ...data,
          ...mixdata,
          uniquesessionid: uniquesessionid,
        };
        let makedata = { ...finaldata, cheapest: "Yes" };
        localStorage.setItem("multiship_data", JSON.stringify(mixdata));

        dispatch(fetchShippingRates(makedata))
          .unwrap()
          .then((response) => {
            if (response.status === true && response.statusCode === 200) {
              if (response.data.length > 0) {
                localStorage.setItem("searchData", JSON.stringify(finaldata));
                navigate("/service");
              }
            }
            if (response.status === false && response.statusCode === 401) {
              setErrorState(response?.serviceerrors?.fromPostalCode);
            }
          })
          .catch((error) => {
            if (error?.status === 429 || error?.status === 500) {
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Something went wrong! please try some time latter",
              });
            }
          });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleFromCountryInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fromCountry: "This field is required.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fromCountry: "",
      }));
    }
    setCountryInput({ ...countryInput, fromCountryInput: newInputValue });
  };

  const handleFromCountryInputBlur = (event) => {
    if (!event.target.value) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        fromCountry: "This field is required.",
      }));
    }
  };

  const handleTOCountryInputChange = (event, newInputValue) => {
    if (!newInputValue) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        toCountry: "This field is required.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        toCountry: "",
      }));
    }
    setCountryInput({ ...countryInput, toCountryInput: newInputValue });
  };

  const handleTOCountryInputBlur = (event) => {
    if (!event.target.value) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        toCountry: "This field is required.",
      }));
    }
  };

  const handlefromChange = (event, data) => {
    setpostalerror();
    setErrorState();
    const isLengthConditionTrue = postcodemulti?.from?.postcode_format?.length < data?.length;
    if (postcodemulti?.from?.postcode_format?.length > 1) {
      setFrompostalLength(postcodemulti?.from?.postcode_format?.length);
      setErrorsmulti((prevErrorsMulti) => ({
        ...prevErrorsMulti,
        frompostcode: isLengthConditionTrue,
      }));
    }else{
      setFrompostalLength(10);
      setErrorsmulti((prevErrorsMulti) => ({
        ...prevErrorsMulti,
        frompostcode: false,
      }));
    }

    setPostalCode({
      ...postalCode,
      pincode: data,
    });

    setToFromCountryData({ ...toFromCountryData, fromPostal: data });
    // dispatch(resetMultiErrors())
  };

  const handleToChange = (event, data) => {
    setpostalerror();
    const isLengthConditionTrue = postcodemulti?.to?.postcode_format?.length < data?.length;
    if (postcodemulti?.to?.postcode_format?.length > 1) {
      setTopostalLength(postcodemulti?.to?.postcode_format?.length);
      setErrorsmulti((prevErrorsMulti) => ({
        ...prevErrorsMulti,
        toPostal: isLengthConditionTrue,
      }));
    }else{
      setTopostalLength(10);
      setErrorsmulti((prevErrorsMulti) => ({
        ...prevErrorsMulti,
        toPostal: false,
      }));
    }

    setPostalToCode({
      ...postalToCode,
      pincode: data,
    });
    setToFromCountryData({ ...toFromCountryData, toPostal: data });
    // dispatch(resetMultiErrors())
  };

  const handleqty = (e, index) => {
    setQuantity((prevqty) => {
      const newqty = [...prevqty];
      newqty[index] = e?.target?.value;
      return newqty;
    });
  };

  return (
    <div className="multiship-holder-outer border border-1">
      <div className="background-holder  ">
        <div className="bg-image">
          <Container className="px-4 p-xxl-0">
            <div className="background-title ">
              <h4>Streamline Your Shipments, Save More</h4>
            </div>
          </Container>
        </div>
      </div>
      <Container className="p-0">
        <Card className="my-5 p-0 px-4 p-xl-4 p-xxl-4 border-0">
          <div className="mx-auto enterdetails-multi mb-0 mb-xl-4 pt-4 pt-xl-0">
            <Formik
              initialValues={initialValues}
              setFieldValue
              validationSchema={validationSchema}
              onSubmit={multisend}
              onChange={() => {
                dispatch(resetErrors());
              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                values,
                errors,
                isValid,
                touched,
              }) => (
                setErrors(errors),
                (
                  <Form onSubmit={handleSubmit} onChange={handleChange}>
                    <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-row flex-xxl-row gap-4 mb-0 mb-xl-4">
                      <div className="d-flex gap-4" style={{ flex: "1" }}>
                        <div className="" style={{ flex: "3" }}>
                          <Form.Label className="formlabel fromInputLabel">
                            <p className=" of-black-dark-multi">From</p>
                          </Form.Label>
                          <Paper
                            className={`my-paper ${
                              formErrors?.fromCountry
                                ? "is-invalid-paper"
                                : "is-valid-paper"
                            }`}
                          >
                            <CustomAutocomplete
                              onInputChange={handleFromCountryInputChange}
                              onBlur={handleFromCountryInputBlur}
                              options={
                                countryName &&
                                countryName?.map((data) => data?.name)
                              }
                              value={countryInput.fromCountryInput}
                              placeholder={"Country"}
                              // countryLength={20}
                              lengthInput={20}
                            />
                          </Paper>
                          {formErrors?.fromCountry && (
                            <Form.Text className="text-danger mt-0">
                              {formErrors?.fromCountry}
                            </Form.Text>
                          )}
                        </div>
                        <div
                          className="multi-postal-field"
                          style={{ flex: "2" }}
                        >
                          <Form.Label className="formlabel">
                            <span className="">&nbsp;</span>
                          </Form.Label>
                          <Paper
                            //  || errorsMulti?.frompostcode
                            className={`my-paper ${
                              formErrors?.fromPostal ||
                              postalerror?.fromPostalCode ||
                              (errors.fromPostal &&
                                errors &&
                                errors?.fromPostal &&
                                touched?.fromPostal &&
                                touched &&
                                touched.fromPostal)
                                ? "is-invalid-paper"
                                : "is-valid-paper"
                            }`}
                          >
                            <CustomAutocomplete
                              value={postalCode?.pincode}
                              loading={isPostalLoading}
                              placeholder={"Postal"}
                              name={"fromPostal"}
                              options={
                                postalOptions &&
                                postalOptions?.map(
                                  (pincodes) => pincodes?.pincode_no
                                )
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue) {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    fromPostal: "",
                                  }));
                                } else {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    fromPostal: "This field is required.",
                                  }));
                                }

                                handlefromChange(event, newInputValue);
                              }}
                              onBlur={(event) => {
                                if (!event.target.value) {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    fromPostal: "This field is required.",
                                  }));
                                }
                              }}
                              // fromPostalLength={frompostalLength}
                              lengthInput={frompostalLength || 10}
                            />
                          </Paper>
                          {/* {formErrors?.fromPostal && (
                            <Form.Text className="text-danger mt-0">
                              {formErrors?.fromPostal}
                            </Form.Text>
                          )} */}
                          <ErrorMessage
                            name={"fromPostal"}
                            component="div"
                            className="text-danger"
                          />
                        </div>
                      </div>
                      <div className="d-flex gap-4" style={{ flex: "1" }}>
                        <div className="" style={{ flex: "3" }}>
                          <Form.Label className="formlabel fromInputLabel">
                            <p className=" of-black-dark-multi">To</p>
                          </Form.Label>
                          <Paper
                            className={`my-paper ${
                              formErrors?.toCountry
                                ? "is-invalid-paper"
                                : "is-valid-paper"
                            }`}
                          >
                            <CustomAutocomplete
                              options={
                                countryName &&
                                countryName?.map((data) => data?.name)
                              }
                              value={countryInput.toCountryInput}
                              onBlur={handleTOCountryInputBlur}
                              onInputChange={handleTOCountryInputChange}
                              placeholder={"Country"}
                              // countryLength={20}
                              lengthInput={20}
                            />
                          </Paper>

                          {formErrors?.toCountry && (
                            <Form.Text className="text-danger mt-0">
                              {formErrors.toCountry}
                            </Form.Text>
                          )}
                        </div>
                        <div
                          className="multi-postal-field"
                          style={{ flex: "2" }}
                        >
                          <Form.Label className="formlabel">
                            <p className=" of-black-dark-multi"></p>
                          </Form.Label>
                          <Paper
                            //  || errorsMulti?.toPostal
                            className={`my-paper ${
                              formErrors?.toPostal ||
                              postalerror?.toPostalCode ||
                              (errors.toPostal &&
                                errors &&
                                errors?.toPostal &&
                                touched?.toPostal &&
                                touched &&
                                touched.toPostal)
                                ? "is-invalid-paper"
                                : "is-valid-paper"
                            }`}
                          >
                            <CustomAutocomplete
                              value={postalToCode.pincode}
                              loading={isPostalLoading}
                              name={"toPostal"}
                              options={
                                postalToOptions &&
                                postalToOptions?.map(
                                  (pincodes) => pincodes?.pincode_no
                                )
                              }
                              onInputChange={(event, newInputValue) => {
                                if (newInputValue) {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    toPostal: "",
                                  }));
                                } else {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    toPostal: "This field is required.",
                                  }));
                                }
                                handleToChange(event, newInputValue);
                              }}
                              onBlur={(event) => {
                                if (!event.target.value) {
                                  setFormErrors((prevErrors) => ({
                                    ...prevErrors,
                                    toPostal: "This field is required.",
                                  }));
                                }
                              }}
                              placeholder={"Postal"}
                              // toPostalLength={topostalLength}
                              lengthInput={topostalLength || 10}
                            />
                          </Paper>
                          <ErrorMessage
                            name={"toPostal"}
                            component="div"
                            className="text-danger"
                          />
                          {/* {formErrors?.toPostal && (
                            <Form.Text className="text-danger mt-0">
                              {formErrors?.toPostal}
                            </Form.Text>
                          )} */}
                          {/* </Form.Group> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-block d-xl-none d-xxl-none">
                      <hr
                        style={{
                          color: "#BABABA",
                          border: "1px solid #BABABA",
                        }}
                      />
                    </div>

                    <Card className="p-0 p-xl-4 p-xxl-4 position-relative multishipment-border">
                      <div
                        className="position-absolute px-3 bg-white d-none d-xl-flex d-xxl-flex "
                        style={{ top: "-14px" }}
                      >
                        <p className="paragraph2 p-multihead">Enter Details</p>
                      </div>
                      <FieldArray name="shipments">
                        {({ push, remove }) => (
                          <div className="d-flex gap-3 mb-4">
                            <div className=" " style={{ flex: 2 }}>
                              {values.shipments.map((shipment, index) => (
                                <div
                                  className="d-flex flex-column flex-sm-column flex-md-column flex-lg-column flex-xl-row flex-xxl-row gap-4 mb-0 mb-xl-4"
                                  key={index}
                                >
                                  <Form.Group
                                    className="w-100"
                                    style={{ flex: "2" }}
                                  >
                                    <p className="paragraph2 p-multihead fromInputLabel">
                                      Parcels
                                    </p>
                                    <Form.Select
                                      value={selectData?.parcel_name}
                                      className={`wallet-input is-valid-paper ${
                                        parcelData?.parcel_name ||
                                        index !== values.shipments.length - 1
                                          ? "testclass"
                                          : "pickup-placeholder-select-multi"
                                      }`}
                                      disabled={
                                        index !== values.shipments.length - 1
                                      }
                                      onChange={(event) => {
                                        const selectedIndex =
                                          event.target.value;
                                        const selectedData =
                                          selectData[selectedIndex];
                                        setParcelData(selectedData);
                                        if (selectedIndex !== "") {
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].weight`,
                                            selectedData?.parcel_weight
                                          );
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].height`,
                                            selectedData?.parcel_height
                                          );
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].width`,
                                            selectedData?.parcel_width
                                          );
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].length`,
                                            selectedData?.parcel_length
                                          );
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].unit`,
                                            "1"
                                          );

                                          setQuantity(
                                            ...quantity,
                                            values.shipments[index]?.unit
                                          );
                                          // setFieldValue(
                                          //   `shipments[${
                                          //     values.shipments.length - 1
                                          //   }].weight_in`,
                                          //   selectedData?.parcel_weight_measure
                                          // );
                                          setFieldValue(
                                            `shipments[${
                                              values.shipments.length - 1
                                            }].size_in`,
                                            selectedData?.parcel_dimension_in
                                          );
                                        }
                                      }}
                                    >
                                      {selectData ? (
                                        selectData.map((options, index) => (
                                          <option
                                            key={index}
                                            value={index}
                                            disabled={index === 0}
                                            selected={index === 0}
                                          >
                                            {options?.parcel_name}
                                          </option>
                                        ))
                                      ) : (
                                        <option disabled>
                                          No Parcel Saved...
                                        </option>
                                      )}
                                    </Form.Select>
                                  </Form.Group>

                                  <div
                                    className="d-flex gap-4"
                                    style={{ flex: "2" }}
                                  >
                                    <Form.Group
                                      className="w-100"
                                      style={{ flex: "2" }}
                                      controlId={`unit${index}`}
                                    >
                                      <p className="paragraph2 p-multihead fromInputLabel">
                                        Quantity
                                      </p>
                                      <Field
                                        type="number"
                                        name={`shipments.${index}.unit`}
                                        placeholder="01"
                                        className={`form-control multiship-fields ${
                                          errors.shipments &&
                                          errors.shipments[index] &&
                                          errors.shipments[index].unit &&
                                          touched.shipments &&
                                          touched.shipments[index] &&
                                          touched.shipments[index].unit
                                            ? "is-invalid-paper"
                                            : "is-valid-paper"
                                        }`}
                                        onKeyPress={(e) => {
                                          const charCode =
                                            e.charCode || e.which;
                                          const value =
                                            e.target.value +
                                            String.fromCharCode(charCode);
                                          const isValid =
                                            /^\d+$/.test(value) &&
                                            parseInt(value) >= 1 &&
                                            parseInt(value) <= 15;

                                          if (!isValid) {
                                            e.preventDefault();
                                          }
                                        }}
                                        disabled={
                                          index !== values.shipments.length - 1
                                        }
                                        onChange={(e) => {
                                          handleqty(e, index);
                                        }}
                                      />
                                      <ErrorMessage
                                        name={`shipments.${index}.unit`}
                                        component="div"
                                        className="text-danger"
                                      />
                                    </Form.Group>

                                    <Form.Group
                                      className=""
                                      style={{ flex: "3" }}
                                    >
                                      <p className="paragraph2 p-multihead fromInputLabel">
                                        Weight
                                      </p>
                                      <div className="d-flex position-relative">
                                        <Field
                                          type="text" // Use "text" to allow custom validation
                                          name={`shipments.${index}.weight`}
                                          value={
                                            values.shipments[index]?.weight
                                          }
                                          placeholder="240"
                                          className={`form-control w-100 multiship-fields multifield-weight ${
                                            errors.shipments &&
                                            errors.shipments[index] &&
                                            errors.shipments[index].weight &&
                                            touched.shipments &&
                                            touched.shipments[index] &&
                                            touched.shipments[index].weight
                                              ? "is-invalid-paper"
                                              : "is-valid-paper"
                                          }`}
                                          onChange={(e) => {
                                            let value = e.target.value;

                                            // Remove all characters except digits 1-9 and a single decimal point
                                            value = value.replace(
                                              /[^0-9.]/g,
                                              ""
                                            );

                                            // Ensure only one decimal point
                                            if (value.split(".").length > 2) {
                                              value = value.replace(/\.+$/, "");
                                            }

                                            e.target.value = value;
                                            handleChange(e); // Call Formik's handleChange with the sanitized value
                                          }}
                                          disabled={
                                            index !==
                                            values.shipments.length - 1
                                          }
                                        />

                                        <Form.Select
                                          onChange={handleChange}
                                          className="bg-transparent form-control border-0 position-absolute w-auto ps-3 multiship-fields multiship-select-in-lbs"
                                          size="sm"
                                          name={`shipments.${index}.weight_in`}
                                          disabled={
                                            index !==
                                            values.shipments.length - 1
                                          }
                                          value={
                                            values.shipments[index].weight_in
                                          }
                                        >
                                          <option value="LBS">LBS</option>
                                          <option value="KG">KG</option>
                                        </Form.Select>
                                      </div>

                                      <ErrorMessage
                                        name={`shipments.${index}.weight`}
                                        component="div"
                                        className="text-danger"
                                      />
                                      {errors.weight &&
                                        touched.weight &&
                                        errors.weight}
                                    </Form.Group>
                                  </div>

                                  <div className="" style={{ flex: "3" }}>
                                    <p className="paragraph2 p-multihead fromInputLabel">
                                      Size
                                    </p>
                                    <Form.Group className="size-formgroup-multiship">
                                      <div className="d-flex gap-2">
                                        <div
                                          className="d-flex"
                                          style={{ flex: "4" }}
                                        >
                                          <div
                                            className="d-flex"
                                            style={{ flex: "1" }}
                                          >
                                            <div className="w-100">
                                              <Field
                                                type="number"
                                                name={`shipments.${index}.length`}
                                                placeholder="length"
                                                className={`form-control sizeinput-multi multiship-fields ${
                                                  errors.shipments &&
                                                  errors.shipments[index] &&
                                                  errors.shipments[index]
                                                    .length &&
                                                  touched.shipments &&
                                                  touched.shipments[index] &&
                                                  touched.shipments[index]
                                                    .length
                                                    ? "is-invalid-paper"
                                                    : "is-valid-paper"
                                                }`}
                                                disabled={
                                                  index !==
                                                  values.shipments.length - 1
                                                }
                                                onChange={handleChange}
                                                onKeyPress={(e) => {
                                                  if (!/^[0-9]$/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  const inputValue =
                                                    e.target.value;
                                                  if (inputValue.length >= 3) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`shipments.${index}.length`}
                                                component="div"
                                                className="text-danger"
                                                disabled={
                                                  index !==
                                                  values.shipments.length - 1
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <CloseIcon
                                              style={{
                                                color: "#bababa",
                                                paddingLeft: "0px !important",
                                                paddingRight: "0px !important",
                                                height: "20px !important",
                                                width: "20px",
                                                marginTop: "14px",
                                              }}
                                            />
                                          </div>

                                          <div
                                            className="d-flex"
                                            style={{ flex: "1" }}
                                          >
                                            <div className="w-100">
                                              <Field
                                                type="number"
                                                name={`shipments.${index}.width`}
                                                placeholder="width"
                                                className={`form-control  sizeinput-multi multiship-fields  ${
                                                  errors.shipments &&
                                                  errors.shipments[index] &&
                                                  errors.shipments[index]
                                                    .width &&
                                                  touched.shipments &&
                                                  touched.shipments[index] &&
                                                  touched.shipments[index].width
                                                    ? "is-invalid-paper"
                                                    : "is-valid-paper"
                                                }`}
                                                disabled={
                                                  index !==
                                                  values.shipments.length - 1
                                                }
                                                onChange={handleChange}
                                                onKeyPress={(e) => {
                                                  if (!/^[0-9]$/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  const inputValue =
                                                    e.target.value;
                                                  if (inputValue.length >= 3) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`shipments.${index}.width`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <CloseIcon
                                              style={{
                                                color: "#bababa",
                                                paddingLeft: "0px !important",
                                                paddingRight: "0px !important",
                                                height: "20px !important",
                                                width: "20px",
                                                marginTop: "14px",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="d-flex flex-column"
                                            style={{ flex: "1" }}
                                          >
                                            <div className="w-100">
                                              <Field
                                                type="number"
                                                name={`shipments.${index}.height`}
                                                placeholder="height"
                                                className={`form-control sizeinput-multi multiship-fields ${
                                                  errors.shipments &&
                                                  errors.shipments[index] &&
                                                  errors.shipments[index]
                                                    .height &&
                                                  touched.shipments &&
                                                  touched.shipments[index] &&
                                                  touched.shipments[index]
                                                    .height
                                                    ? "is-invalid-paper"
                                                    : "is-valid-paper"
                                                }`}
                                                disabled={
                                                  index !==
                                                  values.shipments.length - 1
                                                }
                                                onChange={handleChange}
                                                onKeyPress={(e) => {
                                                  if (!/^[0-9]$/.test(e.key)) {
                                                    e.preventDefault();
                                                    return;
                                                  }
                                                  const inputValue =
                                                    e.target.value;
                                                  if (inputValue.length >= 3) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                              />
                                              <ErrorMessage
                                                name={`shipments.${index}.height`}
                                                component="div"
                                                className="text-danger"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{ flex: "1" }}>
                                          <div
                                            className="h-100 "
                                            style={{ flex: "1" }}
                                          >
                                            <Form.Select
                                              className="multiship-fields multiship-select-in-lbh is-valid-paper"
                                              onChange={handleChange}
                                              size="sm"
                                              name={`shipments.${index}.size_in`}
                                              disabled={
                                                index !==
                                                values.shipments.length - 1
                                              }
                                              value={
                                                values.shipments[index].size_in
                                              }
                                            >
                                              <option value="IN">IN</option>
                                              <option value="CM">CM</option>
                                            </Form.Select>
                                          </div>
                                        </div>
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <div
                                    className="d-flex gap-3"
                                    style={{ flex: "1" }}
                                  >
                                    {values.shipments.length > 0 && (
                                      <div
                                        style={{ flex: 1 }}
                                        className="multi-quantity"
                                      >
                                        <div className="postal-code-smart-ship">
                                          <span className="d-none d-lg-block">
                                            &nbsp;
                                          </span>
                                          <Button
                                            onClick={() => remove(index)}
                                            className="secondry btn w-100 multiship-input text-nowrap multiship-fields"
                                            disabled={
                                              values.shipments.length === 1
                                            }
                                          >
                                            - Remove Shipment
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                              {errorState && (
                                <>
                                  <p className="text-danger">{errorState}</p>
                                </>
                              )}
                              {multierrors && (
                                <p className="text-danger">{multierrors}</p>
                              )}

                              <div className="d-flex justify-content-center ">
                                <div className=" multishipment-buttons   px-0 px-xl-4 bg-white ">
                                  <div className=" d-flex flex-column flex-lg-column flex-xl-row gap-4 justify-content-center mt-4">
                                    <div className="w-100">
                                      <Button
                                        className="secondry btn px-auto w-100 multiship-input text-nowrap multiship-fields add-shipment-multi-btn multiship-fields"
                                        disabled={
                                          !values.shipments.every(
                                            (shipment) =>
                                              shipment.height &&
                                              shipment.width &&
                                              shipment.length &&
                                              shipment.weight &&
                                              shipment.unit
                                          ) ||
                                          errors.shipments ||
                                          values.shipments.length >= 5
                                        }
                                        onClick={() => {
                                          setParcelData("");
                                          const lastShipment =
                                            values.shipments[
                                              values.shipments.length - 1
                                            ];
                                          if (
                                            lastShipment.height &&
                                            lastShipment.width &&
                                            lastShipment.length &&
                                            lastShipment.weight &&
                                            lastShipment.unit &&
                                            !errors.shipments
                                          ) {
                                            push({
                                              height: "",
                                              width: "",
                                              length: "",
                                              weight: "",
                                              unit: "",
                                            });
                                          }
                                        }}
                                      >
                                        + Add New Shipment
                                      </Button>
                                    </div>
                                    <div className="w-100">
                                      <Button
                                        className=" primarybtn btn px-auto multiship-input multiship-fields text-nowrap multiship-fields"
                                        type="submit"
                                        style={{
                                          width: "-webkit-fill-available",
                                        }}
                                      >
                                        {/* || isCountryLoading */}
                                        {isLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          <>Get Quote & Book</>
                                        )}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </FieldArray>
                    </Card>
                  </Form>
                )
              )}
            </Formik>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default MultiShipment;
