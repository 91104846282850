/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/styles/footer.css";
import { Link, useNavigate } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { resetErrors } from "../../redux/landing/landingSlice";
import { useDispatch } from "react-redux";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let year = new Date().getFullYear();

  const scrollToTop = (path, redirect) => {
    if (path && redirect !== "no") {
      navigate(`/footer/${path}`);
    } else if (redirect === "no") {
      navigate(`/${path}`)
    } else {
      navigate('/')
    }
  };
  const navigationFun = (e) => {
    navigate("/");
    setTimeout(() => {
      window.scroll({
        top: 700,
        left: 0,
        behavior: "smooth",
      });
    }, 1500);
  };
  return (
    <>
      {/* *****************new footer */}
      <div className="footersection">
        <div className="footer footerall d-flex flex-column">
          <div className="container p-0 d-flex gap-4 flex-column flex-sm-column flex-md-row flex-lg-row py-4">
            <div className="d-flex px-4 px-sm-0 px-md-0 px-lg-0  flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row gap-4">
              <div
                className=" footerlinktext footer-logo "
                style={{ flex: "5" }}
              >
                <div className="footerlogotext footer1 d-flex flex-column gap-4 gap-lg-5">
                  <div>
                    <p
                      to="/"
                      onClick={() => scrollToTop()}>
                      <Image src={`${window.location.origin}/assets/LSN_logo.svg`} alt="footer-logo" width={128} height={50} fluid className="footerlogo"
                      />
                    </p>
                  </div>
                  <div>
                    <p className="footert pe-0 pe-lg-3" style={{ textAlign: "justify" }}  >
                      Let’s Ship Now is a purpose-built platform form where user
                      gets most economical, fastest, and reliable domestic &
                      international shipping service with leading shipping
                      carriers like Fedex and UPS for their individual needs as
                      well as business needs.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="d-flex flex-column footerlinktext  gap-4  "
                style={{ flex: "6" }}
              >
                <div className="d-flex  gap-4 flex-column flex-sm-row flex-md-row flex-lg-row ">
                  <div className=" footerlinktext   " style={{ flex: "3" }}>
                    <div className="d-flex flex-column  align-items-start align-items-lg-center">
                      <div className="footertextrdiv d-flex flex-column gap-4">
                        <div>
                          <p className="paragraph1 masterlink">Delivery to</p>
                        </div>
                        <div className="d-flex flex-column delivery-information-links">
                          <p className="footertextp m-0">
                            <p
                              // to="/package-delivery-to-united-states-of-america"
                              onClick={() => scrollToTop('package-delivery-to-united-states-of-america')}
                              className="footerlinks m-0"
                            >
                              USA{" "}
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              // to="/package-delivery-to-canada"
                              onClick={() => scrollToTop('package-delivery-to-canada')}
                              className="footerlinks m-0"
                            >
                              Canada
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              // to="/package-delivery-to-united-kingdom"
                              onClick={() => scrollToTop('package-delivery-to-united-kingdom')}
                              className="footerlinks m-0"
                            >
                              United Kingdom
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              // to="/package-delivery-to-india"
                              onClick={() => scrollToTop('package-delivery-to-india')}
                              className="footerlinks m-0"
                            >
                              India
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              // to="/package-delivery-to-germany"
                              onClick={() => scrollToTop('package-delivery-to-germany')}
                              className="footerlinks m-0"
                            >
                              Germany
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              className="footerlinks m-0"
                              // to="/package-delivery-to-russia"
                              onClick={() => scrollToTop('package-delivery-to-russia')}
                            >
                              Russia
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="   " style={{ flex: "3" }}>
                    <div className="d-flex flex-column  d-flex flex-column  align-items-start align-items-sm-end align-items-md-start align-items-lg-start align-items-xl-center align-items-xxl-end">
                      <div className="footertextrdiv w-75 text-start d-flex flex-column gap-4 ">
                        <div>
                          <p className="paragraph1 masterlink">Information</p>
                        </div>
                        <div className="d-flex flex-column delivery-information-links">
                          <p className=" footertextp m-0">
                            <p className="m-0"
                              // to="/contact-us"
                              onClick={() => scrollToTop('contact-us', "no")}>
                              Contact Us
                            </p>
                          </p>
                          <p className="footertextp m-0 font-16">
                            <p className="font-16"
                              href="#"
                              onClick={(e) => {
                                navigationFun(e);
                              }}
                            >
                              How it Works
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p
                              //  to="/trackshipment" 
                              onClick={() => scrollToTop('trackshipment', 'no')}
                              className="footerlinks m-0">
                              Track Shipment
                            </p>
                          </p>
                          <p className=" footertextp m-0">
                            <p className="m-0"
                              //  to="/our-couriers"
                              onClick={() => scrollToTop('our-couriers')}>
                              Our Couriers
                            </p>
                          </p>
                          <p className=" footertextp m-0 pickup-text">
                            <p className="m-0"
                              // to="/pickup-drop-locations"
                              onClick={() => scrollToTop('pickup-drop-locations', "no")}
                            >
                              Pickup & Drop Locations
                            </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className=" d-none d-sm-none d-md-block d-lg-block"
                    style={{ flex: "2" }}
                  >
                    <div className="d-flex flex-column  align-items-start align-items-md-end align-items-lg-end ">
                      <div className="footertextrdiv d-flex flex-column gap-4  contact-us-links">
                        <div>
                          <p className="paragraph1 masterlink">Contact US</p>
                        </div>
                        <div className="d-flex flex-column delivery-information-links">
                          <p className=" footertextp">
                            <a
                              href="https://www.facebook.com/people/Lets-Ship-Now/100081614497579/"
                              target="_blank"
                            >
                              <Image
                                src={`${window.location.origin}/assets/facebook.svg`}
                                alt="fb"
                                name="facebook"
                                width={24}
                                height={24}
                                className="footer-icon-social"
                              />
                              <span className=" footer-icon-social ms-2 font-16">
                                Facebook
                              </span>
                            </a>
                          </p>
                          <p className=" footertextp">
                            <a
                              href="https://www.instagram.com/lets_shipnow/"
                              target="_blank"
                            >
                              <Image
                                src={`${window.location.origin}/assets/instagram.svg`}
                                alt="insta"
                                name="instagram"
                                className="footer-icon-social"
                                width={21}
                                height={24}
                              />
                              <span className=" footer-icon-social ms-2 font-16">
                                Instagram
                              </span>
                            </a>
                          </p>
                          <p className="footertextp">
                            <a
                              href="https://www.linkedin.com/company/letsshipnow/"
                              target="_blank"
                            >
                              <Image
                                src={`${window.location.origin}/assets/linkedin.svg`}
                                alt="in"
                                name="linkedin"
                                className="footer-icon-social"
                                width={21}
                                height={24}
                              />
                              <span className=" footer-icon-social ms-2 font-16">
                                LinkedIn
                              </span>
                            </a>
                          </p>
                          <p className=" footertextp">
                            <a
                              href="https://twitter.com/LetsShipNow"
                              target="_blank"
                            >
                              <Image
                                src={`${window.location.origin}/assets/x_logo.svg`}
                                alt="twit"
                                name="twitter"
                                className="footer-icon-social"
                                width={22}
                                height={22}
                              />
                              <span className=" footer-icon-social ms-2 font-16">
                                Twitter
                              </span>
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" d-xxl-none d-xl-none d-lg-none d-md-none">
                  <div className="d-flex footer-social ">
                    <div className="d-flex align-items-center">
                      <p className="paragraph1 masterlink  mb-0 me-2 ">
                        Find Us :{" "}
                      </p>
                    </div>
                    <div className="d-flex gap-3">
                      <p className=" footertextp m-0">
                        <a
                          href="https://www.facebook.com/people/Lets-Ship-Now/100081614497579/"
                          target="_blank"
                        >
                          <Image
                            src={`${window.location.origin}/assets/facebook.svg`}
                            alt="facebook"
                            name="facebook"
                            width={24}
                            height={24}
                            className="footer-icon-social"
                          />
                        </a>
                      </p>
                      <p className=" footertextp m-0">
                        <a
                          href="https://www.instagram.com/lets_shipnow/"
                          target="_blank"
                        >
                          <Image
                            src={`${window.location.origin}/assets/instagram.svg`}
                            alt="instagram"
                            name="instagram"
                            width={21}
                            height={24}
                            className="footer-icon-social"
                          />
                        </a>
                      </p>
                      <p className="footertextp m-0">
                        <a
                          href="https://www.linkedin.com/company/letsshipnow/"
                          target="_blank"
                        >
                          <Image
                            src={`${window.location.origin}/assets/linkedin.svg`}
                            alt="linkedin"
                            name="linkedin"
                            width={21}
                            height={24}
                            className="footer-icon-social"
                          />
                        </a>
                      </p>
                      <p className=" footertextp m-0">
                        <a
                          href="https://twitter.com/LetsShipNow"
                          target="_blank"
                        >
                          <Image
                            src={`${window.location.origin}/assets/x_logo.svg`}
                            alt="twit"
                            name="twitter"
                            className="footer-icon-social"
                            width={22}
                            height={22}
                          />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-hr-line">
            <hr className="m-0" />
          </div>
          <div className="d-flex flex-column py-4">
            <div className="d-flex flex-column gap-4 container p-0 ">
              <div className="d-none d-sm-none d-md-block d-lg-block ">
                <p className=" footerlasttext text-center mb-2 d-flex justify-content-center">
                  <p className="cursor-pointer"
                    //  to="/packaging-guidelines"
                    onClick={() => scrollToTop('packaging-guidelines')}>
                    Packaging Guidelines
                  </p>
                  <span className="ms-2 me-2">•</span>
                  <p className="cursor-pointer"
                    //  to="/prohibited-items" 
                    onClick={() => scrollToTop('prohibited-items')}>
                    Prohibited Items
                  </p>
                  <span className="ms-2 me-2">•</span>
                  <p className="cursor-pointer"
                    // to="/privacy-policy"
                    onClick={() => scrollToTop('privacy-policy')}>
                    Privacy & Policy
                  </p>
                  <span className="ms-2 me-2">•</span>
                  <p className="cursor-pointer"
                    //  to="/refund-policy"
                    onClick={() => scrollToTop('refund-policy')}>
                    Refund Policy
                  </p>
                  <span className="ms-2 me-2">•</span>
                  <p className="cursor-pointer"
                    //  to="/cookies-Page"
                    onClick={() => scrollToTop('cookies-Page')}>
                    Cookies
                  </p>
                  <span className="ms-2 me-2">•</span>
                  <p className="cursor-pointer"
                    //  to="/faq-questions"
                    onClick={() => scrollToTop('faq-questions')}>
                    FAQs
                  </p>
                </p>
              </div>
              <div className="footer-text d-flex d-sm-flex d-md-none d-lg-none w-100 d-md-none ">
                {/* <div className="ps-3 mx-4"> */}
                <div
                  className="list-unstyled m-0"
                  style={{ listStyleType: "disc" }}
                >
                  <div className="d-flex flex-column">
                    <div className="d-flex   ">
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap ">
                        <ul>
                          <li className="paragraph2 footerlasttext mb-2">
                            <p
                              // to="/packaging-guidelines"
                              onClick={() => scrollToTop('packaging-guidelines')}
                            >
                              Packaging Guidelines
                            </p>
                          </li>
                        </ul>
                      </p>
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap">
                        <ul>
                          <li className="paragraph2 footerlasttext mb-2">
                            <p
                              // to="/prohibited-items"
                              onClick={() => scrollToTop('prohibited-items')}
                              className=""
                            >
                              Prohibited Items
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap">
                        <ul>
                          <li className="paragraph2 footerlasttext mb-2">
                            <p
                              // to="/privacy-policy"
                              onClick={() => scrollToTop('privacy-policy')}>
                              Privacy & Policy
                            </p>
                          </li>
                        </ul>
                      </p>
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap">
                        <ul>
                          <li className="paragraph2 footerlasttext mb-2">
                            <p
                              //  to="/refund-policy"
                              onClick={() => scrollToTop('refund-policy')}>
                              Refund Policy
                            </p>
                          </li>
                        </ul>
                      </p>
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap">
                        <ul>
                          <li className="paragraph2 footerlasttext mb-2">
                            <p
                              //  to="/cookies-Page"
                              onClick={() => scrollToTop('cookies-Page')}>
                              Cookies
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="paragraph2 footerlasttext mb-0 text-nowrap">
                        <ul>
                          <li className="paragraph2 footerlasttext">
                            <p
                              //  to="/faq-questions"
                              onClick={() => scrollToTop('faq-questions')}>
                              FAQs
                            </p>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </div>
              <div className="  ">
                <p className="text-center copyrighttext paragraph1" href="#">
                  <CopyrightIcon fontSize="small" /> Copyright {year}, All
                  Rights Reserved by Lets ship now LLC
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
