import React, { useState } from "react";
import {
  Card,
  Col,
  Form,
  Container,
  Row,
  Image,
  Spinner,
} from "react-bootstrap";
import "../assets/styles/footer/contactUs.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactusinfo } from "../redux/user_profile/ProfileThunk";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";

import { Button } from "@mui/material";
import Captcha from "./Captcha";

const Contactus = () => {
  const dispatch = useDispatch();

  const contactUsLoading = useSelector(
    (state) => state?.profileData?.iscontactusinfoLoading
  );
  const options = [
    "Select",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00",
  ];

  const [captchaText, setCaptchaText] = useState("");
  const [captchaTextgen, setCaptchaTextgen] = useState("");
  const [rotate, setRotate] = useState(false);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
    email: "",
    ConvenientTime: options[0],
    message: "",
    status: 0,
    contact_prefix: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    phone: "",
    captchaText: "",
    contact_prefix: "",
  });
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 14) return;
    if (
      name === "contact_prefix" &&
      (!/^[\d+]*$/.test(value) || value.length > 4)
    )
      return;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: "" });
  };

  const validateForm = () => {
    const errors = {};
    let formIsValid = true;

    if (formData.first_name.trim() === "") {
      errors.first_name = "Required";
      formIsValid = false;
    } else if (formData.first_name.length > 25) {
      errors.first_name = "Max 25 characters";
      formIsValid = false;
    }
    // if (formData.last_name.trim() === "") {
    //   errors.last_name = "Required";
    //   formIsValid = false;
    // } else 
    if (formData.last_name.length > 25) {
      errors.last_name = "Max 25 characters";
      formIsValid = false;
    }
    if (formData.email.trim() === "") {
      errors.email = "Required";
      formIsValid = false;
    } else {
      const emailRegex =
        /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/i;
      if (!emailRegex.test(formData.email)) {
        errors.email = "Invalid email address";
        formIsValid = false;
      }
    }
    if (captchaText.trim() === "") {
      errors.captchaText = "Required";
      formIsValid = false;
    }
    if (formData?.message.trim() === "") {
      errors.message = "Required";
      formIsValid = false;
    }
    if (formData?.phone.trim() === "") {
      errors.phone = "Required";
      formIsValid = false;
    } else {
      const phoneRegex = /^\d{9,14}$/;
      if (!phoneRegex.test(formData.phone)) {
        errors.phone = "Invalid phone number";
        formIsValid = false;
      }
    }
    if (formData?.contact_prefix.trim() === "") {
      errors.contact_prefix = "Required";
      formIsValid = false;
    }

    setFormErrors(errors);
    return formIsValid;
  };

  const validateCaptcha = () => {
    if (captchaText === captchaTextgen) {
      return true;
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        captchaText: "Invalid captcha",
      }));
      return false;
    }
  };

  const generateCaptcha = () => {
    setRotate(true);
    const characters =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const captchaLength = 6;
    let captcha = "";
    for (let i = 0; i < captchaLength; i++) {
      captcha += characters[Math.floor(Math.random() * characters.length)];
    }
    setTimeout(() => setRotate(false), 1000);
    setCaptchaTextgen(captcha);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleSendClick = () => {
    if (validateForm() && validateCaptcha()) {
      setRotate(true);
      dispatch(contactusinfo(formData))
        .unwrap()
        .then((response) => {
          generateCaptcha();
          setTimeout(() => setRotate(false), 2000);
          if (response.statusCode === 200 && response.status === true) {
            setCaptchaText("");
            setFormData({
              first_name: "",
              last_name: "",
              company_name: "",
              email: "",
              ConvenientTime: options[0],
              message: "",
              status: 0,
              phone: "",
              contact_prefix: "",
            });
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Inquiry Details sent successfully",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 2000,
              timerProgressBar: true,
              customClass: {
                timerProgressBar: "custom-progress-bar",
              },
            });
          }
          else{
            setFormErrors({
              first_name: "",
              last_name: "",
              email: "",
              phone: "",
              captchaText: "",
              message: "",
              contact_prefix: "",
            });
          }
        });
      setFormErrors({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        captchaText: "",
        message: "",
        contact_prefix: "",
      });
    }
  };

  return (
    <div className="contact-us-outer py-5 ">
      <>
        <Container className="p-0  d-flex justify-content-center">
          <Row className="w-100 d-flex justify-content-center">
            <Col lg={11} className="p-0">
              <Card
                className="contact-us-parent-card-holder   "
                style={{ borderRadius: "20px" }}
              >
                <div className="d-flex flex-column flex-md-row gap-3 p-3">
                  <div className="p-0" style={{ flex: "1" }}>
                    <Card className="contact-card border-0 bg-transparent pl-2 pr-0 gap-3">
                      <Card.Text>
                        <h5
                          className="lh-1"
                          style={{ color: "var(--white-font)" }}
                        >
                          Contact Us
                        </h5>
                      </Card.Text>
                      <div className="w-100">
                        <Image
                          src={`${window.location.origin}/assets/contactus/contact_us_banner.png`}
                          fluid
                          className="footerlogo"
                          alt="banner"
                          style={{ width: "100%" }}
                        />
                      </div>
                      <Card.Text>
                        <p
                          className="paragraph2"
                          style={{ color: "var(--white-font)" }}
                        >
                          We value your feedback, questions, and comments. Feel
                          free to get in touch with us. Our team is here to
                          assist you.
                        </p>
                      </Card.Text>
                      <div className=" d-flex gap-2 flex-column flex-lg-row flex-xl-row flex-xxl-row">
                        <div className="d-flex gap-2 w-100">
                          <div className=" w-100">
                            <Image
                              alt="27global"
                              src={`${window.location.origin}/assets/contactus/watch.svg`}
                              fluid
                              className="footerlogo mb-2"
                            />
                            <p
                              className="paragraph2"
                              style={{
                                color: "var(--white-font)",
                                lineHeight: "20px",
                              }}
                            >
                              24/7 Global{" "}
                              <span style={{ display: "block" }}>
                                Support Team
                              </span>
                            </p>
                          </div>
                          <div className=" w-100">
                            <Image
                              alt="Swift"
                              src={`${window.location.origin}/assets/contactus/swift.svg`}
                              fluid
                              className="footerlogo mb-2"
                            />
                            <p
                              className="paragraph2"
                              style={{
                                color: "var(--white-font)",
                                lineHeight: "20px",
                              }}
                            >
                              Swift & Satisfying{" "}
                              <span style={{ display: "block" }}>
                                {" "}
                                Query Responses
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex gap-2 w-100">
                          <div className=" w-100">
                            <Image
                              alt="Proactive"
                              src={`${window.location.origin}/assets/contactus/proactive.svg`}
                              fluid
                              className="footerlogo mb-1"
                            />
                            <p
                              className="paragraph2"
                              style={{ color: "var(--white-font)" }}
                            >
                              Proactive Issue
                              <span
                                className="d-block"
                                style={{ lineHeight: "20px" }}
                              >
                                {" "}
                                Resolution
                              </span>
                            </p>
                          </div>
                          <div className=" w-100">
                            <Image
                              alt="Seamless"
                              src={`${window.location.origin}/assets/contactus/seamless.svg`}
                              fluid
                              className="footerlogo mb-1"
                            />
                            <p
                              className="paragraph2"
                              style={{ color: "var(--white-font)" }}
                            >
                              Seamless
                              <span
                                style={{ display: "block", lineHeight: "20px" }}
                              >
                                {" "}
                                Communication
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <Card.Text>
                        <h5 style={{ color: "var(--white-font)" }}>Email:</h5>
                        <div className="d-flex">
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 paragraph2"
                          >
                            Bulk Shipment Inquiries
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="ms-2"
                          >
                            :
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 mr-3 ms-2 font-weight-300 paragraph2"
                          >
                            bulk@letsshipnow.com
                          </p>
                        </div>
                        <div className="d-flex">
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 paragraph2"
                          >
                            Customer Support
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="ms-2"
                          >
                            :
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 text-right ms-2 font-weight-300 paragraph2"
                          >
                            support@letsshipnow.com
                          </p>
                        </div>
                      </Card.Text>
                      <Card.Text>
                        <h5 style={{ color: "var(--white-font)" }}>Phone:</h5>
                        <div className="d-flex">
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 paragraph2"
                          >
                            Mobile
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="ms-2"
                          >
                            :
                          </p>
                          <p
                            style={{ color: "var(--white-font)" }}
                            className="m-0 mr-3 ms-2 font-weight-300 paragraph2"
                          >
                            +1 <span>(</span>312<span>)</span> 375 5082
                          </p>
                        </div>
                      </Card.Text>
                      {/* <Card.Text className="d-flex align-align-items-center "
                        style={{ color: "var(--white-font)" }} >

                      </Card.Text> */}
                    </Card>
                  </div>
                  <div className="p-0" style={{ flex: "1" }}>
                    <Card
                      className=""
                      style={{ border: "none", borderRadius: "10px" }}
                    >
                      <Form className="px-4 py-4">
                        <>
                          <div className="d-flex flex-column gap-3">
                            <Card.Text className="">
                              <h5 className="blue-text">
                                Send a Message to Us
                              </h5>
                            </Card.Text>
                            <div className=" d-flex gap-3 ">
                              <Form.Group
                                className="w-100"
                                controlId="formGridfirstname"
                              >
                                <Form.Label className="formlabel">
                                  <p className="paragraph2 of-black-dark">
                                    First Name
                                  </p>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  className={`${
                                    formErrors.first_name
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                  }`}
                                  name="first_name"
                                  placeholder="Enter first name"
                                  onChange={handleChange}
                                  onKeyPress={(e) => {
                                    if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formData?.first_name}
                                  isInvalid={
                                    formErrors?.first_name !== "" &&
                                    formData?.first_name === ""
                                  }
                                />
                                {formErrors?.first_name && <p className="invalid-feedback"><b>{formErrors?.first_name}</b></p>}
                                {/* <Form.Control.Feedback type="invalid">
                                   {formErrors?.first_name} 
                                </Form.Control.Feedback> */}
                              </Form.Group>
                              <Form.Group
                                className="w-100"
                                controlId="formGridlastname"
                              >
                                <Form.Label className="formlabel">
                                  <p className="paragraph2 of-black-dark">
                                    Last Name
                                  </p>
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="last_name"
                                  placeholder="Enter last name"
                                  // className={`${
                                  //   formErrors?.last_name
                                  //     ? "is-invalid-paper"
                                  //     : "is-valid-paper"
                                  // }`}
                                  onChange={handleChange}
                                  onKeyPress={(e) => {
                                    if (!/^[a-zA-Z\s]*$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  value={formData?.last_name}
                                  // isInvalid={
                                  //   formErrors?.last_name !== "" &&
                                  //   formData?.last_name === ""
                                  // }
                                />
                                {formErrors?.last_name && <p className="invalid-feedback"><b>{formErrors?.last_name}</b></p>}

                                {/* <Form.Control.Feedback type="invalid">
                                  {formErrors?.last_name}
                                </Form.Control.Feedback> */}
                              </Form.Group>
                            </div>
                            <Form.Group
                              className=""
                              controlId="formGridBusinessName"
                            >
                              <Form.Label className="formlabel">
                                <p className="paragraph2 of-black-dark">
                                  {" "}
                                  Business Name
                                </p>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="company_name"
                                className="is-valid-paper"
                                value={formData?.company_name}
                                placeholder="Enter business name"
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (!/^[a-zA-Z\s.&]*$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                            <Form.Group
                              className="form-email-contact "
                              controlId="formGridemailAddress1"
                            >
                              <Form.Label className="formlabel">
                                <p className="paragraph2 of-black-dark">
                                  {" "}
                                  Email Address
                                </p>
                              </Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter email address"
                                className={`no-background-image ${
                                  formErrors?.email
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                }`}
                                onChange={handleChange}
                                value={formData?.email}
                                isInvalid={!!formErrors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors?.email}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <div className=" d-flex gap-3">
                              <div className="" style={{ flex: "1" }}>
                                <Form.Label className="formlabel">
                                  <p className="paragraph2 of-black-dark">
                                    {" "}
                                    Phone
                                  </p>
                                </Form.Label>
                                <Form.Control
                                  className={`user-address-placeholder input-main-all ${
                                    formErrors.contact_prefix
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                  }`}
                                  type="number"
                                  name="contact_prefix"
                                  placeholder="+91"
                                  value={formData?.contact_prefix}
                                  onChange={handleChange}
                                  onKeyPress={(e) => {
                                    if (!/^[0-9+]+$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  pattern="[0-9]*"
                                  isInvalid={
                                    formErrors?.contact_prefix !== "" &&
                                    formData?.contact_prefix === ""
                                  }
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  {formErrors?.contact_prefix}
                                </Form.Control.Feedback>
                              </div>
                              <div className="" style={{ flex: "4" }}>
                                <Form.Label className="formlabel">
                                  <p className="paragraph2 of-black-dark"> </p>
                                </Form.Label>
                                <Form.Control
                                  className={`user-address-placeholder input-main-all ${
                                    formErrors.phone
                                      ? "is-invalid-paper"
                                      : "is-valid-paper"
                                  }`}
                                  type="number"
                                  placeholder="Contact no"
                                  name="phone"
                                  value={formData?.phone}
                                  onChange={handleChange}
                                  onKeyPress={(e) => {
                                    if (!/^[0-9]*$/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onWheel={(event) =>
                                    event.currentTarget.blur()
                                  }
                                  pattern="[0-9]*"
                                  isInvalid={!!formErrors.phone}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {formErrors?.phone}
                                </Form.Control.Feedback>
                              </div>
                            </div>
                            <Form.Group
                              className="height-44"
                              as={Col}
                              controlId="formGridConvenientTime"
                            >
                              <Form.Label className="formlabel">
                                <p className="paragraph2 of-black-dark">
                                  Convenient Time
                                </p>
                              </Form.Label>
                              <Form.Select
                                className={`contact-select-time height-44 ${
                                  formData.ConvenientTime === "Select" &&
                                  "contact-placeholder-select"
                                }`}
                                name="ConvenientTime"
                                value={formData?.ConvenientTime}
                                onChange={handleChange}
                              >
                                <KeyboardArrowDownIcon
                                  sx={{ color: "#E9E9E9" }}
                                />
                                {options.map((option, index) => (
                                  <option
                                    key={index}
                                    value={option}
                                    disabled={index === 0}
                                  >
                                    {option}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>
                                <p className="paragraph2 of-black-dark">
                                  Message
                                </p>
                              </Form.Label>
                              <Form.Control
                                style={{ height: "75px", maxHeight: "300px" }}
                                as="textarea"
                                className={` textarea-contact ${
                                  formErrors.message
                                    ? "is-invalid-paper"
                                    : "is-valid-paper"
                                }`}
                                name="message"
                                value={formData?.message}
                                placeholder="Tell us about your shipping needs in a few words"
                                rows={5}
                                isInvalid={
                                  formErrors?.message !== "" &&
                                  formData?.message === ""
                                }
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formErrors?.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Captcha
                              rotate={rotate}
                              formErrors={formErrors}
                              formData={formData}
                              setCaptchaText={setCaptchaText}
                              captchaText={captchaText}
                              setFormErrors={setFormErrors}
                              setCaptchaTextgen={setCaptchaTextgen}
                              captchaTextgen={captchaTextgen}
                              generateCaptcha={generateCaptcha}
                            />
                            <Row className="mx-auto w-100 ">
                              <Button
                                className="primarybtn btn py-2 W-100"
                                style={{ height: "40px" }}
                                onClick={() => handleSendClick()}
                              >
                                {contactUsLoading ? (
                                  <>
                                    <Spinner animation="border" size="sm" />
                                  </>
                                ) : (
                                  <>SEND</>
                                )}
                              </Button>
                            </Row>
                          </div>
                        </>
                      </Form>
                    </Card>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </div>
  );
};

export default Contactus;
