import React from 'react'
import { Skeleton, Box } from "@mui/material";
import { Card } from "react-bootstrap";



const UserSkeleton = ({ width, twoLine, tworound }) => {
    return (
        <>
            <div className='d-flex gap-4 flex-column flex-sm-row' style={{ width: width }} >
                <Card className="card-border " style={{ flex: "1" }} >
                    <Card.Body className='p-3'>
                        <div className='d-flex flex-column gap-3'>
                            <div className="d-flex d-sm-flex d-md-none d-lg-flex flex-column flex-sm-row flex-xxl-row gap-0 gap-sm-3 gap-xxl-3">
                                <Box className="d-flex align-items-start gap-1 w-100 loading-download-invoice justify-content-between">

                                    <div className='d-flex gap-4'>
                                        <Skeleton
                                            variant="rectangular"
                                            width={150}
                                            height={25}
                                            className="rounded"
                                        />
                                    </div>

                                    <div className='d-flex gap-4'>
                                        <Skeleton
                                            variant="rectangular"
                                            width={25}
                                            height={25}
                                            className="rounded"
                                        />
                                        {tworound === true && (
                                            <>
                                                <Skeleton
                                                    variant="rectangular"
                                                    width={25}
                                                    height={25}
                                                    className="rounded"
                                                />
                                            </>
                                        )}
                                    </div>
                                </Box>
                            </div>
                            <div className='d-flex gap-2 flex-column '>
                                <div className="d-flex">
                                    <Skeleton variant="rectangular"
                                        height={25} className="rounded w-50" />
                                </div>
                                <div className="d-flex">
                                    <Skeleton variant="rectangular"
                                        height={25} className="rounded w-75" />
                                </div>
                                {twoLine === true && (
                                    <>
                                        <div className="d-flex">
                                            <Skeleton variant="rectangular"
                                                height={25} className="rounded w-100" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className="card-border" style={{ flex: "1" }}>
                    <Card.Body className='p-3'>
                        <div className='d-flex flex-column gap-3'>
                            <div className="d-flex d-sm-flex d-md-none d-lg-flex flex-column flex-sm-row flex-xxl-row gap-0 gap-sm-3 gap-xxl-3">
                                <Box className="d-flex align-items-start gap-1 w-100 loading-download-invoice justify-content-between">

                                    <div className='d-flex gap-4'>
                                        <Skeleton
                                            variant="rectangular"
                                            width={150}
                                            height={25}
                                            className="rounded"
                                        />
                                    </div>

                                    <div className='d-flex gap-4'>
                                        <Skeleton
                                            variant="rectangular"
                                            width={25}
                                            height={25}
                                            className="rounded"
                                        />
                                        {tworound === true && (
                                            <>
                                                <Skeleton
                                                    variant="rectangular"
                                                    width={25}
                                                    height={25}
                                                    className="rounded"
                                                />
                                            </>
                                        )}
                                    </div>
                                </Box>
                            </div>
                            <div className='d-flex gap-2 flex-column '>
                                <div className="d-flex">
                                    <Skeleton variant="rectangular"
                                        height={25} className="rounded w-50" />
                                </div>
                                <div className="d-flex">
                                    <Skeleton variant="rectangular"
                                        height={25} className="rounded w-75" />
                                </div>
                                {twoLine === true && (
                                    <>
                                        <div className="d-flex">
                                            <Skeleton variant="rectangular"
                                                height={25} className="rounded w-100" />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default UserSkeleton