import { Box, Skeleton } from '@mui/material'
import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'

const BulkOrderPreviewSkeleton = () => {
    return (
        <>
            <div>
                <div className='d-flex gap-4 my-5'>
                    <div style={{ flex: "4" }}>
                        <Card className='p-3 card-border'>
                            <Box className="w-100 d-flex flex-column gap-4 ">
                                <Box className="gap-3 d-flex align-items-center">
                                    <Skeleton animation="wave" className='w-25' variant="rounded" />
                                    <Skeleton animation="wave" className='w-75' height="7px" variant="rounded" />
                                </Box>
                                <Box className="d-flex gap-3">
                                    <Box className="w-100 p-2 border border-2 rounded-2 d-flex align-items-center gap-2">
                                        <Skeleton animation="wave" className='w-25' height="45px" variant="circular" />
                                        <Skeleton animation="wave" className='w-75' height="70px" variant="rounded" />
                                    </Box>
                                    <Box className="w-100 p-2 border border-2 rounded-2 d-flex align-items-center gap-2">
                                        <Skeleton animation="wave" className='w-25' height="45px" variant="circular" />
                                        <Skeleton animation="wave" className='w-75' height="70px" variant="rounded" />
                                    </Box>
                                    <Box className="w-100 p-2 border border-2 rounded-2 d-flex align-items-center gap-2">
                                        <Skeleton animation="wave" className='w-25' height="45px" variant="circular" />
                                        <Skeleton animation="wave" className='w-75' height="70px" variant="rounded" />
                                    </Box>
                                    <Box className="w-100 p-2 border border-2 rounded-2 d-flex align-items-center gap-2">
                                        <Skeleton animation="wave" className='w-25' height="45px" variant="circular" />
                                        <Skeleton animation="wave" className='w-75' height="70px" variant="rounded" />
                                    </Box>
                                </Box>
                                <Box className="w-100 p-3 border border-2 rounded-2 d-flex flex-column gap-4">
                                    <Skeleton animation="wave" className='w-25' variant="rounded" />
                                    <Box className="d-flex gap-3 w-100">
                                        <Skeleton animation="wave" className='w-75 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                    </Box>
                                    <Box className="d-flex gap-3 w-100">
                                        <Skeleton animation="wave" className='w-50 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                        <Skeleton animation="wave" className='w-25 height-44' height="px" variant=" rounded" />
                                    </Box>
                                </Box>
                                <Box className="d-flex gap-3 w-100">
                                    <Skeleton animation="wave" className='w-100 height-44' height="px" variant=" rounded" />
                                </Box>
                                <Box className="d-flex gap-3 w-100 border border-2 p-2 rounded-2">
                                    <Box className="d-flex flex-column gap-3" style={{ flex: "2" }}>
                                        <Skeleton animation="wave" className='w-50' variant="rounded" />
                                        <Skeleton animation="wave" className='w-50' variant="rounded" />
                                        <Skeleton animation="wave" className='w-75' variant="rounded" />
                                        <Skeleton animation="wave" className='w-75' variant="rounded" />
                                        <Skeleton animation="wave" className='w-100' variant="rounded" />
                                    </Box>
                                    <Box className="d-flex gap-3 flex-column" style={{ flex: "4" }}>
                                        <Box className="d-flex justify-content-between  w-100">
                                            <Box className=" d-flex flex-column gap-3" style={{ flex: "4" }}>
                                                <Skeleton animation="wave" className='w-25' variant="rounded" />
                                                <Skeleton animation="wave" className='w-75' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50' variant="rounded" />
                                                <Skeleton animation="wave" className='w-25' variant="rounded" />
                                            </Box>
                                            <Box className="" style={{ flex: "1" }} >
                                                <Skeleton animation="wave" className='w-100' height="40px" variant="rounded" />
                                            </Box>
                                        </Box>
                                        <Box className="d-flex justify-content-end">
                                            <Box className="d-flex gap-3 w-75">
                                                <Skeleton animation="wave" className='w-75 height-44' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50 height-44' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50 height-44' variant="rounded" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="d-flex gap-3 w-100 border border-2 p-2 rounded-2">
                                    <Box className="d-flex flex-column gap-3" style={{ flex: "2" }}>
                                        <Skeleton animation="wave" className='w-50' variant="rounded" />
                                        <Skeleton animation="wave" className='w-50' variant="rounded" />
                                        <Skeleton animation="wave" className='w-75' variant="rounded" />
                                        <Skeleton animation="wave" className='w-75' variant="rounded" />
                                        <Skeleton animation="wave" className='w-100' variant="rounded" />
                                    </Box>
                                    <Box className="d-flex gap-3 flex-column" style={{ flex: "4" }}>
                                        <Box className="d-flex justify-content-between  w-100">
                                            <Box className=" d-flex flex-column gap-3" style={{ flex: "4" }}>
                                                <Skeleton animation="wave" className='w-25' variant="rounded" />
                                                <Skeleton animation="wave" className='w-75' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50' variant="rounded" />
                                                <Skeleton animation="wave" className='w-25' variant="rounded" />
                                            </Box>
                                            <Box className="" style={{ flex: "1" }} >
                                                <Skeleton animation="wave" className='w-100' height="40px" variant="rounded" />
                                            </Box>
                                        </Box>
                                        <Box className="d-flex justify-content-end">
                                            <Box className="d-flex gap-3 w-75">
                                                <Skeleton animation="wave" className='w-75  height-44' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50  height-44' variant="rounded" />
                                                <Skeleton animation="wave" className='w-50  height-44' variant="rounded" />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    </div>
                    <div style={{ flex: "2" }}>
                        {/* <Box className="w-100 p-2 border border-2 rounded-2"> */}
                        {/* <Skeleton animation="wave" className='w-100' variant="rounded" /> */}
                        <Card className="service-card-holder card-border w-100">
                            <div className="d-flex gap-1 py-2 flex-column ">
                                <div className="mx-2 py-2 mb-1 d-flex justify-content-center ">
                                    <Skeleton animation="wave" variant="rounded" height={100} width={150} className="shipping-logo-img-div" />
                                    {/* <Skeleton animation="wave" className='w-25' variant="rounded" /> */}
                                </div>
                                <div className="d-flex mx-5 px-5">
                                    <Skeleton animation="wave" variant="text" className="w-100" />
                                </div>
                                <div className="d-flex ">
                                    <Skeleton animation="wave" height={5} variant="text" className="w-100 " />
                                </div>
                                <div className="d-flex mx-5 px-5">
                                    <Skeleton animation="wave" variant="text" className="w-100" />
                                </div>
                                <div className="mx-3">
                                    <Row className="d-flex justify-content-between gap-3">
                                        <Col className="mb-1 me-1">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                        <Col className="ms-5">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mx-3">
                                    <Row className="d-flex justify-content-between gap-3">
                                        <Col className="mb-1 me-1">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                        <Col className="ms-5">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mx-3">
                                    <Row className="d-flex justify-content-between gap-3">
                                        <Col className="mb-1 me-1">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                        <Col className="ms-5">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="d-flex  py-1">
                                    <Skeleton animation="wave" height={5} variant="text" className="w-100 " />
                                </div>
                                <div className="mx-3">
                                    <Row className="d-flex justify-content-between gap-3">
                                        <Col className="mb-1 me-1">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                        <Col className="ms-5">
                                            <Skeleton animation="wave" variant="text" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                        {/* </Box> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BulkOrderPreviewSkeleton