/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Col, Form, Spinner } from "react-bootstrap";
import EastIcon from "@mui/icons-material/East";
import "../../assets/styles/userProfile/userOrderDetails.css";
import UserMyOrderDetailsChildDetails from "./UserMyOrderDetailsChildDetails";
import { useDispatch, useSelector } from "react-redux";
import { resetErrors } from "../../redux/address/addressSlice";
import { Box, Pagination, Skeleton, Tooltip } from "@mui/material";
import { parse } from "date-fns";
import { orderDetails } from "../../redux/user_profile/ProfileThunk";
import { useNavigate } from "react-router-dom";
import { labelDownload } from "../../redux/payment/PaymentThunk";
import CustomAlert from "../common/CustomAlert";
import DynamicDatePicker from "../../pages/DynamicDatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import { icon } from "leaflet";
import SwalFireComponent from "../common/SwalFireComponent";
import Swal from "sweetalert2";

const UserMyOrderDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderList = useSelector(
    (state) => state.profileData?.orderDetailsData?.Orders?.data
  );
  const orderListTotalCount = useSelector(
    (state) => state.profileData?.orderDetailsData?.Orders?.total
  );

  const isOrderLoading = useSelector(
    (state) => state.profileData?.isOrderLoading
  );
  const isLabelLoading = useSelector(
    (state) => state?.createPayment?.islabelDownloadLoading
  );

  const user_id = JSON.parse(localStorage.getItem("user"));
  const [selectedCard, setSelectedCard] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [searchButton, setSearchButton] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [incoShow, setIconShow] = useState({
    startDateIcon: null,
    endDateicon: null,
  });
  const [shortDateError, setShortDateError] = useState(false);
  const [page, setPage] = useState(1);
  const perPage = 5;
  const [checkedItems, setCheckedItems] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  const handleViewMore = (orderList) => {
    setSelectedCard(true);
    // setSelectedCard(selectedCard === orderList?.id ? null : orderList);
    navigate(`/user/myorders?${orderList?.id}`);
  };

  const getOrderStatusColor = (orderStatus) => {
    switch (orderStatus) {
      case "SUCCESSFUL PAYMENT":
        return "#C4E9C4";
      case "IN TRANSIT":
        return "#E6CDBB";
      case "FAILED":
        return "#FFC0C0";
      default:
        return "#C4E9C4";
    }
  };

  useEffect(() => {
    if (user_id) {
      dispatch(
        orderDetails({
          start_date: "",
          end_date: "",
          search: "",
        })
      );
    }
    dispatch(resetErrors());
    scrollToTop();
    const url = window.location.href;
    const parts = url.split("/");
    const dynamicPart = parts[parts.length - 1];
    const queryParams = dynamicPart.split("?");
    const orderId = queryParams[1];
    if (orderId) {
      setSelectedCard(true);
    }
  }, [dispatch]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    if (searchVal.trim() === "") {
      setSearchButton("");
    }
  }, [searchVal]);
  useEffect(() => {
    const formattedStartDate = startDate ? formatDate(startDate) : "";
    const formattedEndDate = endDate ? formatDate(endDate) : "";

    dispatch(
      orderDetails({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        search: searchButton,
        per_page: 5,
        page: 1,
      })
    );
    setPage(1);
  }, [startDate, endDate, searchButton, dispatch]);
  useEffect(() => {
    if (page) {
      const formattedStartDate = startDate ? formatDate(startDate) : "";
      const formattedEndDate = endDate ? formatDate(endDate) : "";
      dispatch(
        orderDetails({
          start_date: formattedStartDate,
          end_date: formattedEndDate,
          search: searchButton,
          per_page: 5,
          page: page,
        })
      );
    }
  }, [page]);
  useEffect(() => {
    if (orderList?.length) {
      const trans = orderList?.map((data, index) => {
        return { ...data, transactionNumber: index + 1 };
      });
      setFilteredData(trans);
    }
  }, [orderList]);
  const handleClear = () => {
    setSearchVal("");
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleStartDateChange = (newValue) => {
    setIconShow({
      ...incoShow,
      startDateIcon: newValue,
    });
    if (
      dayjs.isDayjs(newValue) &&
      newValue.isValid() &&
      newValue.year().toString().length === 4
    ) {
      setStartDate(newValue);
      setEndDate(null);
    }
  };
  const handleEndDateChange = (newValue) => {
    setIconShow({
      ...incoShow,
      endDateicon: newValue,
    });
    setShortDateError(false);
    if (
      dayjs.isDayjs(newValue) &&
      newValue.isValid() &&
      newValue.year().toString().length === 4
    ) {
      if (startDate && newValue.isBefore(startDate, "day")) {
        // alert('End date cannot be before start date.');
        setShortDateError(true);
      } else {
        setEndDate(newValue);
      }
    }
  };
  const handleClearstart = () => {
    setStartDate(null);
  };
  const handleClearEnd = () => {
    setEndDate(null);
  };
  const disableEndDateBeforeStartDate = (date) => {
    return startDate && date.isBefore(startDate, "day");
  };
  const handleDivClick = (id) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id],
    }));
  };
  const anyChecked = Object.values(checkedItems).some((value) => value);
  const handleBulkClick = () => {
    const selectedIds = Object.keys(checkedItems).filter(
      (id) => checkedItems[id]
    );
    let data2 = {
      type: "Order Details",
      order_detail_ids: selectedIds,
    };
    dispatch(labelDownload(data2))
      .unwrap()
      .then((response) => {
        setCheckedItems({});
        if (
          response.ShipmentLabel?.code === 200 &&
          response.ShipmentLabel?.status === "success"
        ) {
          const pdfLink = response?.ShipmentLabel?.data;
          fetch(pdfLink)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.style.display = "none";
              a.href = url;
              a.download = "shipment_label.pdf"; // Default file name
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
            })
            .catch(() => {
              CustomAlert("Something went wrong! Please try again later.");
            });
        }
        if (
          response.ShipmentLabel?.code === 404 &&
          response.ShipmentLabel?.status === "fail"
        ){
          Swal.fire({
            toast: true,
            icon: "error",
            title: response.ShipmentLabel?.errors,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 2000,
            timerProgressBar: true,
            customClass: {
              timerProgressBar: "custom-progress-bar-error",
            },
          });
        }
        if (
          response.ShipmentLabel?.code === 500 &&
          response.ShipmentLabel?.status === "fail"
        ){
          Swal.fire({
            toast: true,
            icon: "error",
            title: response.ShipmentLabel?.errors,
            animation: true,
            position: "top-right",
            showConfirmButton: false,
            showCloseButton: true,
            timer: 2000,
            timerProgressBar: true,
            customClass: {
              timerProgressBar: "custom-progress-bar-error",
            },
          });
        }
      })
      .catch((error) => {
        if (error?.status === 429 || error?.status === 500) {
          CustomAlert("Something went wrong! please try some time latter");
        }
      });
  };
  const handleChangePage = (event, value) => {
    setPage(value);
    scrollToTop();
  };
  const handleinput = (e) => {
    setSearchVal(e.target.value);
  };
  const handleSearch = () => {
    setSearchButton(searchVal);
  };
  return (
    <>
      <div className="bord d-flex flex-column gap-4">
        {!selectedCard && (
          <Card className="border-0 bg-transparent">
            <div className="w-100">
              <div className="d-flex flex-column flex-lg-row gap-3 w-100">
                <div className="search-order ">
                  <div className=" d-flex ps-0 ps-xl-4 ms-0 ms-xl-1">
                    <div className="d-flex gap-2 my_orders w-100 my-order-search-input position-relative height-44">
                      <Form.Group
                        controlId="formGridfirstname"
                        className=" d-flex w-100"
                      >
                        <div class="input-group height-44">
                          <input
                            type="text"
                            class="form-control  user-address-placeholder py-2 is-valid-paper position-relative  user-order-padding"
                            name="addressInput"
                            placeholder="Search by order id / tracking id"
                            onChange={handleinput}
                            onKeyPress={(e) => {
                              if (!/^[a-zA-Z0-9]+$/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            value={searchVal}
                            aria-label="Text input with button"
                          />
                          {searchVal.length > 0 && (
                            <ClearIcon
                              className="position-absolute"
                              onClick={handleClear}
                              style={{
                                right: "85px",
                                top: "11px",
                                zIndex: "999",
                                cursor: "pointer",
                              }}
                            />
                            // <img alt="icon" className="position-absolute z-3"
                            //   style={{ right: "90px", top: "15px", cursor: "pointer", }}
                            //   src={`${window.location.origin}/assets/address-icons/close.svg`}
                            //   onClick={handleClear}
                            // />
                          )}
                          <Button
                            style={{
                              right: "6px",
                              top: "5.5px",
                              zIndex: "999",
                              borderRadius: "4px",
                              height: "34px",
                            }}
                            onClick={handleSearch}
                            className="blue-text primarybtn btn savebtn-user position-absolute lh-1 d-flex align-items-center"
                          >
                            Search
                          </Button>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-sm-row gap-3 my-order-date-picker order-datepicker">
                  <div className="startdatepicker">
                    <div
                      className=" w-100 my-order-date-picker position-relative"
                      style={{ width: "100%" }}
                    >
                      <DynamicDatePicker
                        dateFormat="DD-MM-YYYY"
                        value={startDate}
                        onChange={handleStartDateChange}
                        onClear={handleClearstart}
                        incoShow={incoShow}
                      />
                    </div>
                  </div>
                  <div className="enddatepicker">
                    <div className=" w-100 my-order-date-picker position-relative">
                      <DynamicDatePicker
                        value={endDate}
                        dateFormat="DD-MM-YYYY"
                        onChange={handleEndDateChange}
                        onClear={handleClearEnd}
                        shortDateError={shortDateError}
                        shouldDisableDate={disableEndDateBeforeStartDate}
                        incoShow={incoShow}
                      />
                    </div>
                  </div>
                  <Tooltip
                    title={`Select an order to enable download`}
                    className="pointer-icon"
                  >
                    <div className="bulklabel height-44">
                      <Button
                        disabled={!anyChecked}
                        className="h-100 primarybtn btn text-nowrap w-100"
                        onClick={() => !isLabelLoading && handleBulkClick()}
                      >
                        {isLabelLoading ? (
                          <>
                            <Spinner animation="border" size="sm" />
                          </>
                        ) : (
                          <>Download all Label</>
                        )}
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Card>
        )}
        {!isOrderLoading ? (
          <div>
            {!selectedCard ? (
              <>
                <div className="d-flex flex-column gap-4">
                  <div className="d-flex flex-column gap-3">
                    {orderList ? (
                      <>
                        {filteredData.length ? (
                          <div className="user-orderdetails d-flex  flex-column gap-4 ">
                            {filteredData.map((order, index) => (
                              <>
                                <div className="d-flex w-100">
                                  <div
                                    className="d-flex align-items-center ps-4 pe-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDivClick(order?.id)}
                                  >
                                    <Form.Check
                                      className="p-0"
                                      type="checkbox"
                                      id={`custom-checkbox-${order?.id}`}
                                      label=""
                                      checked={!!checkedItems[order?.id]}
                                    />
                                  </div>
                                  <div
                                    key={order?.id}
                                    className="d-flex flex-column gap-1 gap-lg-0 w-100"
                                  >
                                    {/* {orderList?.order_details?.map((order, index) => ( */}
                                    <Card className="border-0 rounded-3 order-details-card w-100">
                                      <Card.Body>
                                        <div className=" d-flex gap-2 gap-lg-4 gap-xl-5 gap-xxl-5   flex-column flex-sm-row flex-lg-row h-100">
                                          <div
                                            className=" d-flex "
                                            style={{ flex: "1" }}
                                          >
                                            <div className=" d-none d-sm-none d-lg-flex w-100 h-100">
                                              <div className="user-service-logo px-4 rounded-3  w-100">
                                                <div className="py-2 w-100 h-100 d-flex align-items-center">
                                                  <img
                                                    className="mx-auto d-lg-block img-fluid"
                                                    src={order?.cardlogo}
                                                    alt="logo"
                                                    style={{
                                                      maxWidth: "100%",
                                                      maxHeight: "100%",
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div className=" d-flex gap-3 d-sm-flex  flex-sm-column flex-sm-column-reverse d-lg-none">
                                              <div className="">
                                                <div className="user-service-logo px-2 rounded-3 py-1 h-auto align-items-center d-flex justify-content-center">
                                                  <div className="py-2">
                                                    <img
                                                      className="mx-auto d-block img-fluid"
                                                      src={order?.cardlogo}
                                                      alt="logo"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="d-flex d-sm-flex d-md-none align-items-center">
                                                <div
                                                  style={{ color: "#113468" }}
                                                  className="w-auto order-status-user"
                                                >
                                                  <p
                                                    className="px-2 rounded-1"
                                                    style={{
                                                      width: "max-content",
                                                      fontWeight: "400",
                                                      fontSize: "12px",
                                                      backgroundColor:
                                                        getOrderStatusColor(
                                                          order?.order
                                                            ?.order_status
                                                        ),
                                                    }}
                                                  >
                                                    {order?.has_drop_off_service ===
                                                    "Yes"
                                                      ? "Drop-off"
                                                      : "Pickup"}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className=" d-flex justify-content-between flex-column flex-lg-row flex-sm-row  gap-2 "
                                            style={{ flex: "5" }}
                                          >
                                            <div className="d-flex flex-column gap-3  gap-lg-2">
                                              <div className=" d-flex gap-0 gap-md-4 gap-lg-4 flex-column flex-sm-row flex-md-row flex-lg-row">
                                                <div className="d-flex flex-column flex-sm-row  gap-sm-3">
                                                  <div>
                                                    <p
                                                      className="paragraph1"
                                                      style={{
                                                        fontWeight: "500",
                                                      }}
                                                    >
                                                      {
                                                        order?.courier_service_name
                                                      }
                                                      <sup>®</sup>
                                                    </p>
                                                  </div>
                                                  <span className="d-none d-sm-block d-md-block  d-lg-block">
                                                    |
                                                  </span>
                                                  <div>
                                                    <p className="paragraph2 text-nowrap">
                                                      {" "}
                                                      From{" "}
                                                      {
                                                        order
                                                          ?.collection_country
                                                          ?.iso_code
                                                      }{" "}
                                                      To {"  "}{" "}
                                                      {
                                                        order?.delivery_country
                                                          ?.iso_code
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className=" ms-3 ms-sm-0 ">
                                                <ul className="m-0 p-0 ps-sm-4 ">
                                                  <li>
                                                    <p className="paragraph2">
                                                      Order ID: #{order?.id}
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p className="paragraph2">
                                                      Order Date and Time:{" "}
                                                      {order?.order_created_at}
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p className="paragraph2">
                                                      Tracking ID: #
                                                      {
                                                        order?.order
                                                          ?.lsn_reference
                                                      }
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div className=" d-flex d-sm-flex d-md-none gap-lg-4 gap-2 flex-column flex-sm-row flex-lg-row d-lg-flex">
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none d-flex align-items-center p-0 gap-2"
                                                    href={
                                                      order?.shipping_file &&
                                                      order?.shipping_file
                                                    }
                                                    target="_blank"
                                                    variant="link"
                                                    disabled={
                                                      !order?.shipping_file
                                                    }
                                                  >
                                                    <img
                                                      alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dlabel.svg`}
                                                    />
                                                    <a
                                                      className="paragraph2 text-decoration-none m-0"
                                                      target="_blank"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }}
                                                    >
                                                      Download Label
                                                    </a>
                                                  </Button>
                                                </div>
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none  d-flex align-items-center p-0 gap-2"
                                                    variant="link"
                                                    disabled={
                                                      !order?.invoice_file
                                                    }
                                                    href={
                                                      order?.invoice_file &&
                                                      order?.invoice_file
                                                    }
                                                    target="_blank"
                                                  >
                                                    <img
                                                      alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dinvoice.svg`}
                                                    />
                                                    <a
                                                      target="_blank"
                                                      className="paragraph2 text-decoration-none m-0"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }}
                                                    >
                                                      Download Invoice
                                                    </a>
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-none d-sm-block d-md-flex  d-lg-flex  flex-column gap-4">
                                              <div className="d-flex flex-column  justify-content-center gap-2 gap-sm-5">
                                                <div className="d-none d-sm-none d-md-block d-lg-block">
                                                  <div
                                                    style={{ color: "#113468" }}
                                                    className="w-auto order-status-user"
                                                  >
                                                    <p
                                                      className="px-2 rounded-1"
                                                      style={{
                                                        width: "max-content",
                                                        fontWeight: "400",
                                                        fontSize: "12px",
                                                        backgroundColor:
                                                          getOrderStatusColor(
                                                            order?.orderdetails
                                                              ?.order_status
                                                          ),
                                                      }}
                                                    >
                                                      {/* {order?.order?.order_status} */}
                                                      {order?.has_drop_off_service ===
                                                      "Yes"
                                                        ? "Drop-off"
                                                        : "Pickup"}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="d-none d-sm-none  d-lg-flex ">
                                                  <div className="d-flex justify-content-center">
                                                    {/* {selectedCard !== order?.id && ( */}
                                                    <Button
                                                      className="text-decoration-none d-flex gap-2"
                                                      variant="link"
                                                      onClick={() =>
                                                        handleViewMore(order)
                                                      }
                                                    >
                                                      <p
                                                        className="paragraph1 text-decoration-none m-0 text-nowrap"
                                                        style={{
                                                          color: "#113468",
                                                          fontWeight: "400",
                                                        }}
                                                      >
                                                        View Details
                                                      </p>
                                                      <div
                                                        style={{
                                                          color: "#113468",
                                                        }}
                                                      >
                                                        <EastIcon />
                                                      </div>
                                                    </Button>
                                                    {/* )} */}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className=" d-flex d-sm-none d-md-flex d-lg-none gap-lg-4 gap-3 flex-column flex-sm-column">
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none d-flex align-items-center p-0 gap-2"
                                                    variant="link"
                                                    disabled={
                                                      !order?.shipping_file
                                                    }
                                                  >
                                                    <img
                                                      alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dlabel.svg`}
                                                    />
                                                    <a
                                                      className="paragraph2 text-decoration-none m-0"
                                                      href={
                                                        order?.shipping_file &&
                                                        order?.shipping_file
                                                      }
                                                      target="_blank"
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }}
                                                      rel="noreferrer"
                                                    >
                                                      Download Label
                                                    </a>
                                                  </Button>
                                                </div>
                                                <div className="text-nowrap ">
                                                  <Button
                                                    className="text-decoration-none  d-flex align-items-center p-0 gap-2"
                                                    variant="link"
                                                    // onClick={() => handleViewMore(order?.id)}
                                                  >
                                                    <img
                                                      alt="icon"
                                                      src={`${window.location.origin}/assets/user-profile/dinvoice.svg`}
                                                    />
                                                    <a
                                                      target="_blank"
                                                      className="paragraph2 text-decoration-none m-0"
                                                      disabled={
                                                        !order?.invoice_file
                                                      }
                                                      href={
                                                        order?.invoice_file &&
                                                        order?.invoice_file
                                                      }
                                                      style={{
                                                        color: "#6B6B6B",
                                                        fontWeight: "300",
                                                      }}
                                                      rel="noreferrer"
                                                    >
                                                      Download Invoice
                                                    </a>
                                                  </Button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                    <Card
                                      className="d-block d-sm-block d-lg-none border-0 rounded-3 card-view-details w-100"
                                      onClick={() => handleViewMore(order)}
                                    >
                                      <div className="d-flex flex-column justify-content-center gap-2">
                                        <div className="d-none d-lg-block">
                                          <div
                                            style={{ color: "#113468" }}
                                            className="w-auto order-status-user"
                                          >
                                            <p
                                              className="px-2 rounded-1"
                                              style={{
                                                width: "max-content",
                                                fontWeight: "400",
                                                fontSize: "12px",
                                                backgroundColor:
                                                  getOrderStatusColor(
                                                    order?.order?.order_status
                                                  ),
                                              }}
                                            >
                                              {/* {
                                                order?.order
                                                  ?.order_status
                                              } */}
                                              {order?.has_drop_off_service ===
                                              "Yes"
                                                ? "Drop-off"
                                                : "Pickup"}
                                            </p>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="d-flex justify-content-center">
                                            {selectedCard !== order?.id && (
                                              <Button
                                                className="text-decoration-none d-flex gap-2"
                                                variant="link"
                                                onClick={() =>
                                                  handleViewMore(order)
                                                }
                                              >
                                                <p
                                                  className="paragraph1 text-decoration-none m-0"
                                                  style={{
                                                    color: "#113468",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  View Details
                                                </p>
                                                <div
                                                  style={{ color: "#113468" }}
                                                >
                                                  <EastIcon />
                                                </div>
                                              </Button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </Card>
                                    {/* ))} */}
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        ) : (
                          <>
                            <div className="user-orderdetails ">
                              <Card className="border-0 py-5">
                                <Card.Body>
                                  <p>
                                    No orders found. Adjust your search or date
                                    range for results..
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                        )}

                        <div className=" d-flex justify-content-end">
                          <Pagination
                            count={Math.ceil(orderListTotalCount / perPage)}
                            variant="outlined"
                            shape="rounded"
                            page={page}
                            onChange={handleChangePage}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="user-orderdetails ">
                          <Card className="border-0 py-5">
                            <Card.Body>
                              <p>No Order Found.......</p>
                            </Card.Body>
                          </Card>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                <UserMyOrderDetailsChildDetails
                  getOrderStatusColor={getOrderStatusColor}
                  item={selectedCard}
                  setSelectedCard={setSelectedCard}
                />
              </>
            )}
          </div>
        ) : (
          <>
            <div className="d-flex flex-column gap-1 gap-xxl-3">
              {/* <div className="d-flex flex-column flex-md-row flex-lg-row flex-xxl-row justify-content-between gap-3 pb-3 pb-xl-3 pb-xxl-0">
                <div className="" style={{ flex: "3" }}>
                  <Skeleton
                    variant="square"
                    height={35}
                    className="rounded w-100"
                  />
                </div>
                <div
                  className=" d-none d-xxl-flex "
                  style={{ flex: "2" }}
                ></div>
                <div className="d-flex " style={{ flex: "4" }}>
                  <div className="d-flex w-100 gap-3">
                    <div className="" style={{ flex: "1" }}>
                      <Skeleton
                        variant="square"
                        height={35}
                        className="rounded w-100"
                      />
                    </div>
                    <div className="" style={{ flex: "1" }}>
                      <Skeleton
                        variant="square"
                        height={35}
                        className="rounded w-100"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <Card className="border-0 order-details-card">
                <Card.Body>
                  <div className="d-flex flex-column flex-sm-row flex-xxl-row gap-3">
                    <div
                      className=" d-flex flex-sm-column-reverse flex-md-column justify-content-start align-items-sm-start gap-3 gap-sm-0"
                      style={{ flex: "2" }}
                    >
                      <div className="w-100">
                        <Skeleton
                          variant="square"
                          height={150}
                          className="rounded w-100 load-img-skeleton"
                        />
                      </div>
                      <div className="w-100 d-flex d-sm-flex d-md-none d-xxl-none">
                        <Skeleton height={50} className="w-100" />
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column gap-2 "
                      style={{ flex: "4" }}
                    >
                      <div className=" d-flex">
                        <div
                          className=" d-flex gap-0 gap-sm-3 gap-lg-0 gap-xl-2 flex-column"
                          style={{ flex: "3" }}
                        >
                          <div className="d-flex">
                            <Skeleton height={40} className="w-100" />
                          </div>
                          <div className="d-flex flex-column gap-2 gap-sm-3 gap-lg-0 gap-xl-2">
                            <div>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                              <Box className="d-flex gap-1 align-items-center">
                                <Skeleton
                                  variant="circular"
                                  width={15}
                                  height={15}
                                />
                                <Skeleton height={20} className="w-100" />
                              </Box>
                            </div>
                            <div className="d-flex d-sm-flex d-md-none d-lg-flex flex-column flex-sm-row flex-xxl-row gap-0 gap-sm-3 gap-xxl-3">
                              <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                                <Skeleton
                                  variant="rectangular"
                                  width={25}
                                  height={25}
                                  className="rounded"
                                />
                                <Skeleton height={40} className="w-100" />
                              </Box>
                              <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                                <Skeleton
                                  variant="rectangular"
                                  width={25}
                                  height={25}
                                  className="rounded"
                                />
                                <Skeleton height={40} className="w-100" />
                              </Box>
                            </div>
                          </div>
                        </div>
                        <div
                          className=" d-none d-lg-none d-xxl-flex"
                          style={{ flex: "1" }}
                        ></div>
                      </div>
                    </div>
                    <div
                      className="gap-md-0 align-items-center justify-content-end justify-content-md-start justify-content-lg-center d-none d-sm-none d-md-flex d-xxl-flex flex-md-column"
                      style={{ flex: "2" }}
                    >
                      <Box className="d-flex flex-row flex-md-column flex-xxl-column gap-3">
                        <Skeleton width={150} height={50} className="" />
                        <Skeleton
                          width={150}
                          height={50}
                          className="d-md-none d-lg-flex"
                        />
                      </Box>
                      <div className="d-none d-sm-none d-md-flex d-lg-none flex-column w-100 gap-md-1">
                        <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                          <Skeleton
                            variant="rectangular"
                            width={25}
                            height={25}
                            className="rounded"
                          />
                          <Skeleton height={40} className="w-100" />
                        </Box>
                        <Box className="d-flex align-items-center gap-1 w-100 loading-download-invoice">
                          <Skeleton
                            variant="rectangular"
                            width={25}
                            height={25}
                            className="rounded"
                          />
                          <Skeleton height={40} className="w-100" />
                        </Box>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card className="d-block d-sm-block d-lg-none border-0 rounded-3 card-view-details">
                <div className="d-flex flex-column justify-content-center gap-2">
                  <div className="d-flex justify-content-center">
                    <Skeleton height={50} className="w-75" />
                  </div>
                </div>
              </Card>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UserMyOrderDetails;
