/* eslint-disable no-unused-vars */
import React, { useEffect, Suspense, lazy } from "react";
import "../../assets/styles/herosection.css";
import ShipingSlider from "../../components/ShipingSlider";
import Services from "../../components/Services";
import ShippingSmarter from "../../components/ShippingSmarter/ShippingSmarter";
import HeroSection from "../../components/hero_section/HeroSection";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHerosectionDetails,
  fetchSlidingDetails,
  fetchlandingServices,
  fetchFaqsDetails,
  fetchCountryDetails,
  fetchServiceTitle,
  loginadminuser,
} from "../../redux/landing/landingThunk";
import { useModel } from "../../utils/modalContext";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isSliderLogin, isHeroLoading } = useSelector((state) => ({
    isSliderLogin: state?.landingReducer?.isSliderLogin,
    isHeroLoading: state?.landingReducer?.isHeroLoading,
  }));
  const Landing_Hero = useSelector(
    (state) => state.landingReducer?.landingHeroDetails?.data
  );
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );
  const Landing_Slider = useSelector(
    (state) => state.landingReducer?.landingSliderDetails?.data
  );
  const Landing_Services = useSelector(
    (state) => state.landingReducer?.landingServicesDetails?.data
  );
  const serviceTitle = useSelector(
    (state) => state.landingReducer?.homepageserviceTitle?.data
  );
  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      dispatch(loginadminuser(params?.id)).unwrap()
        .then((response) => {
          if (response?.Login?.status === "success" && response?.Login?.code === "200") {
            navigate("/user/myorders")
            localStorage.setItem("user", JSON.stringify(response?.Login?.data));
            Swal.fire({
              toast: true,
              icon: "success",
              title: "logged in successfully",
              animation: true,
              position: "top-right",
              showConfirmButton: false,
              showCloseButton: true,
              timer: 2000,
              timerProgressBar: true,
              customClass: {
                timerProgressBar: "custom-progress-bar",
              },
            });
            // setLoginForm({
            //   email: "",
            //   password: "",
            // });
            // setLoginErrorForm({
            //   email: "",
            //   password: "",
            // });
            // setIsChecked(false);
            // setIsFormSubmitted(false);
            // setPasswordVisible(false);
            // setForgotPasswordVisible({
            //   forgotOtp: "",
            //   password: true,
            //   password_confirmation: true,
            // });
            // setShowAuthenticationModal(false);
          }
          if (response?.Login.status === "fail" && response?.Login?.code === "404" && response?.Login?.errors?.otp_verification === false && !response?.Login?.errors?.password) {
            // setloginotppopup()
          }

        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    }
  }, [params?.id])
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (!Landing_Hero) {
      dispatch(fetchHerosectionDetails());
    }
    if (!country_Name) {
      dispatch(fetchCountryDetails());
    }
    if (!Landing_Slider) {
      dispatch(fetchSlidingDetails());
    }
    if (!Landing_Services) {
      dispatch(fetchlandingServices());
    }
    if (!serviceTitle) {
      dispatch(fetchServiceTitle());
    }
  }, [dispatch]);

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    localStorage.setItem("ipaddress", res?.data?.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  return (
    <div className="pb-5">
      {isHeroLoading ? (
        <div
          className=" gap-5"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          {/* <ThreeDots
              name="loader"
              style={{ paddingLeft: "40px" }}
              height="80vh"
              width="150"
              radius="9"
              color="#113468"
              ariaLabel="three-dots-loading"
              wrapperStyle={{ margin: "0 auto" }}
              visible={true}
            /> */}
          <Spinner
            animation="grow"
            style={{ background: "#113468" }}
            color="#113468"
          />
          <Spinner
            animation="grow"
            style={{ background: "#113468" }}
            color="#113468"
          />
          <Spinner
            animation="grow"
            style={{ background: "#113468" }}
            color="#113468"
          />
        </div>
      ) : (
        <div className="d-flex flex-column gap-5">
          <HeroSection />
          <ShipingSlider />
          <Services />
          <ShippingSmarter />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
