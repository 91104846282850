import { createSlice } from "@reduxjs/toolkit";
import {
  LoginRegistration,
  LoginUser,
  EmailVerification,
  forgetemailaddress,
  forgetemailotppassword,
} from "./LoginRegistrationThunk";
const initialState = {
  error: "",
};
export const LoginRegistrationSlice = createSlice({
  name: "Login_Registration",
  initialState: {
    LoginRegistration: [],
    LoginUserData: [],
    SignupOtp: [],
    // error: [],
    signup_error: [],
    forgetemailaddress: [],
    forgetemailotppassword: [],
    isLoadingSignUp: false,
    isLoadingLogin: false,
    isVerification: false,
    isLoadingForgot: false,
    isLoadingEmail: false
  },
  reducers: {
    resetErrors: (state) => {
      return initialState;
    },
    clearError: (state) => {
      state.error = [];
    },

  },
  extraReducers: {
    [LoginRegistration.fulfilled]: (state, { payload }) => {
      return { ...state, LoginRegistration: payload, isLoadingSignUp: false };
    },
    [LoginRegistration.pending]: (state) => {
      return { ...state, LoginRegistration: [], isLoadingSignUp: true };
    },
    [LoginRegistration.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
        isLoadingSignUp: false
      };
    },
    [LoginUser.fulfilled]: (state, { payload }) => {
      return { ...state, LoginUserData: payload, isLoadingLogin: false };
    },
    [LoginUser.pending]: (state) => {
      return { ...state, LoginUserData: [], isLoadingLogin: true };
    },
    [LoginUser.rejected]: (state, { payload, errors }) => {
      return {
        error: payload.errors,
        isLoadingLogin: false
      };
    },

    [EmailVerification.fulfilled]: (state, { payload }) => {
      return { ...state, SignupOtp: payload, isLoadingEmail: false };
    },
    [EmailVerification.pending]: (state) => {
      return { ...state, SignupOtp: [], isLoadingEmail: true };
    },
    [EmailVerification.rejected]: (state, { payload }) => {
      if (payload && payload.errors) {
        // Update error state based on the API response structure
        state.errors = payload.errors;
      } else {
        state.errors = "An error occurred during login."; // Generic error message
      }
      state.isLoadingEmail = false;
    },

    [forgetemailaddress.fulfilled]: (state, { payload }) => {
      return { ...state, forgetemailaddress: payload, isLoadingForgot: false };
    },
    [forgetemailaddress.pending]: (state) => {
      return { ...state, forgetemailaddress: [], isLoadingForgot: true };
    },
    [forgetemailaddress.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload,
        isLoadingForgot: false
      };
    },
    [forgetemailotppassword.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        forgetemailotppassword: payload,
        isLoadingOtp: false,
      };
    },
    [forgetemailotppassword.pending]: (state) => {
      return { ...state, forgetemailotppassword: [], isLoadingOtp: true };
    },
    [forgetemailotppassword.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload?.errors?.password,
        isLoadingOtp: false
      };
    },
  },
});
export const { resetErrors } = LoginRegistrationSlice.actions;
export default LoginRegistrationSlice.reducer;
