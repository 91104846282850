import { createSlice } from "@reduxjs/toolkit";
import {
  bulkOrders,
  bulkOrdersDelete,
  bulkOrdersEdit,
  bulkOrdersList,
  fetchShippingServices,
  fetchpickupdropdata,
  fetchdropdata
} from "./ServicesThunk";
import { bulkAddress } from "../address/adressThunk";
const initialState = {
  error: "",
  insuranceError: "",
  isPickupLoading: false,
};
export const ShippingServiceSlice = createSlice({
  name: "ShippingServiceSlice",
  initialState: {
    isServiceLoading: false,
    isPickupLoading: false,
    isbulkorderLoading: false,
    isbulkorderEditLoading: false,
    isbulkorderDeleteLoading: false,
    isDropofLoading: false,
    isbulkAddressLoading: false,
    ShippingService: [],
    pickupdropdata: [],
    dropoffdata: [],
    bulkAddressData: [],
    bulkorderData: [],
    bulkOrdersDeleteData: [],
    bulkOrdersEditData: [],

  },
  reducers: {
    resetServiceErrors: (state) => {
      return initialState;
    },
    resetonlyerrors: (state) => {
      return initialState.error
    },
  },
  extraReducers: {
    [fetchShippingServices.fulfilled]: (state, { payload }) => {
      return { ...state, ShippingService: payload, isServiceLoading: false };
    },
    [fetchShippingServices.pending]: (state) => {
      return { ...state, ShippingService: [], isServiceLoading: true };
    },
    [fetchShippingServices.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        insuranceError: payload,
        error: payload.errors,
        isServiceLoading: false
      };
    },
    [bulkOrders.fulfilled]: (state, { payload }) => {
      return { ...state, bulkorderData: payload, isbulkorderLoading: false };
    },
    [bulkOrders.pending]: (state) => {
      return { ...state, bulkorderData: [], isbulkorderLoading: true };
    },
    [bulkOrders.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        insuranceError: payload,
        error: payload?.errors,
        isbulkorderLoading: false
      };
    },
    [bulkOrdersList.fulfilled]: (state, { payload }) => {
      return { ...state, bulkorderDataList: payload, isbulkorderListLoading: false };
    },
    [bulkOrdersList.pending]: (state) => {
      return { ...state, bulkorderDataList: [], isbulkorderListLoading: true };
    },
    [bulkOrdersList.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        bulkOrderserrors: payload,
        error: payload?.errors,
        isbulkorderListLoading: false
      };
    },
    [bulkOrdersEdit.fulfilled]: (state, { payload }) => {
      return { ...state, bulkOrdersEditData: payload, isbulkorderEditLoading: false };
    },
    [bulkOrdersEdit.pending]: (state) => {
      return { ...state, bulkOrdersEditData: [], isbulkorderEditLoading: true };
    },
    [bulkOrdersEdit.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        bulkOrderserrors: payload,
        error: payload?.errors,
        isbulkorderEditLoading: false
      };
    },
    [bulkOrdersDelete.fulfilled]: (state, { payload }) => {
      return { ...state, bulkOrdersDeleteData: payload, isbulkorderDeleteLoading: false };
    },
    [bulkOrdersDelete.pending]: (state) => {
      return { ...state, bulkOrdersDeleteData: [], isbulkorderDeleteLoading: true };
    },
    [bulkOrdersDelete.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        bulkOrderserrors: payload,
        error: payload?.errors,
        isbulkorderDeleteLoading: false
      };
    },
    [fetchpickupdropdata.fulfilled]: (state, { payload }) => {
      return { ...state, pickupdropdata: payload, isPickupLoading: false };
    },
    [fetchpickupdropdata.pending]: (state) => {
      return { ...state, pickupdropdata: [], isPickupLoading: true };
    },
    [fetchpickupdropdata.rejected]: (state, { payload, error }) => {
      return {
        error: payload.errors,
        isPickupLoading: false
      };
    },
    [fetchdropdata.fulfilled]: (state, { payload }) => {
      return { ...state, dropoffdata: payload, isDropofLoading: false };
    },
    [fetchdropdata.pending]: (state) => {
      return { ...state, dropoffdata: [], isDropofLoading: true };
    },
    [fetchdropdata.rejected]: (state, { payload, error }) => {
      return {
        error: payload.errors,
        isDropofLoading: false
      };
    },
    [bulkAddress.fulfilled]: (state, { payload }) => {
      return { ...state, bulkAddressData: payload, isbulkAddressLoading: false };
    },
    [bulkAddress.pending]: (state) => {
      return { ...state, bulkAddressData: [], isbulkAddressLoading: true };
    },
    [bulkAddress.rejected]: (state, { payload, error }) => {
      return {
        error: payload.errors,
        isbulkAddressLoading: false
      };
    },
  }
})
export const { resetServiceErrors,resetonlyerrors } = ShippingServiceSlice.actions;
export default ShippingServiceSlice.reducer
