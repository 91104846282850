/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Container, Dropdown, Nav, NavDropdown, Navbar, Row, Col, Card, Image, Form, Button } from "react-bootstrap";
import { FormControl, InputLabel, MenuItem, Paper, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountryDetails } from "../../redux/landing/landingThunk";
import CustomAutocomplete from "../common/CustomCountryPostal";
import { resetErrors } from "../../redux/landing/landingSlice";

const AccordianCustomDetails = ({ dropdowndata, vatdata, setVatdata }) => {
    const dispatch = useDispatch();

    const country_Name = useSelector(
        (state) => state.landingReducer?.countryDeatils?.data
    );
    const [countryName, setCountryName] = useState();

    useEffect(() => {
        dispatch(resetErrors());
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        dispatch(fetchCountryDetails());
    }, [dispatch]);

    useEffect(() => {
        if (country_Name) {
            const topCountries = country_Name?.filter(
                (selectedData) => selectedData?.top_countries === 1
            );
            const remainingCountries = country_Name?.filter(
                (selectedData) => selectedData?.top_countries !== 1
            );
            const filteredCountries = [...topCountries, ...remainingCountries];
            setCountryName(filteredCountries);
        }
    }, [country_Name]);



    const handleSelectChange = (event) => {
        const selectedCourierPartner = event.target.value;
        setVatdata(prevFormData => ({
            ...prevFormData,
            Reason: selectedCourierPartner
        }));
    };

    const handleSelectcountryChange = (event) => {
        const selectedCourierPartner = event.target.value;
        setVatdata(prevFormData => ({
            ...prevFormData,
            countryInput: selectedCourierPartner
        }));
    };

    return (
        <>

            <div className="mb-3 mt-3" style={{ flex: "1" }}>
                <Form.Group controlId="formGridfirstname ">
                    <Form.Label className="formlabel">
                        <p className=" of-black-dark">Manufacturer Country</p>
                    </Form.Label>
                    <Form.Select aria-label="Default select example"
                        placeholder='Select'
                        className={`Pickup-select-input shadow-none`} onChange={handleSelectcountryChange} value={vatdata?.countryInput}
                    >
                        {countryName && countryName?.map((option, index) => {
                            return (
                                <option key={index} value={option?.name} >
                                    {option?.name}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
            <div className="mb-3 mt-3" style={{ flex: "1" }}>
                <Form.Group controlId="formGridfirstname">
                    <Form.Label className="formlabel">
                        <p className=" of-black-dark">Reason For Export</p>
                    </Form.Label>
                    <Form.Select aria-label="Default select example"
                        placeholder='Select'
                        className={`Pickup-select-input shadow-none`} onChange={handleSelectChange} value={vatdata.Courier_Partners}
                    >
                        {dropdowndata?.map((option, index) => {
                            return (
                                <option key={index} value={option}  >
                                    {option}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </div>
        </>
    );
};

export default AccordianCustomDetails;
