import { createSlice } from "@reduxjs/toolkit";
import { UserAutoreloadWallet, UserWallet, UserWallettransaction } from "./walletThunk";

const initialState = {
    error: "",
};

export const WalletDataSlice = createSlice({
    name: "wallet_details",
    initialState: {
        isWalletLoading: false,
        walletData: [],
        wallettransaction: [],
        autoreloadtransaction: [],
        wallettransactionLoading: false,
        autoreloadtransactionLoading: false,
    },
    reducers: {
        resetErrors: (state) => {
            return initialState;
        },
    },
    extraReducers: {
        [UserWallet.pending]: (state) => {
            return { ...state, walletData: [], isWalletLoading: true }
        },
        [UserWallet.fulfilled]: (state, { payload }) => {
            return { ...state, walletData: payload, isWalletLoading: false }
        },
        [UserWallet.rejected]: (state, { payload }) => {
            return {
                ...state, error: payload.errors,
                isWalletLoading: false
            }
        },
        [UserWallettransaction.pending]: (state) => {
            return { ...state, wallettransaction: [], wallettransactionLoading: true }
        },
        [UserWallettransaction.fulfilled]: (state, { payload }) => {
            return { ...state, wallettransaction: payload, wallettransactionLoading: false }
        },
        [UserWallettransaction.rejected]: (state, { payload }) => {
            return {
                ...state, error: payload.errors,
                wallettransactionLoading: false
            }
        },

        [UserAutoreloadWallet.pending]: (state) => {
            return { ...state, autoreloadtransaction: [], autoreloadtransactionLoading: true }
        },
        [UserAutoreloadWallet.fulfilled]: (state, { payload }) => {
            return { ...state, autoreloadtransaction: payload, autoreloadtransactionLoading: false }
        },
        [UserAutoreloadWallet.rejected]: (state, { payload }) => {
            return {
                ...state, error: payload.errors,
                autoreloadtransactionLoading: false
            }
        }
    }
})

export const { resetErrors } = WalletDataSlice.actions;
export default WalletDataSlice.reducer;