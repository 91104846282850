import Swal from 'sweetalert2';

function generateErrorMessage(validationArray) {
  if (validationArray && validationArray.length > 0) {
    return `<div class="text-danger">${validationArray.join(", ")}</div>`;
  } else {
    return "";
  }
}

function showAlert(requiredValidation) {
  const htmlContent = `
  <div class="custom-container-swal snic" justify-content: space-between;">
         <div as={col}>
           <img src="assets/error.png" class="img-fluid" style="object-fit: cover; max-width: 35%; height: auto; margin-right: 10px;" alt="Error Image">
         </div>
         <div style="width:-webkit-fill-available; display: flex; align-items: center; justify-content: center;">
    <div style="text-align: center;">
      ${generateErrorMessage(requiredValidation.fromcountryCode)}
      ${generateErrorMessage(requiredValidation.fromPostalCode)}
      ${generateErrorMessage(requiredValidation.toPostalCode)}
      ${generateErrorMessage(requiredValidation.frompostalCode)}
      ${generateErrorMessage(requiredValidation.height)}
      ${generateErrorMessage(requiredValidation.length)}
      ${generateErrorMessage(requiredValidation.tocountryCode)}
      ${generateErrorMessage(requiredValidation.topostalCode)}
      ${generateErrorMessage(requiredValidation.weight)}
      ${generateErrorMessage(requiredValidation.width)}
    </div>
    </div>
    </div>
  `;

  Swal.fire({
    html: htmlContent,
    // confirmButtonText: 'OK'
       customClass: {
            popup: "custom-swal-hero",
            content: "dynamic-width-alert",
            confirmButton: "custom-confirm-button",
            actions: "custom-actions-class m-0",
          },
          width: 400,
  });
}

export default showAlert;
