import React, { useEffect, useState } from "react";
import { Container, Form, Spinner } from "react-bootstrap";
import "../../assets/styles/trackShipment/trackShipment.css";
import { resetErrors } from "../../redux/landing/landingSlice";
import { useDispatch, useSelector } from "react-redux";
import UserTrackShip from "../../components/profileComponent/UserTrackShip";
import { trackshipment } from "../../redux/trackshipment/TrackshipThunk";
import { reseterrortrack } from "../../redux/trackshipment/TrashipSlice";
import { Button } from "@mui/material";

const TrackShip = () => {
  const dispatch = useDispatch();
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const trackdata = useSelector(
    (state) => state?.Trackshipdetails?.TrashipData?.data
  );
  const trackingerror = useSelector((state) => state?.Trackshipdetails?.error);
  const trackingloading = useSelector(
    (state) => state?.Trackshipdetails?.isTrackshipLoading
  );
  const [trackingNumber, setTrackingNumber] = useState();
  const [fieldError, setFieldError] = useState();
  useEffect(() => {
    // dispatch(resetErrors());
    // dispatch(reseterrortrack());
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [dispatch, fieldError]);

  const trackInput = (e) => {
  
    dispatch(reseterrortrack());
    if (e?.target?.value?.length < 0) {
      setFieldError("This field is required.");
    }else if(e?.target?.value?.length > 20){
      setFieldError("Too long");
    }
     else {
      setTrackingNumber(e?.target?.value);
      setFieldError("");
    }
  };

  const handlesubmit = () => {
    if (!trackingNumber) {
      setFieldError("This field is required.");
    } else {
      dispatch(
        trackshipment({
          tracking_number: trackingNumber,
          uniquesessionid: uniquesessionid,
        })
      ).unwrap()
        .then((response) => {
          if (response?.status === false && response?.statusCode === 400) {
            setFieldError(response?.errors);
          }
        })
        .catch((error) => { });
    }

  };

  return (
    <div className="trackshipmentpage">
      <>
        <div className="trackshipm entpageouter background-holder">
          <div className="trackship-bg-image">
            <Container>
              <div className="background-title container">
                <h4>Track Shipment</h4>
              </div>
            </Container>
          </div>
        </div>
        <div className="track-ship-input-holder d-flex flex-column gap-5 py-5 mx-2 px-3 px-lg-0">
          <Container className="d-flex flex-column gap-5 position-relative p-0 ">
            <div className="order-lg-1 order-2 position-relative d-flex justify-content-center ">
              <div className="d-flex justify-content-center input-tract-btn flex-column flex-lg-row  gap-3 div-1 w-50">
                <div class="main d-flex justify-content-center gap-2">
                  <div className="" style={{ flex: "2" }}>
                    <div class="main">
                      <div class="form-group has-feedback position-relative">
                        <div className="position-absolute d-flex align-items-center px-3" style={{ top: "31px", left: "0px" }}   >
                          <img src={`${window.location.origin}/assets/searchicon.svg`}
                            className="form-control-feedback position-absolute" alt="Search Icon" />
                        </div>
                        <div className="">
                          <Form.Group controlId="formGridfirstname">
                            <Form.Control style={{ height: "60px" }}
                              className={`trackship-input ${trackingerror || fieldError ? "is-invalid-paper" : "is-valid-paper"}`}
                              type="text"
                              name="trackship"
                              placeholder="Enter Tracking Number"
                              value={trackingNumber}
                              onChange={trackInput}
                              isInvalid={trackingerror || fieldError}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-danger "
                            >
                              {trackingerror ||
                                (fieldError && (
                                  <span className="p.paragraph2">
                                    {trackingerror || fieldError}
                                  </span>
                                ))}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" " style={{ flex: "1", height: "60px" }}>
                    <Button className="primarybtn btn track-btn w-100" style={{ height: "60px" }} onClick={() => !trackingloading && handlesubmit()}  >
                      {trackingloading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <>
                          TRACK
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="order-1 order-lg-2">
              <div className="track-ship-text-holder  div-2">
                <p className="paragraph1 track-text">
                  You have booked your delivery through us, you can find Let's
                  Ship Now Order number on the confirmation email we sent at the
                  time of booking. (It will look something like: LSN4839839)
                  Just enter your unique Let's Ship Now Order number into the
                  box below. You can find out exactly where it is!
                </p>
              </div>
            </div>
            {trackdata?.length && (
              <div className="order-3 order-lg-3">

                {trackdata?.map((data) => {
                  return <UserTrackShip isshowfirst={true} trackdataold={data} />
                })}
              </div>
            )}
          </Container>
        </div>
      </>
    </div >
  );
};

export default TrackShip;
