import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { InputBase, IconButton, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import "../../assets/styles/herosection.css";

const CustomAutocomplete = ({ onInputChange, onChange, options = [], placeholder, inputValue, loading, value, name, onBlur, onCountrySelect, countryLength, fromPostalLength, toPostalLength, lengthInput }) => {

  const handleKeyPress = (e) => {
    const invalidChars = /[+\-=)(*&^%$#@!?:><}{'";,./\\|_[\]`~]/;
    if (invalidChars.test(e.key) || e.target.value.length >= lengthInput) {
      e.preventDefault();
    }
  };


  return (
    <Autocomplete
      freeSolo
      disablePortal
      options={options}
      value={value}
      onInputChange={(event, newInputValue) => {
        if (onInputChange) {
          onInputChange(event, newInputValue);
        }
      }}
      onKeyPress={handleKeyPress}
      // onInputChange={onInputChange}
      onChange={onChange}
      inputValue={inputValue}
      loading={loading}
      renderInput={(params) => (
        <div ref={params.InputProps.ref} className="d-flex">
          <IconButton
            disabled
            name={"close_icon"}
            type="icon"
            sx={{ p: "10px" }}
            aria-label="search"
            className="px-1"
          >
            <SearchIcon style={{ color: "#bababa" }} />
          </IconButton>
          <InputBase
            placeholder={placeholder}
            name={name}
            type="text"
            className="heroinputs input-text input-placeholder height-50"
            {...params.inputProps}
            // onBlur={onBlur}
            onCountrySelect={onCountrySelect}
            autoComplete="new-country"
          />
        </div>
      )}
    />
  );
};

export default CustomAutocomplete;
