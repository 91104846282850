import { createSlice } from "@reduxjs/toolkit";
import {
  fetchProfileDetails,
  changePassword,
  contactusinfo,
  deactiveAccount,
  orderDetails,
  customerParcel,
  getParcels,
  getcart,
  getuserdetail,
  getparcellabelsize,
  updateparcellabelsize,
} from "./ProfileThunk";
const initialState = {
  error: "",
};
export const ProfileDataSlice = createSlice({
  name: "Profile_details",
  initialState: {
    isProfileLoading: false,
    isPasswordLoading: false,
    isParcelLoading: false,
    isParcelCrudApi: false,
    isCartDataLoading: false,
    isGetuserdetailLoading: false,
    iscontactusinfoLoading: false,
    isgetparcellabelsizeLoading:false,
    isupdateparcellabelsizeLoading:false,
    updateparcellabelsize:[],
    getparcellabelsize:[],
    profileDetailData: [],
    profilePasswordChange: [],
    deactivateUser: [],
    contactusinfo: [],
    orderDetailsData: [],
    parcelDetails: [],
    parcelData: [],
    cartData: [],
    getuserdetail: []
  },
  reducers: {
    resetErrors: (state) => {
      return initialState;
    },
  },

  extraReducers: {
    [fetchProfileDetails.pending]: (state) => {
      return { ...state, profileDetailData: [], isProfileLoading: true };
    },
    [fetchProfileDetails.fulfilled]: (state, { payload }) => {
      return { ...state, profileDetailData: payload, isProfileLoading: false };
    },
    [fetchProfileDetails.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
        isProfileLoading: false
      };
    },

    [changePassword.pending]: (state) => {
      return { ...state, profilePasswordChange: [], isPasswordLoading: true };
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      return { ...state, profilePasswordChange: payload, isPasswordLoading: false };
    },

    [changePassword.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
        isPasswordLoading: false,
      };
    },
    [contactusinfo.pending]: (state) => {
      return { ...state, contactusinfo: [], iscontactusinfoLoading: true };
    },
    [contactusinfo.fulfilled]: (state, { payload }) => {
      return { ...state, contactusinfo: payload, iscontactusinfoLoading: false, };
    },
    [contactusinfo.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
        iscontactusinfoLoading: false,
      };
    },

    [deactiveAccount.pending]: (state) => {
      return { ...state, deactivateUser: [] };
    },
    [deactiveAccount.fulfilled]: (state, { payload }) => {
      return { ...state, deactivateUser: payload };
    },

    [deactiveAccount.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
      };
    },

    [orderDetails.pending]: (state) => {
      return { ...state, orderDetailsData: [], isOrderLoading: true };
    },
    [orderDetails.fulfilled]: (state, { payload }) => {
      return { ...state, orderDetailsData: payload, isOrderLoading: false };
    },
    [orderDetails.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.errors,
        isOrderLoading: false
      };
    },
    [customerParcel.pending]: (state) => {
      return { ...state, parcelDetails: [], isParcelCrudApi: true };
    },
    [customerParcel.fulfilled]: (state, { payload }) => {
      return { ...state, parcelDetails: payload, isParcelCrudApi: false };
    },
    [customerParcel.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isParcelCrudApi: false
      };
    },
    [getParcels.pending]: (state) => {
      return { ...state, parcelData: [], isParcelLoading: true };
    },
    [getParcels.fulfilled]: (state, { payload }) => {
      return { ...state, parcelData: payload, isParcelLoading: false };
    },
    [getParcels.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isParcelLoading: false
      };
    },
    [getcart.pending]: (state) => {
      return { ...state, cartData: [], isCartDataLoading: true };
    },
    [getcart.fulfilled]: (state, { payload }) => {
      return { ...state, cartData: payload, isCartDataLoading: false };
    },
    [getcart.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isCartDataLoading: false
      };
    },
    [getuserdetail.pending]: (state) => {
      return { ...state, getuserdetail: [], isGetuserdetailLoading: true };
    },
    [getuserdetail.fulfilled]: (state, { payload }) => {
      return { ...state, getuserdetail: payload, isGetuserdetailLoading: false };
    },
    [getuserdetail.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isGetuserdetailLoading: false
      };
    },
    [getparcellabelsize.pending]: (state) => {
      return { ...state, getparcellabelsize: [], isgetparcellabelsizeLoading: true };
    },
    [getparcellabelsize.fulfilled]: (state, { payload }) => {
      return { ...state, getparcellabelsize: payload, isgetparcellabelsizeLoading: false };
    },
    [getparcellabelsize.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isgetparcellabelsizeLoading: false
      };
    },
    [updateparcellabelsize.pending]: (state) => {
      return { ...state, updateparcellabelsize: [], isupdateparcellabelsizeLoading: true };
    },
    [updateparcellabelsize.fulfilled]: (state, { payload }) => {
      return { ...state, updateparcellabelsize: payload, isupdateparcellabelsizeLoading: false };
    },
    [updateparcellabelsize.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        error: payload?.errors,
        isupdateparcellabelsizeLoading: false
      };
    },
  },
});
export const { resetErrors } = ProfileDataSlice.actions;

export default ProfileDataSlice.reducer;
