import React, { useEffect } from "react";
import "../assets/styles/shiping-slider.css";
import { Link } from "react-router-dom";
import { Card, Container, Figure } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchSlidingDetails } from "../redux/landing/landingThunk";

const ShipingSlider = () => {
  const dispatch = useDispatch()
  const Landing_Slider = useSelector(
    (state) => state.landingReducer?.landingSliderDetails?.data
  );
  const getCustomIndex = (cardId) => {
    if (cardId === 3) {
      return 3;
    } else if (cardId === 4) {
      return 3;
    } else if (cardId === 5) {
      return 3;
    } else if (cardId === 6) {
      return 3;
    } else {
      return cardId;
    }
  };
  return (
    <div className="">
      <>
        <Container className="p-0">
          <div className="px-4 px-sm-0 px-lg-0  pt-lg-4">
            <div className="shipping-slider-holder" id="shipingSliderHowItWork">
              <div className="efortless">
                <h4 className="">
                  <span>
                    Most Cost Effective, Ship Anywhere Globally, Trusted
                    Carriers
                  </span>{" "}
                  <img
                    src={`${window.location.origin}/assets/3lines.svg`}
                    alt="three_lines"
                    width={47}
                    height={53}
                    className="d-none d-lg-block"
                  />
                </h4>
              </div>
              <div className="slider-steps">
                <div className="sliderhead w-100">
                  <div className="slider-description">
                    <p>
                      Let’s Ship Now allows comparison of multiple shipping
                      options and prepare a package, including a shipping
                      label, from the comfort of your home. We offer the best
                      rates from the best shipping carriers and provide
                      additional discounts, making it the most economical
                      courier service online. HINT: You have to Sign Up to
                      receive all discounts.
                    </p>
                  </div>
                  <div className="arrow-img-holder d-none d-lg-block">
                    <div className="arrowimg">
                      <img
                        src={`${window.location.origin}/assets/arrow.svg`}
                        alt="down_arrow"
                        width={109}
                        height={155}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Card className="shipingcard shadow-none">
                  <div className="slidermain">
                    <ul className="card-slider-holder">

                      {Landing_Slider?.map((data, index) => (
                        <li className="slidercard" key={data?.id} style={{ "--index": getCustomIndex(data?.id) }}  >
                          <div className="slidercardtext">
                            <h3>
                              {`${data?.no} / 0${Landing_Slider.length}`}
                            </h3>
                            <p className="hero_heading">{data?.heading}</p>
                            <div className="slidercard-para">
                              <p className="slidercard-description">
                                {data?.description}
                              </p>
                            </div>
                          </div>
                          <div className="card__content">
                            <Figure className="shiping-slider-images-scroll" >
                              <img src={data?.image_with_path} alt="Image description" />
                            </Figure>
                          </div>
                        </li>
                      ))}

                    </ul>
                  </div>
                </Card>
              </div>
            </div>
          </div>


        </Container>
      </>
    </div>
  );
};
export default ShipingSlider;
