// src/MapComponent.jsx
import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

// Fix marker icon issue with Leaflet in React
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
});

L.Marker.prototype.options.icon = defaultIcon;

const calculateCentroid = (locations) => {
    if (!locations?.length) return [0, 0];

    const total = locations.reduce(
        (acc, loc) => {
            acc.lat += loc.latitude;
            acc.lng += loc.longitude;
            return acc;
        },
        { lat: 0, lng: 0 }
    );

    return [total.lat / locations?.length, total.lng / locations?.length];
};

const MapComponent = ({ locations }) => {
    const mapRef = useRef();

    useEffect(() => {
        const map = mapRef.current;
        if (map && locations.length > 0) {
            const bounds = L.latLngBounds(locations?.map(loc => [Number(loc.latitude), Number(loc.longitude)]));
            map?.fitBounds(bounds);
        }
    }, [locations]);

    const center = calculateCentroid(locations);

    return (
        <>
            <div className='m-2'>
                <MapContainer className='w-100 rounded-3' style={{ height: "32rem" }} center={center} zoom={13} ref={mapRef}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {locations?.map((location, index) => (
                        <Marker key={index} position={[location.latitude, location.longitude]}>
                            <Popup>{location.company || 'Store Location'}</Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>
        </>
    );
};

export default MapComponent;
