import React from 'react';
import Swal from 'sweetalert2';

const CustomAlert = (message) => {
    Swal.fire({
        html: `
            <div class="custom-container-swal snic" style="justify-content: space-between;">
                <div as={col}>
                    <img src="assets/error.png" class="img-fluid" style="object-fit: cover; max-width: 20%; height: auto; margin-right: 10px;" alt="Error Image">
                </div>
                <div style="width: -webkit-fill-available; display: flex; align-items: center; justify-content: center;">
                    <div style="text-align: center;">
                        <div class="text-danger">${message}</div>
                    </div>
                </div>
            </div>
        `,
        customClass: {
            popup: "custom-swal-hero swal2-custom-popup", 
            content: "dynamic-width-alert",
            confirmButton: "custom-confirm-button",
            actions: "custom-actions-class m-0",
        },
    });
};

export default CustomAlert;
