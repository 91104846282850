import { Container, Modal } from "react-bootstrap";
import "../../assets/styles/addressBook/serviceaboutpopup.css";
import { useRef } from "react";

const ServiveProviderAboutPopup = ({ showServiveAboutPopup, setShowServiveAboutPopup, iframelink }) => {
  const iframeRef = useRef(null);
  const handleCloseModalService = () => {
    setShowServiveAboutPopup(false);
  };
  const handleIframeLoad = () => {
    const iframeWindow = iframeRef.current.contentWindow;
    iframeWindow.postMessage({ type: 'removeHeader' }, '*');
  };

  return (
    <>
      <Container>
        <div className="service-about-holder">
          <Modal show={showServiveAboutPopup} onHide={handleCloseModalService} dialogClassName="modal-lg"  >
            <div className="servicemodelpopup d-flex align-items-center justify-content-center">
              <Modal.Body className="servicemodalbodylogin">
                <div className="serviceloginmodelbody">
                  <Modal.Header closeButton className="closebutton d-flex justify-content-end"></Modal.Header>
                  <div className="servicemodeliframe">
                    <iframe
                      ref={iframeRef}
                      title="serviceProviderIframe"
                      width={window.innerWidth - 300}
                      height={window.innerHeight - 150}
                      src={iframelink}
                      sandbox="allow-scripts allow-same-origin"
                      onLoad={handleIframeLoad}
                    />
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </Container>
    </>
  );
};

export default ServiveProviderAboutPopup;
