import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AddressElement } from "@stripe/react-stripe-js";
import { Col } from "react-bootstrap";
import "../../../assets/styles/orderStripePayment/orderStripePayment.css";

const AddnewCard = ({ setBillingAddress, setCardError }) => {
  // let stripe = loadStripe(
  //   "pk_test_51NvCCrCaHyWzJ3ZGIwICdQ33liP3GOvSli9CKVWO0thQ9jVY2GehkTvhBGqAL6t10DgIS0Wg8IiL0p3B9eCi9KTM00CPkzMD0J"
  // );
  // const stripe = loadStripe(
  //   "pk_live_51JetZWJuLBuhfb7or7cbQXc09FBYttBPhgJtW1I19HbQXGBBuDouLMZDDjDpiW2ZVD9G01OyDh9QIf83qwSgwqGw00I6WYLvbl"
  // );
  let stripeURL = `${process.env.REACT_APP_STRIPE_URL}`;
  let stripe = loadStripe(stripeURL);
  const onchangeForm = (event) => {
    setCardError("");
    if (event.complete) {
      const address = event.value.address;
      const name = event.value.name;
      setBillingAddress({ address, name });
    }
  };

  return (
    <>
      <div>
        <Elements stripe={stripe}>
          <form>
            <Col md={12} className="stripe-add-new-card">
              <AddressElement
                options={{ mode: "billing" }}
                onChange={(event) => {
                  onchangeForm(event);
                }}
              />
            </Col>
          </form>
        </Elements>
      </div>
    </>
  );
};

export default AddnewCard;
