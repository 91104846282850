import "../../assets/styles/header.css";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Collapse from "react-bootstrap/Collapse";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { useModel } from "../../utils/modalContext";
import { resetErrors } from "../../redux/landing/landingSlice";
import { Tooltip } from "@mui/material";
import KeyboardArrowDownTwoToneIcon from "@mui/icons-material/KeyboardArrowDownTwoTone";
import Swal from "sweetalert2";
import Authentication from "../../pages/withoutlogin/Authentication";
import { useDispatch, useSelector } from "react-redux";
import { Logout, PersonAdd, Settings } from "@mui/icons-material";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setActiveSectionfinal } = useModel("myorders");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [user_id, setUser] = useState();
  const [expanded, setExpanded] = useState(false);

  const userloginData = useSelector(
    (state) => state.loginRegistration?.LoginUserData?.Login?.data
  );

  const userloginuserData = useSelector(state => state?.profileData?.profileDetailData?.Profile?.data)

  const {
    modalname,
    setModalname,
    showAuthenticationModal,
    setShowAuthenticationModal,
  } = useModel();

  const toggleModal = (e) => {
    setModalname(e);
    setShowAuthenticationModal(true ? true : false);
    setShowLoginModal(true ? true : false);
  };

  const storedLoginDataString = localStorage.getItem("user");
  const storedLoginData = storedLoginDataString
    ? JSON.parse(storedLoginDataString)
    : null;

  useEffect(() => {
    const useriD = JSON.parse(localStorage.getItem("user"));
    if (useriD) {
      setUser(useriD);
    }
    if (userloginData) {
      setUser(userloginData);
    }
    if (userloginuserData) {
      setUser(userloginuserData);
    }
  }, [userloginData, userloginuserData]);

  const handleProfileClick = (section) => {
    setActiveSectionfinal(section);
    navigate(`/user/${section}`);
  };

  const scrolltop = () => {
    dispatch(resetErrors());
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const navigationFun = () => {
    navigate("/");
    setExpanded(!expanded);
    setTimeout(() => {
      window.scroll({
        top: 635,
        left: 0,
        behavior: "smooth",
      });
    }, 1500);
  };

  const isLoggedIn = storedLoginData !== null && storedLoginData !== undefined;

  const logOut = () => {
    Swal.fire({
      toast: true,
      icon: "success",
      title: "user Logged Out Successfully",
      animation: true,
      position: "top-right",
      showConfirmButton: false,
      showCloseButton: true,
      timer: 2000,
      timerProgressBar: true,
      customClass: {
        timerProgressBar: "custom-progress-bar",
      },
    });
    // localStorage.removeItem("uniquesessionid");
    localStorage.removeItem("user");
    localStorage.removeItem("cartdetail_id");
    localStorage.removeItem("cart_id");
    localStorage.removeItem("token");
    // localStorage.clear();

    navigate("/");
  };



  return (
    <>
      <div className="headercomponent sticky-top">
        <Container className="header-container-holder p-0">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="light"
            className="header-container navbar sticky-top w-100 py-2"
          >
            <div className="me-auto">
              <Navbar.Brand className="ps-4 ps-sm-0 ps-lg-0">
                <Link to="/">
                  <img
                    src={`${window.location.origin}/assets/LSN_logo.svg`}
                    width="120"
                    height={58.88}
                    className="d-inline-block align-top pt-1 pb-2"
                    alt="LSN logo"
                  />
                </Link>
              </Navbar.Brand>
            </div>
            <div>
              <Nav className="signup-holder d-block d-lg-none ">
                {!isLoggedIn ? (
                  <>
                    <Nav.Link href="" className="px-0 ps-2">
                      <Nav.Link
                        style={{ color: "#212529" }}
                        onClick={(e) => toggleModal("login")}
                        className="px-0"
                      >
                        Login
                      </Nav.Link>
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <div className="user-profile-username-holder">
                      <Nav.Link onClick={() => logOut()}>
                        <Link to="/">
                          <LogoutIcon sx={{ color: "var(--primary)" }} />
                        </Link>
                      </Nav.Link>
                    </div>
                  </>
                )}
              </Nav>
            </div>
            <div className={`ms-2 me-sm-0 me-lg-0 ${isLoggedIn ? "me-0" : "me-4"}`}>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setExpanded(!expanded)}
              />
            </div>

            <div className="d-lg-none">
              {isLoggedIn &&
                <Nav.Link className=" py-2 py-lg-0  ps-2 me-4 me-sm-0 me-lg-0">
                  <Link className="ps-0 p-sm-0  ps-md-0 ps-0" to="/cartpreview" >
                    <img src={`${window.location.origin}/assets/cart.svg`} width="29"
                      alt="cart"
                    />
                  </Link>
                </Nav.Link>
              }
            </div>

            <Navbar.Collapse id="responsive-navbar-nav" className="collapse-nav">
              <Nav className="collapse-height m-auto nav-holder align-items-center d-flex gap-0 gap-lg-2 gap-xl-2">
                <Nav.Link className="nav-holder-nav py-2 py-lg-0" href="/"  >
                  <Link className="ms-0 ps-4 ps-sm-0 ps-md-0 ps-lg-2 px-xl-3" to="/">
                    Home
                  </Link>
                </Nav.Link>
                {!isLoggedIn ?
                  <Nav.Link className="nav-holder-nav py-2 py-lg-0" href="/multishipment">
                    <Link className="ps-4 ps-sm-0 ps-md-0 ps-0 ps-lg-2 px-xl-3"
                      to="/multishipment"  >
                      Compare & Ship
                    </Link>
                  </Nav.Link> :
                  <NavDropdown
                    className="nav-holder-nav nav-dropdown nav-drop-first border-0"
                    id="nav-dropdown-basic-example"
                    title={
                      <div className=" d-flex justify-content-between w-100 py-0 py-lg-0 me-2">
                        <Link className="ps-4 ps-sm-0 ps-md-0 ms-0 ms-lg-3 ms-xl-4 ">
                          Ship Now
                        </Link>
                        <KeyboardArrowDownTwoToneIcon className="" />
                      </div>
                    } menuVariant="light"   >
                    <div className="drpdwn-header">
                      <NavDropdown.Item className="mx-lg-0 rounded-0 p-0 drop-item-link">
                        <Nav.Link
                          className="dropdown-item dropdown-item-border p-0 justify-content-start justify-content-lg-center drop-package py-2 drop-package-border"
                          href="packaging-guidelines"
                        >
                          <Link
                            to="/bulk-orders"
                            className=" ps-5 ps-sm-4 ps-lg-0"
                          >
                            Bulk Order
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item className="mx-lg-0 p-0 ">
                        <Nav.Link
                          className="dropdown-item dropdown-item-border justify-content-start justify-content-lg-center py-2 px-0"
                          href="weighing-measuring"
                        >
                          <Link to="/multishipment" className=" ps-5 ps-sm-4 ps-lg-0"     >
                            Compare & Ship
                          </Link>
                        </Nav.Link>
                      </NavDropdown.Item>
                    </div>
                  </NavDropdown>
                  // <Nav.Link className="nav-holder-nav py-2 py-lg-0" href="/multishipment">
                  //   <Link className="ps-4 ps-sm-0 ps-md-0 ps-0 ps-lg-2 px-xl-3" to="/multishipment" >
                  //     Compare & Ship
                  //   </Link>
                  // </Nav.Link>
                }
                <Nav.Link className="nav-holder-nav py-2 py-lg-0" href="/" onClick={() => { navigationFun(); }}   >
                  <Link className="ps-4 ps-sm-0 ps-md-0 ps-0 ps-lg-2 px-xl-3">
                    How it Works
                  </Link>
                </Nav.Link>
                <Nav.Link className="nav-holder-nav py-2 py-lg-0" href="/trackshipment" >
                  <Link className="ps-4 ps-sm-0 ps-md-0 ps-0 ps-lg-2 px-xl-3"
                    to="/trackshipment"  >
                    Track Shipment
                  </Link>
                </Nav.Link>
                <NavDropdown
                  className="nav-holder-nav nav-dropdown nav-drop-first border-0"
                  id="nav-dropdown-basic-example"
                  title={
                    <div className=" d-flex justify-content-between w-100 py-0 py-lg-0 me-2">
                      <Link className="ps-4 ps-sm-0 ps-md-0 ps-0 ps-lg-3 ps-xl-4 ">
                        Resources
                      </Link>
                      <KeyboardArrowDownTwoToneIcon className="" />
                    </div>
                  } menuVariant="light"   >
                  <div className="drpdwn-header">
                    <NavDropdown.Item className="px-lg-0 rounded-0 p-0 drop-item-link">
                      <Nav.Link href="/footer/packaging-guidelines" className="dropdown-item dropdown-item-border p-0 justify-content-start justify-content-lg-center drop-package py-2 drop-package-border">
                        <Link to="/footer/packaging-guidelines" className=" ps-5 ps-sm-4 ps-lg-0"   >
                          Packaging Guidelines
                        </Link>
                      </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="px-lg-0 p-0 ">
                      <Nav.Link href="/footer/weighing-measuring"
                        className="dropdown-item dropdown-item-border justify-content-start justify-content-lg-center py-2"   >
                        <Link
                          to="/footer/weighing-measuring"
                          className=" ps-5 ps-sm-4 ps-lg-0"
                        >
                          Weighing and Measuring
                        </Link>
                      </Nav.Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item className="px-lg-0 border-0 border-bottom-0 p-0">
                      <Nav.Link href="/footer/prohibited-items"
                        className="dropdown-item p-0 justify-content-start justify-content-lg-center py-2"
                      >
                        <Link
                          to="/footer/prohibited-items"
                          className=" ps-5 ps-sm-4 ps-lg-0 bold-font "
                        >
                          Prohibited Items
                        </Link>
                      </Nav.Link>
                    </NavDropdown.Item>
                  </div>
                </NavDropdown>
                {isLoggedIn && (
                  <Nav.Link href="/user"
                    className="d-block d-lg-none  nav-holder-nav py-2 py-lg-0"
                  >
                    <Link
                      to="/user"
                      onClick={() => {
                        handleProfileClick("profile");
                        scrolltop();
                      }}
                      className="ps-4 ps-sm-0 ps-md-0 ps-0"
                    >
                      My Account{" "}
                    </Link>
                  </Nav.Link>
                )}
                <></>
              </Nav>
              <Nav className="signup-holder d-none d-lg-flex">
                {!isLoggedIn ? (
                  <>
                    <Nav.Link>
                      <Nav.Link
                        onClick={(e) => toggleModal("signup")}
                        style={{ color: "#212529" }}
                      >
                        Signup
                      </Nav.Link>
                    </Nav.Link>
                    <span>|</span>
                    <Nav.Link className="px-0 ps-3">
                      <Nav.Link
                        style={{ color: "#212529" }}
                        onClick={(e) => toggleModal("login")}
                        className="px-0"
                      >
                        Login
                      </Nav.Link>
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Tooltip title={`${user_id?.first_name} ${user_id?.last_name}`} className="pointer-icon"    >
                      <div className="user-profile-username-holder me-2 d-lg-none d-xl-flex align-items-end">
                        <p className="hello text-end mb-1 m-0 lh-1">Hello</p>
                        <p className="m-0 lh-1 text-end overflow-hidden text-nowrap username-header">
                          <b className="">{user_id?.first_name}{" "} {user_id?.last_name}</b>
                        </p>

                      </div>
                    </Tooltip>
                    <div className="custom-dropdown-header">
                      <NavDropdown
                        className="nav-holder-nav nav-dropdown "
                        id="nav-dropdown-basic-example"
                        title={
                          <>
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={`${window.location.origin}/assets/user-profile/maleusericon.svg`}
                                  alt="User Icon"
                                  width={40}
                                />
                              </div>
                            </div>
                          </>
                        }
                        menuVariant="light"
                      >
                        <div className="drpdwn-header">
                          <div
                            className="dropdown-link-item"
                            onClick={() => handleProfileClick("myorders")}
                          >
                            <NavDropdown.Item>
                              <Nav.Link className=" p-0">
                                <Link to="/user" className="px-3 bg-transparent">
                                  <p className="m-0">My Orders</p>{" "}
                                </Link>
                              </Nav.Link>
                            </NavDropdown.Item>
                          </div>
                          <div
                            className="dropdown-link-item"
                            onClick={() => handleProfileClick("profile")}
                          >
                            <NavDropdown.Item>
                              <Nav.Link>
                                <Link to="/user">
                                  <p className="m-0">My Profile</p>
                                </Link>
                              </Nav.Link>
                            </NavDropdown.Item>
                          </div>
                          <div
                            className="dropdown-link-item"
                            onClick={() => handleProfileClick("setting")}
                          >
                            <NavDropdown.Item>
                              <Nav.Link>
                                <Link to="/user">
                                  <p className="m-0">Settings</p>
                                </Link>
                              </Nav.Link>
                            </NavDropdown.Item>
                          </div>
                          <div className="" onClick={() => logOut()}>
                            <NavDropdown.Item>
                              <Nav.Link>
                                <Link to="/">
                                  <p className="m-0 bold-font">Logout</p>
                                </Link>
                              </Nav.Link>
                            </NavDropdown.Item>
                          </div>
                        </div>
                      </NavDropdown>
                    </div>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
            <div className="d-none d-md-none d-lg-block">
              {isLoggedIn &&
                <Nav.Link className=" py-2 py-lg-0  ps-2 me-4 me-sm-0 me-lg-0">
                  <Link className="ps-0 p-sm-0  ps-md-0 ps-0" to="/cartpreview" >
                    <img src={`${window.location.origin}/assets/cart.svg`} width="29"
                      alt="cart"
                    />
                  </Link>
                </Nav.Link>
              }
            </div>
          </Navbar>
        </Container>
        <Authentication
          showAuthenticationModal={showAuthenticationModal}
          setShowAuthenticationModal={setShowAuthenticationModal}
          modalname={modalname}
          setModalname={setModalname}
        />
      </div>
    </>
  );
};

export default Header;
