import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Error500 = () => {
  const navigate = useNavigate();
  const navigatehomepge = () => {
    navigate("/");
  };
  return (
    <>
      <Container>
        <div className="errorpage">
          <Card className="border-0 px-5 text-center position-relative">
            <div className="position-relative">
              <div className="position-relative text-center ">
                <img
                  className="position-absolute z-3"
                  src={`${window.location.origin}/assets/errorimg.png`}
                  alt="Error Icon"
                  width={500}
                  height={400}
                  style={{ marginLeft: "-16rem", marginTop: "0rem" }}
                />
              </div>
              <div
                className="position-relative"
                style={{
                  top: "5rem",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <span
                  style={{
                    color: "#F1F1F1",
                    fontSize: "280px",
                    fontWeight: "500",
                  }}
                >
                  500
                </span>
              </div>
            </div>
            <div className="text-center mb-4">
              <p
                style={{
                  color: "#999999",
                  fontSize: "30px",
                  fontWeight: "bold",
                  marginTop: "-20px",
                }}
              >
                &nbsp;
              </p>
              <pre
                style={{
                  color: "#999999",
                  fontSize: "44px",
                  fontWeight: "bold",
                  marginTop: "00px",
                }}
              >
                Oops.. Internal Server Error....! <br />
                Please Try After Some Time
              </pre>
            </div>
            <div className="text-center mb-5">
              <Button className="primarybtn p-2" onClick={navigatehomepge}>
                return to home page 
              </Button>
            </div>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default Error500;
