import React from "react";
import "../../assets/styles/userProfile/userWallet.css"
// import { Card } from "react-bootstrap";


const DashboardUser = () => {

    return (
        <>
        <h3>Dashboard</h3>
        </>
    );
};

export default DashboardUser;
