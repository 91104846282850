import Swal from 'sweetalert2';

const SwalFireComponent = ({ icon, title }) => {
    Swal.fire({
        toast: true,
        icon,
        title,
        // width:"89%",
        animation: true,
        position: "top-right",
        showConfirmButton: false,
        showCloseButton: true,
        timer: 1500,
        timerProgressBar: true,
        // customClass: {
        //     timerProgressBar: "custom-progress-bar",
        //     popup:"swal-popup-custom"
        // },
    });
};

export default SwalFireComponent;
