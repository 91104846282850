import React, { useState, useEffect } from "react";
import Header from "./components/common/Header";
import LsnRoutes from "./routes/LsnRoutes";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/common/Footer";
import { ModelProvider } from "./utils/modalContext";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setNavigateFunction } from "./utils/navigation";

const App = () => {
  const [id, setId] = useState({ ip_address: "", unique_address: "" })
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setId({ ip_address: res?.data?.ip })
    // localStorage.setItem("ipaddress", res?.data?.ip);
  };
  useEffect(() => {
    getData()
    const uniquesessionid = localStorage.getItem("uniquesessionid");
    if (!uniquesessionid) {
      const newUniqueID = uuidv4();
      setId({ unique_address: newUniqueID })

      localStorage.setItem("uniquesessionid", newUniqueID);

    }
  }, []);

  // let stripePromise = loadStripe(
  //   "pk_test_51NvCCrCaHyWzJ3ZGIwICdQ33liP3GOvSli9CKVWO0thQ9jVY2GehkTvhBGqAL6t10DgIS0Wg8IiL0p3B9eCi9KTM00CPkzMD0J"
  // );
  let stripeURL = `${process.env.REACT_APP_STRIPE_URL}`;

  let stripePromise = loadStripe(stripeURL);
  const navigate = useNavigate();

  useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);

  return (
    <Elements stripe={stripePromise}>
      <ModelProvider>
        <Header />
        <div className="d-flex flex-column">
          <LsnRoutes />
        </div>
        <Footer />
      </ModelProvider>
    </Elements>
  );
};

export default App;
