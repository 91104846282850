import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Form,
  Image,
  Modal,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  bulkOrdersDelete,
  bulkOrdersList,
  fetchShippingServices,
} from "../../../redux/services/ServicesThunk";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Pagination, Skeleton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../assets/styles/bulkOrders/bulkOrders.css";
import AddressCard from "../../../components/profileComponent/UserAddressCards";
import { resetErrors } from "../../../redux/landing/landingSlice";
import {
  deletecartdetail,
  fetchCountryDetails,
} from "../../../redux/landing/landingThunk";
import { getParcels, getcart } from "../../../redux/user_profile/ProfileThunk";
import { formatPrice } from "../../../utils/formatPrice";
import BulkOrderPreviewModal from "./BulkOrderPreviewModal";
import SwalFireComponent from "../../../components/common/SwalFireComponent";
import BulkOrderBookServiceModal from "./BulkOrderBookServiceModal";
import { fetchAddressList } from "../../../redux/address/adressThunk";
import BulkOrderPreviewSkeleton from "./BulkOrderPreviewSkeleton";
import { Link, useNavigate } from "react-router-dom";
import SelectDateTime from "../../../components/addressBook/SelectDateTime";
import AccordianCustomDetails from "../../../components/accordianAddressbook/AccordianCustomDetails";
import Swal from "sweetalert2";
import BulkAllOrderBookServiceModal from "./BulkAllOrderBookServiceModal";
import { resetServiceErrors } from "../../../redux/services/ServicesSlice";

const BulkOrderPreview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const userData = JSON.parse(localStorage.getItem("user"));
  const serviceData = JSON.parse(localStorage.getItem("serviceData"));
  const serviceDatanew = useSelector(
    (state) => state?.landingReducer?.landingShipServices
  );
  const BulkOrderPreviewDataPending = useSelector(
    (state) =>
      state?.servicedata?.bulkorderDataList?.BulkOrderData?.data?.pending
  );
  const BulkOrderPreviewDataCompleted = useSelector(
    (state) =>
      state?.servicedata?.bulkorderDataList?.BulkOrderData?.data?.completed
  );
  const isbulkorderListLoading = useSelector(
    (state) => state?.servicedata?.isbulkorderListLoading
  );

  const parcel_details = useSelector(
    (state) => state?.profileData?.parcelData?.Parcel?.data
  );

  const isbulkorderDeleteLoading = useSelector(
    (state) => state?.servicedata?.isbulkorderDeleteLoading
  );

  const userAddressList = useSelector(
    (state) => state?.addressValidation?.addressList?.CustomerAddress?.data
  );

  const userAddressListloading = useSelector(
    (state) => state?.addressValidation?.isAddressLoading
  );
  const dropoffdata = useSelector(
    (state) =>
      state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location
  );

  const pickupdatanew = useSelector(
    (state) =>
      state?.servicedata?.pickupdropdata?.DropPickupData?.data?.pickup_date
  );

  const dropoffdatanew = useSelector(
    (state) =>
      state?.servicedata?.pickupdropdata?.DropPickupData?.data?.dropoff_location
        ?.date
  );

  const shipmentDataNew = useSelector(
    (state) => state?.servicedata.ShippingService?.data
  );

  const bookservicedata = useSelector(
    (state) => state?.servicedata.ShippingService?.data
  );

  const bookserviceloading = useSelector(
    (state) => state?.servicedata?.isServiceLoading
  );

  const bookserviceError = useSelector(
    (state) => state?.servicedata?.ShippingService?.error
  );

  const bookserviceErrors = useSelector(
    (state) => state?.servicedata?.ShippingService?.errors
  );
  const ispickupdropLoading = useSelector(
    (state) => state?.servicedata?.isPickupLoading
  );

  const cartData = useSelector(
    (state) => state?.profileData?.cartData?.cartData?.data
  );

  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );

  const iscartloading = useSelector(
    (state) => state?.profileData?.isCartDataLoading
  );

  const isDeleteCardLoading = useSelector(
    (state) => state?.landingReducer?.isDeleteCardLoading
  );
  const ShipingLoading = useSelector(
    (state) => state?.landingReducer?.isLoading?.isServicesLoading
  );

  const dropdowndata = [
    "Sale or Purchase",
    "Gift",
    "Sample",
    "Repair/Return",
    "Documents",
    "Intracompany Transfer",
    "Temporary Export",
    "Personal Effect ",
  ];

  const [collectionAddress, setCollectionAddress] = useState();
  const useriD = JSON.parse(localStorage.getItem("user"));
  const [selectData, setSelectData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showAddressModal, setshowAddressModal] = useState(false);
  const [showModalService, setShowModalService] = useState(false);
  const [showAllModalService, setAllShowModalService] = useState(false);
  const [showpickupdrop, setshowpickupdrop] = useState(false);
  const [selectedBulkData, setSelectedBulkData] = useState({});
  const [deleteId, setDeleteId] = useState();
  const [cancelServiceid, setcancelServiceid] = useState();
  const [bookserviceid, setbookserviceid] = useState();
  const [allerrors, setAllerrors] = useState("");
  const [othererrors, setOthererrors] = useState("");
  const [bookServiceLoad, setBookserviceloading] = useState(false);
  const [bookServiceLoadingPopup, setbookServiceLoadingPopup] = useState(false);
  const [collectionDates, setCollectionDates] = useState();
  const [collectionValues, setCollectionValues] = useState();
  const [dropupDates, setDropupDates] = useState();
  const [serviceDataNewFromApi, setserviceDataNewFromApi] = useState();
  const [selectedCollectionService, setSelectedCollectionService] = useState("Drop");
  const [isinsuranceopen, setisinsuranceopen] = useState(false);
  const [key, setKey] = useState("pending");
  const [continueBulk, setContinueBulk] = useState(false);
  const [submitBulk, setSubmitBulk] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagecomplete, setCurrentPagecomplete] = useState(1);
  const [bulkselectid, setbulkselectid] = useState();
  const [singleId, setSingleId] = useState();
  const [pickupValidation, setPickupValidation] = useState(false);
  const [collectionlocation, setcollectionlocation] = useState();


  const [newData, setNewData] = useState([]);
  // const [shipmentErrors, setShipmentErrors] = useState([]);
  const [shipmentErrorsAllModal, setShipmentErrorsAllModal] = useState([]);
  // const [shipmentErrorsAllModalId, setShipmentErrorsAllModalId] = useState([]);
  const [shipmentErrorsModal, setShipmentErrorsModal] = useState([]);
  const [filterData, setFilterData] = useState({
    channel_rate_option: "",
    courier_partners: "",
    courier_id: "",
  });

  const [vatdata, setVatdata] = useState({
    countryInput: "Canada",
    Reason: dropdowndata[0],
  });
  const [formData, setFormData] = useState({
    // unit_value: "",
    // parcel_weight: "",
    // parcel_length: "",
    // parcel_width: "",
    // parcel_height: "",
    // parcel_weight_in: "LBS",
    // parcel_size_in: "IN",
    // Courier_Partners: "",
    // channel_rate_option: "",
    // courier_id:""
  });
  const [selectedRadio, setSelectedRadio] = useState(
    serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No"
  );

  const [formShipData, setFormShipData] = useState({
    insuranceRadio:
      serviceData?.cart_details?.is_enhanced_cover === "Yes" ? "Yes" : "No",
  });
  // let newData = [];

  const hasDefaultAddress =
    userAddressList && userAddressList?.some((i) => i?.is_default === "Yes");

  useEffect(() => {
    if (serviceDatanew?.data?.length) {
      setNewData((prevData) => [...prevData, serviceDatanew]);
    }
    if (serviceDatanew?.serviceerrors) {
      // setShipmentErrors((prevData) => [...prevData, serviceDatanew]);
      setNewData((prevData) => [...prevData, serviceDatanew]);

    }
  }, [serviceDatanew]);

  useEffect(() => {
    const cart_id = localStorage.getItem("cart_id");
    const cartdetails_id = localStorage.getItem("cartdetail_id");
    const newdata = {
      cartdetail_id: cartdetails_id,
      cart_id: cart_id,
      customer_id: useriD?.id,
      has_drop_off_service: selectedCollectionService === "Drop" ? "Yes" : "No",
      collection_date:
        selectedCollectionService !== "Drop"
          ? collectionDates ||
          (pickupdatanew?.length && pickupdatanew[0]?.label)
          : "",
      collection_value:
        selectedCollectionService !== "Drop"
          ? collectionValues ||
          (pickupdatanew?.length && pickupdatanew[0]?.value)
          : "",
      drop_off_date:
        selectedCollectionService === "Drop"
          ? dropupDates || (dropoffdatanew && dropoffdatanew[0])
          : "",
      shipContent: [
        {
          id:
            serviceDataNewFromApi?.cart_details?.cart_package?.length &&
            serviceDataNewFromApi?.cart_details?.cart_package[0]?.id,
          contents: selectedBulkData?.parcel_description || "",
          ship_value: selectedBulkData?.parcel_value,
        },
      ],
      insurance: formShipData?.insuranceRadio,
      channelType: "Bulk",
      // channel_order_id: bookserviceid,
      channel_order_id: showAllModalService && bulkselectid ? bulkselectid : bookserviceid,
    };
    dispatch(fetchShippingServices(newdata))
      .unwrap()
      .then((response) => {
        if (response.status === true && response.statusCode === 200) {
          setserviceDataNewFromApi(response?.data);
          localStorage.setItem("serviceData", JSON.stringify(response?.data));
        }
      });
  }, [formShipData]);

  useEffect(() => {
    if (userAddressList) {
      const data = userAddressList?.find((i) => {
        return i.is_default === "Yes";
      });
      setAllerrors("")
      setOthererrors('')
      setCollectionAddress(data);
    }
  }, [userAddressList]);

  useEffect(() => {
    dispatch(bulkOrdersList())
      .unwrap()
      .then((response) => {
        if (
          response?.BulkOrderData?.status === "success" &&
          response?.BulkOrderData?.code === "200"
        ) {
          let data = {
            // cart_id: Number(cartId),
            customer_id: userData?.id,
          };
          dispatch(getcart(data));
        }
      });
  }, [dispatch]);

  useEffect(() => {
    if (BulkOrderPreviewDataPending) {
      setFormData(BulkOrderPreviewDataPending);
      dispatch(
        fetchAddressList({
          // address_id: formData?.address_id,
          customer_id: useriD.id,
          uniquesessionid: uniquesessionid,
        })
      );
    }
  }, [BulkOrderPreviewDataPending]);

  useEffect(() => {
    if (parcel_details && parcel_details.length > 0) {
      const select = {
        parcel_name: "Select",
      };
      const updatedParcelDetails = [select, ...parcel_details];
      setSelectData(updatedParcelDetails);
    }
  }, [parcel_details]);

  useEffect(() => {
    dispatch(resetErrors());
    localStorage.removeItem("searchData");
    localStorage.removeItem("cartdetail_id");
    // localStorage.removeItem("serviceData")
    localStorage.removeItem("DropOffData");
    localStorage.removeItem("multiship_data");
    dispatch(fetchCountryDetails());
    dispatch(getParcels({ customer_id: useriD?.id }));
  }, [dispatch]);

  const handleContinueClick = () => {
    setContinueBulk(true);
    const cart_id = localStorage.getItem("cart_id");
    const cartdetails_id = localStorage.getItem("cartdetail_id");
    let data = {
      cartdetail_id: cartdetails_id,
      cart_id: cart_id,
      customer_id: useriD?.id,
      has_drop_off_service: selectedCollectionService === "Drop" ? "Yes" : "No",
      collection_date: selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
      collection_value: selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
      drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
      insurance: formShipData?.insuranceRadio,
      channelType: "Bulk",
      channel_order_id: showAllModalService && bulkselectid ? bulkselectid : bookserviceid,
      has_customdoc: bookservicedata?.cart_details?.collection_country_id === bookservicedata?.cart_details?.delivery_country_id ? "No" : "Yes",
    };
    dispatch(fetchShippingServices(data))
      .unwrap()
      .then((response) => {
        if (response.status === true && response.statusCode === 200) {
          localStorage.setItem("DropOffData", JSON.stringify(data));
          localStorage.setItem("serviceData", JSON.stringify(response?.data));
          setserviceDataNewFromApi(response?.data);

          const newdata = {
            cartdetail_id: cartdetails_id,
            cart_id: cart_id,
            customer_id: useriD?.id,
            has_drop_off_service: selectedCollectionService === "Drop" ? "Yes" : "No",
            collection_date: selectedCollectionService !== "Drop" ? collectionDates || pickupdatanew[0]?.label : "",
            collection_value: selectedCollectionService !== "Drop" ? collectionValues || pickupdatanew[0]?.value : "",
            drop_off_date: selectedCollectionService === "Drop" ? dropupDates || dropoffdatanew[0] : "",
            shipContent: [{ id: response?.data?.cart_details?.cart_package[0]?.id, contents: selectedBulkData?.parcel_description || "", ship_value: selectedBulkData?.parcel_value },],
            insurance: formShipData?.insuranceRadio,
            channelType: "Bulk",
            channel_order_id: showAllModalService && bulkselectid ? bulkselectid : bookserviceid
          };
          dispatch(fetchShippingServices(newdata))
            .unwrap()
            .then((response) => {
              setContinueBulk(false);
              if (response.status === true && response.statusCode === 200) {
                setisinsuranceopen(true);
                setserviceDataNewFromApi(response?.data);
                localStorage.setItem(
                  "serviceData",
                  JSON.stringify(response?.data)
                );
              }
            });
        } else if (response.statusCode === 440 && response.status === false) {
          // navigate("/service");
          setContinueBulk(false);
        }
      });
  };

  const finalsubmit = () => {
    setSubmitBulk(true);
    const datad = country_Name?.find((i) => {
      return i?.name === vatdata?.countryInput;
    });

    const cart_id = localStorage.getItem("cart_id");
    const cartdetails_id = localStorage.getItem("cartdetail_id");
    const newdata = {
      cartdetail_id: cartdetails_id,
      cart_id: cart_id,
      customer_id: useriD?.id,
      has_drop_off_service: selectedCollectionService === "Drop" ? "Yes" : "No",
      collection_date:
        selectedCollectionService !== "Drop"
          ? collectionDates || pickupdatanew[0]?.label
          : "",
      collection_value:
        selectedCollectionService !== "Drop"
          ? collectionValues || pickupdatanew[0]?.value
          : "",
      drop_off_date:
        selectedCollectionService === "Drop"
          ? dropupDates || dropoffdatanew[0]
          : "",
      shipContent: [
        {
          id: serviceDataNewFromApi?.cart_details?.cart_package[0]?.id,
          contents: selectedBulkData?.parcel_description || "",
          ship_value: selectedBulkData?.parcel_value,
        },
      ],
      insurance: formShipData?.insuranceRadio,
      is_completed: true,
      channelType: "Bulk",
      channel_order_id: showAllModalService && bulkselectid ? bulkselectid : bookserviceid,
      has_customdoc:
        bookservicedata?.cart_details?.collection_country_id ===
          bookservicedata?.cart_details?.delivery_country_id
          ? "No"
          : "Yes",
    };
    if (
      bookservicedata?.cart_details?.collection_country_id !==
      bookservicedata?.cart_details?.delivery_country_id
    ) {
      newdata.reason_for_export = vatdata?.Reason;
      newdata.country_of_manufacture = datad?.id;
    }
    dispatch(fetchShippingServices(newdata))
      .unwrap()
      .then((response) => {
        setSubmitBulk(false);
        if (response.status === true && response.statusCode === 200) {
          setbookserviceid();
          let data = {
            // cart_id: Number(cartId),
            customer_id: userData?.id,
          };
          dispatch(getcart(data));
          dispatch(bulkOrdersList());
          setserviceDataNewFromApi(response?.data);
          localStorage.setItem("serviceData", JSON.stringify(response?.data));
          setSelectedCollectionService("Drop");
          setCollectionValues("");
          setCollectionDates("");
          setshowpickupdrop(false);
          setShowModalService(false);
        }
      })
      .catch((error) => {
        setSubmitBulk(false);
      });
  };

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setSelectedRadio(value);

    // If 'Yes' is selected, update formShipData with relevant data
    if (value === "Yes") {
      setFormShipData({
        ...formShipData,
        insuranceRadio: "Yes",
      });

      // setShipContent({
      //   ...shipContent,
      //   insurance: "Yes",
      // })
    } else {
      setFormShipData({
        ...formShipData,
        insuranceRadio: "No",
      });
      // setShipContent({
      //   ...shipContent,
      //   insurance: "No",
      // });
      // setShipContent({
      //   ...shipContent,
      //   insurance: "Yes",
      // })
    }
  };

  const validationSchema = Yup.object({
    unit_value: Yup.number().required("Required"),
    parcel_weight: Yup.number().required("Required"),
    parcel_length: Yup.number().required("Required"),
    parcel_width: Yup.number().required("Required"),
    parcel_height: Yup.number().required("Required"),
  });

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => { },
  });

  const handleBookClick = (bulkData, bulkAllData, singlebook) => {
    setShipmentErrorsAllModal([])
    // setShipmentErrorsAllModalId()
    setShipmentErrorsModal([])
    setAllerrors("")
    setOthererrors("")
    if (singlebook) {
      setOthererrors("")
      setSingleId(bulkData?.id);
    } else {
      setSingleId();
    }
    if (hasDefaultAddress) {
      setBookserviceloading(true);
      setSelectedBulkData(bulkData);
      setShowModalService(true);
      setbookserviceid(bulkData?.id);

      setSelectedCollectionService("Drop");
      setCollectionValues();
      setCollectionDates();
    } else {
      setbookserviceid(bulkData?.id);
      setSelectedBulkData(bulkData);
      setSingleId(bulkData?.id);
      setOthererrors("Please add collection address");
    }
  };

  const handleBookAll = async () => {
    setSingleId()
    setAllerrors('')
    setSelectData()
    setSelectedBulkData()
    dispatch(resetErrors())
    setBookserviceloading(false)
    setNewData([]);
    // setShipmentErrors([]);
    setShipmentErrorsAllModal([])
    // setShipmentErrorsAllModalId()
    setAllShowModalService(true);

    if (Array.isArray(BulkOrderPreviewDataPending)) {
      for (const bulkAllData of BulkOrderPreviewDataPending) {
        if (!bulkAllData?.cartdetail_id) {
          await handleBookClick(bulkAllData, true, false);
        }
      }
    }
  };

  const handleReset = () => {
    setNewData([]);
    // setShipmentErrors([]);
    setShipmentErrorsAllModal([])
    // setShipmentErrorsAllModalId()
    setAllShowModalService(false);
    // setFormData({
    //   Courier_Partners: "",
    //   channel_rate_option: "",
    // });
    setFilterData({
      channel_rate_option: "",
      courier_partners: "",
      courier_id: "",
    });
    setOthererrors("")
    setAllerrors("")
  };

  const handleclear = (id, newEditValue) => {
    const data = newEditValue?.find((p) => p?.id === id);
    let newData = { ...data, editData: true }
    let allbulk = showAllModalService ? false : true;
    if (id) {
      handleBookClick(newData, true, allbulk);
    }
    setSingleId(id);
    // setShowModalService(false);
    // setAllShowModalService(false);
    // setShowModalService(false);
    // setNewData([]);
    // handleReset();
  };

  const handlecancelService = (bulkData) => {
    setcancelServiceid(bulkData?.id);
    let finalactivedata = {
      cart_id: bulkData?.cart_details?.cart_id,
      cartdetail_id: bulkData?.cart_details?.id,
      customer_id: useriD?.id,
      bulk_id: bulkData?.id
    };
    dispatch(deletecartdetail(finalactivedata))
      .then((response) => {
        if (
          response?.payload?.status === true &&
          response?.payload?.statusCode === 200
        ) {
          dispatch(bulkOrdersList());
          let data = {
            customer_id: userData?.id,
          };
          dispatch(getcart(data));
        }
        if (
          response?.payload?.status === false &&
          response?.payload?.statusCode === 500
        ) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "Something went wrong! please try some time later",
            timer: 5000,
          }).then((result) => {
            if (
              result.isConfirmed ||
              result.dismiss === Swal.DismissReason.timer
            ) {
              window.location.reload();
            }
          });
        }
      })
      .catch((error) => { });
  };

  const handleEditClick = (bulkData) => {
    setSelectedBulkData(bulkData);
    setShowModal(true);
  };

  const handleDelete = (bulkData) => {
    setDeleteId(bulkData?.id);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success me-3 px-4 ",
        cancelButton: "btn btn-danger me-2 px-4",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete this items ?",
        text: "This action is irreversible and will permanently delete the items.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(bulkOrdersDelete({ cart_id: bulkData?.id }))
            .unwrap()
            .then((response) => {
              if (
                response.BulkOrder.statusCode === 200 &&
                response.BulkOrder.status === "success"
              ) {
                SwalFireComponent({
                  icon: "success",
                  title: "Cart item delete successfully",
                });
                dispatch(bulkOrdersList());
                let data = {
                  customer_id: userData?.id,
                };
                dispatch(getcart(data));
              }
            });
        }
      });
  };

  const handleDeleteAll = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success me-3 px-4",
        cancelButton: "btn btn-danger me-2 px-4",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete all items ?",
        text: "This action is irreversible and will permanently delete all the items.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire({
            title: "Deleted",
            text: "Item deleted successfully",
            icon: "warning",
          });
          dispatch(bulkOrdersDelete({ cart_id: `delete-all-${key}` }))
            .unwrap()
            .then((response) => {
              if (
                response.BulkOrder.statusCode === 200 &&
                response.BulkOrder.status === "success"
              ) {
                SwalFireComponent({
                  icon: "success",
                  title: "Cart item delete successfully",
                });
                dispatch(bulkOrdersList());
                let data = {
                  // cart_id: Number(cartId),
                  customer_id: userData?.id,
                };
                dispatch(getcart(data));
              }
            });
        }
      });
  };

  const handleCloseModal = () => {
    setshowpickupdrop(false);
    setshowAddressModal(false);
    setShowModal(false);
    // setShowModalService(false)
    setisinsuranceopen(false);
    setSelectedCollectionService("Drop");
    setCollectionValues();
    setCollectionDates();
    setShowModalService(false);
    setContinueBulk(false);
    dispatch(resetErrors());
    // handleReset()
    // setBookserviceloading(false)
    // setbookserviceid()
  };

  const setopenaddressmodel = () => {
    // dispatch(resetErrors());
    // dispatch(resetServiceErrors())
    setAllerrors("")
    setNewData([]);
    setshowAddressModal(true);
  };



  const CourierPartners = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "FEDEX",
      value: "FEDEX",
    },
    {
      label: "UPS",
      value: "UPS",
    },
  ];
  const RateOptions = [
    {
      label: "Select",
      value: "",
    },
    {
      label: "Cheapest First",
      value: "cheapest",
    },
    {
      label: "Fastest First",
      value: "fastest",
    },
  ];

  const courierIdMap = {
    FEDEX: 1,
    UPS: 2,
    DHL: 3,
    USPS: 4,
  };

  const handleTabChange = (selectedKey) => {
    setKey(selectedKey);
  };

  const filteredDataCount = BulkOrderPreviewDataPending?.length;
  const filteredDataCountcomplete = BulkOrderPreviewDataCompleted?.length;

  const itemsPerPage = 50;

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handlePageChangecomplete = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return BulkOrderPreviewDataPending?.slice(startIndex, endIndex);
  };
  const getCurrentPageDatacomplete = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return BulkOrderPreviewDataCompleted?.slice(startIndex, endIndex);
  };
  let nullNameCount = getCurrentPageData()?.filter(item => item.cartdetail_id === null).length;

  const handleSelectChange = (event) => {
    const selectedCourierPartner = event.target.value;
    const selectedCourierId = courierIdMap[selectedCourierPartner] || "";
    // setFormData((prevFormData) => ({
    // //   ...prevFormData,
    //   Courier_Partners: selectedCourierPartner,
    //   courier_id: selectedCourierId,
    // }));
    setFilterData({
      ...filterData,
      courier_partners: selectedCourierPartner,
      courier_id: selectedCourierId,
    });
  };

  const handleSelectChangeRate = (event) => {
    const selectedRate = event.target.value;
    // setFormData((prevFormData) => ({
    // //   ...prevFormData,
    //   channel_rate_option: selectedRate,
    // }));
    setFilterData({
      ...filterData,
      channel_rate_option: selectedRate,
    });
  };

  const channelId = cartData?.cart_details?.find(item => item?.channel_order_id);
  const cardData = [
    {icon:`${window.location.origin}/assets/multishipment/bulk1.svg`,text: "Optimize your shipment in one click!" },
    {icon:`${window.location.origin}/assets/multishipment/bulk2.svg`,text: "Update courier, dates, & parcels effortlessly" },
    {icon:`${window.location.origin}/assets/multishipment/bulk3.svg`,text: "Perfect for same-country shipments" },
    {icon:`${window.location.origin}/assets/multishipment/bulk4.svg`,text: "Convenience with bulk uploads" },
  ];
  return (
    <>
      <div className="pickupdrop-outer">
        <Container className="p-1">
          {BulkOrderPreviewDataPending ? (
            <>
              <div className={`my-5 ${bookserviceloading && "py-5"}`}>
                {/* {!bookServiceLoad ? ( */}
                <>
                  <div className=" d-flex flex-column flex-xl-row gap-4 px-4 px-md-0">
                    <div style={{ flex: "4" }} className=" d-flex flex-column gap-3 "    >
                      {/* {BulkOrderPreviewDataPending?.length > 1 && <> */}

                      <div className="d-flex gap-3 bulk_cards_outer ">
                        {cardData.map((card, index) => (
                          <Card className="card-border bulk_info_card" style={{ flex: "1" }} key={index}     >
                            <Card.Body className=" d-flex align-items-center p-3">
                              <div className="d-flex gap-2">
                                <div className="" style={{ flex: "1" }}>
                                  <div className=" bulk-round rounded-5 h-100 d-flex justify-content-center align-items-center" style={{ width: "60px", height: "60px" }}  > 
                                    <Image
                                      alt="Seamless"
                                      src={card?.icon}
                                      fluid
                                      className="footerlogo"
                                    />
                            </div>
                                </div>
                                <div className="" style={{ flex: "2" }}>
                                  <div>
                                    <p className="paragraph2 bulk-card-text">
                                      {card.text}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                      </div>

                      <div className="d-flex align-items-center ">
                        <div className="me-3">
                          <p className="collectoinh5 blue-text">
                            <span>Master Details</span>
                          </p>
                        </div>
                        <div className="hrlinedivdelivery">
                          <hr className="hrlinedelivery" />
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3 flex-md-row">
                        <div className="d-flex gap-3 w-100">
                          <Form.Group controlId="formGridfirstname" className="w-100"    >
                            <Form.Label className="formlabel">
                              <p className=" of-black-dark">Courier Partner</p>
                            </Form.Label>
                            <Form.Select placeholder="Select" className={`Pickup-select-input shadow-none`} onChange={handleSelectChange} value={filterData?.courier_partners}      >
                              {CourierPartners.map((option, index) => {
                                return (
                                  <option key={index} value={option?.value}>
                                    {option?.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                          <Form.Group controlId="formGridfirstname" className="w-100"   >
                            <Form.Label className="formlabel">
                              <p className=" of-black-dark">Service Type</p>
                            </Form.Label>
                            <Form.Select placeholder="Select" className={`Pickup-select-input shadow-none`}
                              onChange={handleSelectChangeRate} value={filterData?.channel_rate_option} >
                              {RateOptions.map((option, index) => {
                                return (
                                  <option key={index} value={option?.value}>
                                    {option?.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="d-flex  gap-3 w-100">
                          <Form.Group className="d-flex flex-column bulk-apply-all w-50">
                            <Form.Label className="d-none d-md-block">
                              <p className=" of-black-dark">&nbsp;</p>
                            </Form.Label>
                            {/* disabled={!filterData?.channel_rate_option && !filterData?.courier_partners} */}
                            <Button disabled={nullNameCount < 2}  className="primarybtn btn height-44" onClick={() => handleBookAll()}   >
                              {ShipingLoading && showAllModalService ? (
                                <>
                                  <Spinner animation="border" size="sm" />
                                </>
                              ) : (
                                <>Apply for All</>
                              )}
                            </Button>
                          </Form.Group>
                          <Form.Group className="d-flex flex-column bulk-reset w-50">
                            <Form.Label className="d-none d-md-block">
                              <p className=" of-black-dark">&nbsp;</p>
                            </Form.Label>
                            <Button className="address_btn border text-nowrap height-44 w-100" variant="link" onClick={() => handleReset()}   >
                              <>Reset</>
                            </Button>
                          </Form.Group>
                        </div>
                      </div>
                      {othererrors && showAllModalService && (
                        <>
                          <p className="text-danger error1">
                            {othererrors}
                          </p>
                        </>
                      )}

                      {/* </> */}
                      {/* } */}
                      <div className="d-flex flex-column  gap-3 ">
                        <Form.Group className="d-flex flex-column w-100">
                          <Button className="secondry w-100 btn height-44" onClick={handleDeleteAll}>
                            {isbulkorderDeleteLoading ? (
                              <>
                                <Spinner animation="border" size="sm" />
                              </>
                            ) : (
                              <>{`Delete all ${key && key}`}</>
                            )}
                          </Button>
                        </Form.Group>
                        <div className="d-flex d-lg-flex align-items-center">
                          <div className="me-3">
                            <p className="collectoinh5 blue-text">
                              <span>Orders Details</span>
                            </p>
                          </div>
                          <div className="hrlinedivdelivery">
                            <hr className="hrlinedelivery" />
                          </div>
                        </div>

                        <div className=" user-Wallet">
                          <Tabs id="fill-tab-example" className="border-0" fill activeKey={key} onSelect={handleTabChange}  >
                            <Tab eventKey="pending" title="Pending" className={`user-tab-content p-1 p-sm-3 ${BulkOrderPreviewDataPending[0] && "pt-3"} `} style={{ flex: "1" }}  >
                              <div className="d-flex flex-column gap-3">
                                {getCurrentPageData() &&
                                  getCurrentPageData().length ? (
                                  <>
                                    {getCurrentPageData() &&
                                      getCurrentPageData()?.map(
                                        (bulkData, index) => (
                                          <Card className="card-border">
                                            <div className="">
                                              <Card.Body style={{ background: bulkData?.orderdetail_id < 1 && bulkData?.cart_details ? "#E5F2E5" : bulkData?.cart_details ? "#E9E9E9" : "initial", }}   >
                                                <Form onSubmit={formik.handleSubmit}  >
                                                  <div className="d-flex flex-column  gap-2 ">
                                                    <div className="d-flex flex-column flex-lg-row gap-2 gap-lg-4 ">
                                                      <div
                                                        className="d-flex justify-content-between"
                                                        style={{ flex: "2" }}
                                                      >
                                                        <div>
                                                          <div className="d-flex gap-2">
                                                            <p className="bulk-head-text">
                                                              To Name
                                                            </p>
                                                            :
                                                            <p className="m-0 bulk-description-text">
                                                              {
                                                                bulkData?.first_name
                                                              }{" "}
                                                              {
                                                                bulkData?.last_name
                                                              }
                                                            </p>
                                                          </div>
                                                          {bulkData?.company_name && (
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Company Name
                                                              </p>{" "}
                                                              :
                                                              <p className="m-0 bulk-description-text">
                                                                {
                                                                  bulkData?.company_name
                                                                }
                                                              </p>
                                                            </div>
                                                          )}
                                                          <div className="d-flex gap-2">
                                                            <p className="bulk-head-text">
                                                              Address
                                                            </p>
                                                            :
                                                            <p className="m-0 bulk-description-text">
                                                              {
                                                                bulkData?.address_1
                                                              }{" "}
                                                              {
                                                                bulkData?.address_2
                                                              }
                                                              ,{"  "}
                                                              {bulkData?.city},
                                                              {"  "}
                                                              {
                                                                bulkData?.state_or_county
                                                              }
                                                              ,{" "}
                                                              {
                                                                bulkData?.postcode
                                                              }
                                                              ,
                                                              {
                                                                bulkData
                                                                  ?.country
                                                                  ?.code
                                                              }
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="d-flex d-lg-none">
                                                          <img
                                                            style={{
                                                              width: "30px",
                                                              height: "30px",
                                                            }}
                                                            className=""
                                                            alt="icon"
                                                            src={`${window.location.origin}/assets/address-icons/excelicon.svg`}
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="d-flex flex-column gap-3 "
                                                        style={{ flex: "3" }}
                                                      >
                                                        <div className="d-flex flex-column flex-md-row ">
                                                          <div
                                                            style={{
                                                              flex: "3",
                                                            }}
                                                          >
                                                            <div className="shipment-details-text-p  d-flex gap-4 gap-lg-4 justify-content-between justify-content-lg-start ">
                                                              <div className="d-flex gap-2">
                                                                <p className="bulk-head-text">
                                                                  Weight
                                                                </p>
                                                                :
                                                                <p className="d-flex bulk-description-text">
                                                                  <p className="d-flex gap-1 bulk-description-text">
                                                                    <p>
                                                                      {
                                                                        bulkData?.parcel_weight
                                                                      }
                                                                    </p>{" "}
                                                                    <p>
                                                                      {bulkData?.parcel_weight_measure
                                                                        ? bulkData?.parcel_weight_measure
                                                                        : "LBS"}
                                                                    </p>
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="d-flex gap-2">
                                                                <p className="bulk-head-text">
                                                                  Length
                                                                </p>
                                                                :
                                                                <p className="d-flex bulk-description-text">
                                                                  <p>
                                                                    {
                                                                      bulkData?.parcel_length
                                                                    }
                                                                  </p>{" "}
                                                                  <p>
                                                                    &nbsp;
                                                                    {bulkData?.parcel_dimension_in
                                                                      ? bulkData?.parcel_dimension_in
                                                                      : "IN"}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="d-flex gap-4 gap-lg-4 justify-content-between justify-content-lg-start">
                                                              <div className="d-flex gap-2">
                                                                <p className="bulk-head-text">
                                                                  Width
                                                                </p>
                                                                :
                                                                <p className="d-flex bulk-description-text">
                                                                  <p>
                                                                    {
                                                                      bulkData?.parcel_width
                                                                    }
                                                                  </p>{" "}
                                                                  <p>
                                                                    &nbsp;
                                                                    {bulkData?.parcel_dimension_in
                                                                      ? bulkData?.parcel_dimension_in
                                                                      : "IN"}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="d-flex gap-2">
                                                                <p className="bulk-head-text">
                                                                  Height
                                                                </p>:
                                                                <p className="d-flex bulk-description-text">
                                                                  <p>
                                                                    {bulkData?.parcel_height}
                                                                  </p>{" "}
                                                                  <p>
                                                                    &nbsp;{bulkData?.parcel_dimension_in ? bulkData?.parcel_dimension_in : "IN"}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                {" "}Parcel   Description
                                                              </p>:
                                                              <p className="bulk-description-text">
                                                                {bulkData?.parcel_description}
                                                              </p>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Value
                                                              </p>
                                                              :
                                                              <p className="bulk-description-text">
                                                                {formatPrice(
                                                                  bulkData?.parcel_value
                                                                )}
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className="d-none d-lg-flex ">
                                                            <img style={{ width: "30px", height: "30px", }} className="" alt="icon"
                                                              src={`${window.location.origin}/assets/address-icons/excelicon.svg`} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className={`d-flex w-100 bulk_button_outer `}  >
                                                      {/* ${bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? "" : "flex-column"} */}
                                                      {bulkData?.cart_details && bulkData?.orderdetail_id < 1 && (
                                                        <div className="" style={{ flex: "3", }}>
                                                          <>
                                                            <div className="d-flex flex-wrap">
                                                              <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                <div className=" ">
                                                                  <img style={{ width: "30px", height: "30px", }} className="" alt="icon"
                                                                    src={bulkData?.cart_details?.courier?.courier_image}
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1 text-nowrap me-3">
                                                                <p className="bulk-head-text">
                                                                  {bulkData?.cart_details?.courier_service_name}{" "}:
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(bulkData?.cart_details?.base_price)}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1 text-nowrap me-3">
                                                                <p className="bulk-head-text">
                                                                  Collection Charge :
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(bulkData?.cart_details?.collection_charge)}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap">
                                                              {bulkData?.cart_details?.is_enhanced_cover === "Yes" && bulkData?.cart_details?.enhanced_cover_cost > 0 && (
                                                                <>
                                                                  <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                    <p className="bulk-head-text">
                                                                      Insurance{" "}
                                                                    </p>{" "}
                                                                    :{" "}
                                                                    <p className="d-flex gap-1 bulk-description-text">
                                                                      <p>
                                                                        {bulkData?.cart_details?.is_enhanced_cover === "Yes" && bulkData?.cart_details?.enhanced_cover_cost > 0 && ` ${formatPrice(bulkData?.cart_details?.enhanced_cover_cost)} `}
                                                                      </p>
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              )}
                                                              <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                <p className="bulk-head-text">
                                                                  Total{" "}
                                                                </p>
                                                                :{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(bulkData?.cart_details?.total)}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1 me-3">
                                                                <p className="bulk-head-text">
                                                                  Collection  Date{" "}
                                                                </p>:{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {bulkData?.cart_details?.collection_date}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="d-flex gap-2 justify-content-start justify-content-md-end bulk_button_outer">
                                                      <div className=" w-100"></div>
                                                      <div className=" w-100">
                                                        {bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? (
                                                          <>
                                                            <Button className="bulk-button w-100 text-nowrap" variant="outlined"
                                                              // disabled={showModalService && bookserviceid === formData[index]?.id}
                                                              onClick={() => { if (!(showModalService && bookserviceid === formData[index]?.id)) { handleBookClick(bulkData, false, true); } }}>
                                                              {bookServiceLoad && bookserviceid === formData[index]?.id ? (
                                                                <>
                                                                  <Spinner animation="border" size="sm" />
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon"
                                                                    src={`${window.location.origin}/assets/address-icons/book.svg`} />
                                                                  Book a Service
                                                                </>
                                                              )}
                                                            </Button>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <div className="w-100">
                                                              <Button className="bulk-button-second w-100 text-nowrap" variant="outlined"
                                                                onClick={() => handlecancelService(bulkData, index)} >
                                                                {isDeleteCardLoading && cancelServiceid === formData[index]?.id ? (
                                                                  <>
                                                                    <Spinner animation="border" size="sm" />{" "}
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    <img style={{ width: "14px", height: "14px", }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/cancel.svg`} />
                                                                    Cancel Select Service
                                                                  </>
                                                                )}
                                                              </Button>
                                                            </div>
                                                          </>
                                                        )}
                                                      </div>
                                                      {!bulkData?.cart_details && (
                                                        <div className=" w-100">
                                                          <div className="w-100">
                                                            <Button className="bulk-button w-100 text-nowrap" variant="outlined" onClick={() => handleEditClick(bulkData, index)}  >
                                                              <img style={{ width: "14px", height: "14px", }}
                                                                className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/editbulk.svg`} />
                                                              Edit
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      )}
                                                      <div className=" w-100">
                                                        <Button className={` w-100 text-nowrap ${bulkData?.orderdetail_id < 1 && !bulkData?.cart_details ? "bulk-button" : "bulk-button-second"}`} variant="outlined" onClick={() => handleDelete(bulkData, index)}  >
                                                          {isbulkorderDeleteLoading && deleteId === formData[index]?.id ? (
                                                            <>
                                                              <Spinner animation="border" size="sm" />{" "}
                                                            </>
                                                          ) : (
                                                            <>
                                                              <img style={{ width: "14px", height: "14px" }} className="pointer-icon me-2" alt="icon" src={`${window.location.origin}/assets/address-icons/deletebulk.svg`} />
                                                              Delete
                                                            </>
                                                          )}
                                                        </Button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {showModalService && bookserviceid === formData[index]?.id && (
                                                    <div className={`${(bookserviceloading || ispickupdropLoading) && "bulkloading_service "}`} >
                                                      <BulkOrderBookServiceModal
                                                        // allerrors={allerrors}
                                                        singleId={singleId}
                                                        bulkselectid={bulkselectid}
                                                        bookserviceid={bookserviceid}
                                                        showModalService={showModalService}
                                                        setShipmentErrorsModal={setShipmentErrorsModal}
                                                        formData={formData}
                                                        filterData={filterData}
                                                        bulkData={selectedBulkData}
                                                        userAddressList={collectionAddress}
                                                        setAllerrors={setAllerrors}
                                                        allerrors={allerrors}
                                                        setshowpickupdrop={setshowpickupdrop}
                                                        setisinsuranceopen={setisinsuranceopen}
                                                        setBookserviceloading={setBookserviceloading}
                                                        setshowAddressModal={setshowAddressModal}
                                                        bookServiceLoad={bookServiceLoad}
                                                        setbookServiceLoadingPopup={setbookServiceLoadingPopup}
                                                        bookServiceLoadingPopup={bookServiceLoadingPopup}
                                                        bookserviceloading={bookserviceloading}
                                                        ispickupdropLoading={ispickupdropLoading}
                                                        showAllModalService={showAllModalService}
                                                      />
                                                    </div>
                                                  )}
                                                  { allerrors && bulkselectid  === formData[index]?.id && !bookServiceLoad && !bookserviceloading && !singleId &&  (
                                                    <>
                                                      <p className="text-danger error2">
                                                        {allerrors}
                                                      </p>
                                                    </>)}
                                                  {showAllModalService && !bulkData?.cart_details && (
                                                    <>
                                                      <BulkAllOrderBookServiceModal formData={formData} filterData={filterData} bulkselectid={bulkselectid} setbulkselectid={setbulkselectid}
                                                        singleId={singleId}
                                                        serviceData={newData?.find((item) => item.channel_order_id === bulkData?.id)}
                                                        bulkid={bulkData?.id}
                                                        bulkData={bulkData}
                                                        userAddressList={collectionAddress}
                                                        setAllerrors={setAllerrors}
                                                        allerrors={allerrors}
                                                        setshowpickupdrop={setshowpickupdrop}
                                                        setisinsuranceopen={setisinsuranceopen}
                                                        setBookserviceloading={setBookserviceloading}
                                                        setshowAddressModal={setshowAddressModal}
                                                        setSelectedBulkData={setSelectedBulkData}
                                                        bookServiceLoad={bookServiceLoad}
                                                        setbookServiceLoadingPopup={setbookServiceLoadingPopup}
                                                        bookServiceLoadingPopup={bookServiceLoadingPopup}
                                                        bookserviceloading={bookserviceloading}
                                                        ispickupdropLoading={ispickupdropLoading}
                                                      />
                                                    </>
                                                  )}

                                                  {/* {othererrors && showAllModalService && singleId === bulkData?.id && (
                                                    <>
                                                      <p className="text-danger">
                                                        {othererrors}
                                                      </p>
                                                    </>
                                                  )} */}
                                                  {othererrors && singleId === bulkData?.id && (
                                                    <>
                                                      <p className="text-danger error3">
                                                        {othererrors}
                                                      </p>
                                                    </>
                                                  )}
                                                </Form>
                                              </Card.Body>
                                            </div>
                                          </Card>
                                        )
                                      )}
                                    {filteredDataCount >= itemsPerPage && (
                                      <div className=" d-flex justify-content-end">
                                        <Pagination
                                          count={Math.ceil(
                                            filteredDataCount / itemsPerPage
                                          )}
                                          variant="outlined"
                                          shape="rounded"
                                          page={currentPage}
                                          onChange={(event, page) => {
                                            handlePageChange(event, page);
                                            scrollToTop();
                                          }}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <>
                                      <h6 className="text-center">
                                        Data not found...!
                                      </h6>
                                    </>
                                  </>
                                )}
                              </div>
                            </Tab>

                            <Tab
                              disabled={
                                BulkOrderPreviewDataCompleted.length < 1
                              }
                              eventKey="completed"
                              title="Complete"
                              className="user-tab-content p-3"
                              style={{ flex: "1" }}
                            >
                              <div className="d-flex flex-column gap-3">
                                {getCurrentPageDatacomplete() &&
                                  getCurrentPageDatacomplete().length ? (
                                  <>
                                    {getCurrentPageDatacomplete()?.map(
                                      (bulkData, index) => (
                                        <Card className="card-border overflow-hidden">
                                          <div className="">
                                            <Card.Body
                                              style={{
                                                background:
                                                  bulkData?.orderdetail_id <
                                                    1 && bulkData?.cart_details
                                                    ? "#E5F2E5"
                                                    : bulkData?.cart_details
                                                      ? "white"
                                                      : "initial",
                                              }}
                                            >
                                              <Form
                                                onSubmit={formik.handleSubmit}
                                              >
                                                <div className="d-flex flex-column gap-2">
                                                  <div className="d-flex flex-column flex-md-row gap-4">
                                                    <div
                                                      className=""
                                                      style={{ flex: "2" }}
                                                    >
                                                      <div className="d-flex gap-2">
                                                        <p className="bulk-head-text">
                                                          To Name
                                                        </p>
                                                        :
                                                        <p className="m-0 bulk-description-text">
                                                          {bulkData?.first_name}{" "}
                                                          {bulkData?.last_name}
                                                        </p>
                                                      </div>
                                                      {bulkData?.company_name && (
                                                        <div className="d-flex gap-2">
                                                          <p className="bulk-head-text">
                                                            Company Name
                                                          </p>{" "}
                                                          :
                                                          <p className="m-0 bulk-description-text">
                                                            {
                                                              bulkData?.company_name
                                                            }
                                                          </p>
                                                        </div>
                                                      )}
                                                      <div className="d-flex gap-2">
                                                        <p className="bulk-head-text">
                                                          Address
                                                        </p>
                                                        :
                                                        <p className="m-0 bulk-description-text">
                                                          {bulkData?.address_1}{" "}
                                                          {bulkData?.address_2},
                                                          {"  "}
                                                          {bulkData?.city},
                                                          {"  "}
                                                          {
                                                            bulkData?.state_or_county
                                                          }
                                                          , {bulkData?.postcode}
                                                          ,
                                                          {
                                                            bulkData?.country
                                                              ?.code
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="d-flex flex-column gap-3 "
                                                      style={{ flex: "3" }}
                                                    >
                                                      <div className="d-flex flex-column flex-md-row ">
                                                        <div
                                                          style={{ flex: "3" }}
                                                        >
                                                          <div className="shipment-details-text-p d-flex gap-2 justify-content-between justify-content-md-start">
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Weight
                                                              </p>
                                                              :
                                                              <p className="d-flex gap-1 bulk-description-text">
                                                                <p>
                                                                  {
                                                                    bulkData?.parcel_weight
                                                                  }
                                                                </p>{" "}
                                                                <p>
                                                                  {bulkData?.parcel_weight_measure
                                                                    ? bulkData?.parcel_weight_measure
                                                                    : "LBS"}
                                                                </p>
                                                              </p>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Length
                                                              </p>
                                                              :
                                                              <p className="d-flex bulk-description-text">
                                                                <p>
                                                                  {
                                                                    bulkData?.parcel_length
                                                                  }
                                                                </p>{" "}
                                                                <p>
                                                                  &nbsp;
                                                                  {bulkData?.parcel_dimension_in
                                                                    ? bulkData?.parcel_dimension_in
                                                                    : "IN"}
                                                                </p>
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className="d-flex gap-4 justify-content-between justify-content-md-start">
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Width
                                                              </p>
                                                              :
                                                              <p className="d-flex bulk-description-text">
                                                                <p>
                                                                  {
                                                                    bulkData?.parcel_width
                                                                  }
                                                                </p>{" "}
                                                                <p>
                                                                  &nbsp;
                                                                  {bulkData?.parcel_dimension_in
                                                                    ? bulkData?.parcel_dimension_in
                                                                    : "IN"}
                                                                </p>
                                                              </p>
                                                            </div>
                                                            <div className="d-flex gap-2">
                                                              <p className="bulk-head-text">
                                                                Height
                                                              </p>
                                                              :
                                                              <p className="d-flex bulk-description-text">
                                                                <p>
                                                                  {
                                                                    bulkData?.parcel_height
                                                                  }
                                                                </p>{" "}
                                                                <p>
                                                                  &nbsp;
                                                                  {bulkData?.parcel_dimension_in
                                                                    ? bulkData?.parcel_dimension_in
                                                                    : "IN"}
                                                                </p>
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div className="d-flex gap-2">
                                                            <p className="bulk-head-text">
                                                              {" "}
                                                              Parcel Description
                                                            </p>
                                                            :
                                                            <p className="bulk-description-text">
                                                              {
                                                                bulkData?.parcel_description
                                                              }
                                                            </p>
                                                          </div>
                                                          <div className="d-flex gap-2">
                                                            <p className="bulk-head-text">
                                                              Value
                                                            </p>
                                                            :
                                                            <p className="bulk-description-text">
                                                              {formatPrice(
                                                                bulkData?.parcel_value
                                                              )}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div className="d-none d-md-flex">
                                                          <img
                                                            style={{
                                                              width: "30px",
                                                              height: "30px",
                                                            }}
                                                            className=""
                                                            alt="icon"
                                                            src={`${window.location.origin}/assets/address-icons/excelicon.svg`}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex w-100 ">
                                                    <div
                                                      className=""
                                                      style={{ flex: "3" }}
                                                    >
                                                      {bulkData?.cart_details &&
                                                        bulkData?.orderdetail_id <
                                                        1 && (
                                                          <>
                                                            <div className="d-flex gap-3">
                                                              <div className="shipment-details-text-p d-flex gap-1">
                                                                <div className=" ">
                                                                  <img
                                                                    style={{
                                                                      width:
                                                                        "30px",
                                                                      height:
                                                                        "30px",
                                                                    }}
                                                                    className=""
                                                                    alt="icon"
                                                                    src={
                                                                      bulkData
                                                                        ?.cart_details
                                                                        ?.courier
                                                                        ?.courier_image
                                                                    }
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1">
                                                                <p className="bulk-head-text">
                                                                  {
                                                                    bulkData
                                                                      ?.cart_details
                                                                      ?.courier_service_name
                                                                  }{" "}
                                                                  :
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(
                                                                      bulkData
                                                                        ?.cart_details
                                                                        ?.base_price
                                                                    )}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1">
                                                                <p className="bulk-head-text">
                                                                  Collection  Charge :
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(bulkData?.cart_details?.collection_charge)}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div className="d-flex gap-3">
                                                              {bulkData?.cart_details?.is_enhanced_cover === "Yes" && bulkData?.cart_details?.enhanced_cover_cost > 0 && (
                                                                <>
                                                                  <div className="shipment-details-text-p d-flex gap-1">
                                                                    <p className="bulk-head-text">
                                                                      Insurance :
                                                                    </p>{" "}
                                                                    <p className="d-flex gap-1 bulk-description-text">
                                                                      <p>
                                                                        {/* {bulkData?.cart_details?.is_enhanced_cover === "Yes" ? ` ${formatPrice(bulkData?.cart_details?.enhanced_cover_cost)} ` : ` ${formatPrice("2")} `} */}
                                                                        {bulkData?.cart_details?.is_enhanced_cover === "Yes" && bulkData?.cart_details?.enhanced_cover_cost > 0 && formatPrice(bulkData?.cart_details?.enhanced_cover_cost)}
                                                                      </p>
                                                                    </p>
                                                                  </div>
                                                                </>
                                                              )}
                                                              <div className="shipment-details-text-p d-flex gap-1">
                                                                <p className="bulk-head-text">
                                                                  Total :
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {formatPrice(
                                                                      bulkData
                                                                        ?.cart_details
                                                                        ?.total
                                                                    )}
                                                                  </p>
                                                                </p>
                                                              </div>
                                                              <div className="shipment-details-text-p d-flex gap-1">
                                                                <p className="bulk-head-text">
                                                                  Collection
                                                                  Date :
                                                                </p>{" "}
                                                                <p className="d-flex gap-1 bulk-description-text">
                                                                  <p>
                                                                    {
                                                                      bulkData
                                                                        ?.cart_details
                                                                        ?.collection_date
                                                                    }
                                                                  </p>
                                                                </p>
                                                              </div>
                                                            </div>
                                                          </>
                                                        )}
                                                    </div>
                                                    <div
                                                      className=" d-flex justify-content-end "
                                                      style={{ flex: "2" }}
                                                    >
                                                      <div className=" d-flex gap-2  justify-content-start justify-content-sm-end w-100 ">
                                                        <div className=" ">
                                                          <Button
                                                            className={` w-100 text-nowrap bulk-button`}
                                                            variant="outlined"
                                                            onClick={() =>
                                                              handleDelete(
                                                                bulkData,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            {isbulkorderDeleteLoading &&
                                                              deleteId ===
                                                              formData[index]
                                                                ?.id ? (
                                                              <>
                                                                <Spinner
                                                                  animation="border"
                                                                  size="sm"
                                                                />{" "}
                                                              </>
                                                            ) : (
                                                              <>
                                                                <img
                                                                  style={{
                                                                    width:
                                                                      "14px",
                                                                    height:
                                                                      "14px",
                                                                  }}
                                                                  className="pointer-icon me-2"
                                                                  alt="icon"
                                                                  src={`${window.location.origin}/assets/address-icons/deletebulk.svg`}
                                                                />
                                                                Delete
                                                              </>
                                                            )}
                                                          </Button>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Form>
                                            </Card.Body>
                                          </div>
                                        </Card>
                                      )
                                    )}
                                    {filteredDataCountcomplete >=
                                      itemsPerPage && (
                                        <div className=" d-flex justify-content-end">
                                          <Pagination
                                            count={Math.ceil(
                                              filteredDataCountcomplete /
                                              itemsPerPage
                                            )}
                                            variant="outlined"
                                            shape="rounded"
                                            page={currentPagecomplete}
                                            onChange={(event, page) => {
                                              handlePageChangecomplete(
                                                event,
                                                page
                                              );
                                              scrollToTop();
                                            }}
                                          />
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <h6 className="text-center">
                                      Data not found...!
                                    </h6>
                                  </>
                                )}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>

                        <Modal
                          show={showModal}
                          onHide={handleCloseModal}
                          backdrop="static"
                          keyboard={false}
                          className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"
                        >
                          <Modal.Header
                            closeButton
                            className="closebutton d-flex justify-content-end"
                            style={{ backgroundColor: "white" }}
                          ></Modal.Header>
                          <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                            <div className="user-modal-wallet w-100 h-100" style={{ backgroundColor: "white" }} >
                              <div className="px-4 pb-4">
                                <div className="d-flex flex-column gap-2">
                                  <BulkOrderPreviewModal
                                  serviceData={newData?.find((item) => item.channel_order_id === selectedBulkData?.id)}
                                    filterData={filterData}
                                    handleclear={handleclear}
                                    setAllerrors={setAllerrors}
                                    userAddressList={collectionAddress}
                                    bulkData={selectedBulkData}
                                    setShowModal={setShowModal}
                                    setShowModalService={setShowModalService}
                                    userData={userData}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={showAddressModal}
                          onHide={handleCloseModal}
                          backdrop="static"
                          keyboard={false}
                          className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"
                        >
                          <Modal.Header
                            closeButton
                            className="closebutton d-flex justify-content-end"
                            style={{ backgroundColor: "white" }}
                          ></Modal.Header>
                          <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                            <div className="user-modal-wallet w-100 h-100" style={{ backgroundColor: "white" }}     >
                              <div className="px-4 pb-4">
                                <div className="d-flex flex-column gap-2">
                                  {/* <BulkOrderPreviewModal bulkData={selectedBulkData} setShowModal={setShowModal} setShowModalService={setShowModalService} /> */}
                                  <AddressCard
                                    userId={useriD}
                                    country_Name={country_Name}
                                    bulkUserAddRow={true}
                                    setshowAddressModal={setshowAddressModal}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                        <Modal
                          show={showpickupdrop}
                          onHide={handleCloseModal}
                          backdrop="static"
                          keyboard={false}
                          className="user-wallet-payment-modal  user-wallet-payment-modal d-flex justify-content-center align-items-center"
                        >
                          <Modal.Header
                            closeButton
                            className="closebutton d-flex justify-content-end"
                            style={{ backgroundColor: "white" }}
                          ></Modal.Header>
                          <Modal.Body className="p-0 overflow-auto d-flex align-items-start">
                            <div className="user-modal-wallet w-100 h-100" style={{ backgroundColor: "white" }}   >
                              <div className="px-4 pb-4">
                                <div className="d-flex flex-column gap-2">
                                  {/* <BulkOrderPickupDrop /> */}
                                  <SelectDateTime
                                    // serviceData={service_Data}
                                    // shipContent={shipContent}
                                    selectOpen={showpickupdrop}
                                    setSelectOpen={setshowpickupdrop}
                                    collectionDates={collectionDates}
                                    setCollectionDates={setCollectionDates}
                                    dropupDates={dropupDates}
                                    setDropupDates={setDropupDates}
                                    collectionValues={collectionValues}
                                    setCollectionValues={setCollectionValues}
                                    selectedCollectionService={
                                      selectedCollectionService
                                    }
                                    setPickupValidation={setPickupValidation}
                                    setSelectedCollectionService={
                                      setSelectedCollectionService
                                    }
                                    setcollectionlocation={setcollectionlocation}
                                    collectionlocation={collectionlocation}
                                    // dropDates={dropDates}
                                    // pickupDates={pickupLabels}
                                    // dropdates={dropOffLabels}
                                    user_id={useriD}
                                  />
                                  {showpickupdrop && (
                                    <Button
                                      className="address_btn border height-44"
                                      onClick={
                                        !continueBulk
                                          ? () => handleContinueClick()
                                          : null
                                      }
                                      variant="link"
                                      disabled={
                                        dropoffdata?.error?.error &&
                                        selectedCollectionService === "Drop"
                                      }
                                    >
                                      {continueBulk ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        "Continue"
                                      )}
                                    </Button>
                                  )}
                                  {isinsuranceopen && (
                                    <>
                                      <p className="d-flex flex-column gap-2">
                                        <p>
                                          <b>Parcel Content</b> :{" "}
                                          {selectedBulkData?.parcel_description}
                                        </p>
                                        <p>
                                          <b>Value of Parcel</b> :{" "}
                                          {formatPrice(
                                            selectedBulkData?.parcel_value
                                          )}
                                        </p>
                                      </p>
                                      <p className="d-flex justify-content-between flex-wrap">
                                        <p className="d-inline-flex">
                                          <b> Insurance Cost : </b>&nbsp;
                                          {!bookServiceLoad || !bookserviceloading? <span>
                                            {
                                              shipmentDataNew?.cart_details
                                                ?.insurance_cost === "0.00" ||
                                                !shipmentDataNew?.cart_details
                                                  ?.insurance_cost
                                                ? "Free"
                                                : formatPrice(
                                                  shipmentDataNew
                                                    ?.cart_details
                                                    ?.insurance_cost
                                                )
                                              // `$${shipmentDataNew?.cart_details?.insurance_cost}`
                                            }
                                          </span> : <>loading</>}
                                        </p>
                                        <div className="radiobtnAddress radio-parcel d-flex flex-row align-items-center gap-2 mt-2 mt-md-0 ">
                                          <p className="lh-1 ">
                                            Do you want to add an insurance?{" "}
                                          </p>
                                          <div className="radio-holderaddress row">
                                            <div className="col custom_radio  d-flex gap-2">
                                              <input
                                                className="radio-check-my-ship-details d-flex align-items-center gap-2"
                                                type="radio"
                                                id="yesRadio"
                                                name="insurance"
                                                value="Yes"
                                                checked={
                                                  selectedRadio === "Yes"
                                                }
                                                onChange={(e) => {
                                                  handleRadioChange(e);
                                                }}
                                              />
                                              <label for="yesRadio">Yes</label>

                                              {/* <span className="ms-1 me-3">Yes</span> */}
                                              <input
                                                type="radio"
                                                className="radio-check-my-ship-details d-flex align-items-center gap-2 "
                                                id="noRadio"
                                                name="insurance"
                                                value="No"
                                                checked={selectedRadio === "No"}
                                                onChange={(e) => {
                                                  handleRadioChange(e);
                                                }}
                                              />
                                              <label for="noRadio">No</label>
                                            </div>
                                          </div>
                                        </div>
                                      </p>
                                      {bookservicedata?.cart_details
                                        ?.collection_country_id !==
                                        bookservicedata?.cart_details
                                          ?.delivery_country_id && (
                                          <>
                                            <AccordianCustomDetails
                                              dropdowndata={dropdowndata}
                                              vatdata={vatdata}
                                              setVatdata={setVatdata}
                                            />
                                          </>
                                        )}
                                      {(bookserviceError ||
                                        bookserviceErrors) && (
                                          <>
                                            <p className="text-danger error4">
                                              {bookserviceError || bookserviceErrors}
                                            </p>
                                          </>
                                        )}
                                      <Button
                                        className="primarybtn btn height-44"
                                        onClick={
                                          !submitBulk
                                            ? () => finalsubmit()
                                            : null
                                        }
                                      >
                                        {submitBulk ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          "Submit"
                                        )}
                                      </Button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                    <div style={{ flex: "2" }} className="">
                      <div className="d-flex flex-column gap-3 bulk_orderCollect">
                        <Card className="card-border">
                          <Card.Body className="p-2 p-sm-3">
                            <div className="d-flex flex-column gap-2 ">
                              <div className="d-flex d-flex d-lg-flex justify-content-between align-items-center gap-2">
                                <div className="">
                                  <p className="collectoinh5 blue-text">
                                    <span>Collection Details</span>
                                  </p>
                                </div>
                                <div className="hrlinedivdelivery d-none d-sm-block">
                                  <hr className="hrlinedelivery" />
                                </div>
                                <div className="">
                                  <Button className="address_btn border text-nowrap w-100" variant="link" onClick={() => { setopenaddressmodel() }}>
                                    {!hasDefaultAddress ? (
                                      <>Add Address</>
                                    ) : (
                                      <>Change Address</>
                                    )}
                                  </Button>
                                </div>
                              </div>
                              {userAddressList &&
                                userAddressList?.map((i, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {i?.is_default === "Yes" && (
                                        <div className="">
                                          <Card.Text>
                                            <p
                                              style={{
                                                margin: "0px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {`${i.first_name} ${i.last_name
                                                } ${i?.contact_no !== null
                                                  ? i?.contact_no
                                                  : ""
                                                }`}
                                            </p>
                                            <p className="m-0">
                                              {`${i.address_1}, ${i.city}, ${i?.state} - ${i?.postcode}`}
                                            </p>
                                          </Card.Text>
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              {!hasDefaultAddress && (

                                <>
                                {userAddressListloading ?<>
                                  <Skeleton animation="wave" variant="text" />
                                  <Skeleton animation="wave" variant="text" />
                                </>:
                                <div>
                                    <p className="text-danger cursor-pointer mb-3">
                                      Add or Select default Your Collection
                                      Address
                                    </p>
                                  </div>
                                }</>
                              )}
                            </div>
                          </Card.Body>
                        </Card>

                        {channelId && (
                          <>
                            {cartData?.total > 0 && (
                              <>
                                <Card className="card-border">
                                  <Card.Body>
                                    <div className="d-flex d-none d-lg-flex align-items-center gap-3">
                                      <div className="">
                                        <p className="collectoinh5 blue-text">
                                          <span>
                                            Price Details{" "}
                                            {`( ${cartData?.cart_details?.length} )`}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="hrlinedivdelivery">
                                        <hr className="hrlinedelivery" />
                                      </div>
                                    </div>

                                    <div>
                                      <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                        <div>
                                          <p className="mb-0 paragraph2 order-confirm-p-2">
                                            Sub Total
                                          </p>
                                        </div>
                                        <span>
                                          <p className="mb-0 paragraph2 order-confirm-p-2">
                                            <b>{formatPrice(cartData?.subtotal)}</b>
                                          </p>
                                        </span>
                                      </li>
                                      {cartData?.pickup_charge > 0 && (
                                        <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                                          <div>
                                            <p className="mb-0 paragraph2 order-confirm-p-2 text-nowrap">
                                              Pickup Charge{" "}
                                            </p>
                                          </div>
                                          <div className=" d-flex justify-content-end insurance ">
                                            <span>
                                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                                <b>
                                                  {formatPrice(
                                                    cartData?.pickup_charge
                                                  )}
                                                </b>
                                              </p>
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                      {/* <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0">
                                     <div>
                                       <p className="mb-0 paragraph2 order-confirm-p-2">
                                         TAX{" "}
                                       </p>
                                     </div>
                                     <span>
                                       <p className="mb-0 paragraph2 order-confirm-p-2">
                                         <b>{formatPrice("0.00")}</b>
                                       </p>
                                     </span>
                                   </li> */}
                                      {(cartData?.additional_handling_charges) > 0 && (
                                        <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
                                          <div>
                                            <p className="mb-0">Additional Handling Charges</p>
                                          </div>
                                          <span>
                                            <p className="mb-0">
                                              {formatPrice(cartData?.additional_handling_charges)}
                                            </p>
                                          </span>
                                        </li>
                                      )}
                                      {cartData?.residential_address_surcharge > 0 && (
                                        // <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 ">
                                        //   <div>
                                        //     <p className="mb-0 paragraph2">Residential Address Surcharge</p>
                                        //   </div>
                                        //   <span>
                                        //     <p className="mb-0 paragraph2">
                                        //       {formatPrice(cartData?.residential_address_surcharge)}
                                        //     </p>
                                        //   </span>
                                        // </li>
                                        <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                                          <div>
                                            <p className="mb-0 paragraph2 order-confirm-p-2 text-nowrap">
                                            Residential Address Surcharge
                                            </p>
                                          </div>
                                          <div className=" d-flex justify-content-end insurance ">
                                            <span>
                                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                                <b>
                                                  {formatPrice(cartData?.residential_address_surcharge
                                                  )}
                                                </b>
                                              </p>
                                            </span>
                                          </div>
                                        </li>
                                      )}

                                      {Number(cartData?.total_insurance) > 0 && (
                                        <li className="list-group-item d-flex  align-items-center border-0 px-0">
                                          <div className="d-flex">
                                            <div>
                                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                                Insurance
                                              </p>
                                            </div>
                                          </div>
                                          <div className=" d-flex justify-content-end insurance">
                                            <span>
                                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                                <b>
                                                  {cartData?.total_insurance > 0 &&
                                                    formatPrice(
                                                      cartData?.total_insurance
                                                    )}
                                                </b>
                                              </p>
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                      {cartData?.discount_amount > 0 && (
                                        <li className=" d-flex justify-content-between align-items-center border-0 px-0">
                                          <div>
                                            <p className="mb-0 paragraph2 order-confirm-p-2">
                                              Discount{" "}
                                            </p>
                                          </div>
                                          <span className="px-2">
                                            <div
                                              style={{ color: "#113468" }}
                                              className="discount-label-payment"
                                            >
                                              {" "}
                                            </div>
                                          </span>
                                          <div className=" d-flex justify-content-end insurance ">
                                            <span>
                                              <p className="mb-0 paragraph2 order-confirm-p-2">
                                                <b>
                                                  -{" "}
                                                  {formatPrice(
                                                    cartData?.discount_amount
                                                  )}
                                                </b>
                                              </p>
                                            </span>
                                          </div>
                                        </li>
                                      )}
                                      <div>
                                        <hr className="doted-hr m-1" />
                                      </div>
                                      <div>
                                        <li className="d-flex justify-content-between align-items-center border-0 px-0">
                                          <div>
                                            <span>
                                              <p className="paragraph1 mb-0 p-0">
                                                <b>Total</b>
                                              </p>
                                            </span>
                                          </div>
                                          <div>
                                            <span>
                                              <p className="paragraph1 mb-0">
                                                {/* <b>{formatPrice(cartData?.discount_amount)}</b> */}
                                                <b>
                                                  {formatPrice(cartData?.total)}
                                                </b>
                                              </p>
                                            </span>
                                          </div>
                                        </li>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </Card>
                                <Button
                                  disabled={cartData?.discount_amount === "0"}
                                  className="primarybtn btn height-40 w-100"
                                >
                                  <Link
                                    style={{
                                      color: "white",
                                      textDecoration: "none",
                                    }}
                                    className="w-100"
                                    to={"/cartpreview"}
                                  >
                                    Go to Cart
                                  </Link>
                                </Button>
                              </>
                            )}
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                </>
              </div>
            </>
          ) : (
            <>
              {isbulkorderListLoading || iscartloading ? (
                <>
                  <BulkOrderPreviewSkeleton />
                </>
              ) : (
                <>
                  <div
                    className="div1 w-100 d-flex gap-3 align-items-center justify-content-center"
                    style={{ flex: 2, height: "70vh", flexDirection: "column" }}
                  >
                    <h1>Bulk Order Data Not Found....!</h1>
                    <p>if you want to add bulk data ? </p>
                    <Button className="primarybtn btn height-40 px-4">
                      <Link
                        style={{ color: "white", textDecoration: "none" }}
                        className="w-100"
                        to={"/bulk-orders"}
                      >
                        Add Bulk data
                      </Link>
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default BulkOrderPreview;
