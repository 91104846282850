import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSlidingDetails,
  fetchHerosectionDetails,
  fetchlandingServices,
  fetchFaqsDetails,
  fetchCountryDetails,
  fetchPostalDetails,
  fetchPostalDetailsTo,
  fetchServiceTitle,
  fetchShippingRates,
  fetchShippingRatescoupon,
  deletecartdetail,
  fetchfooterdata,
  fetchStateName,
} from "./landingThunk";
const initialState = {
  error: "",

  rates_error: "",
};
export const landingDetailsSlice = createSlice({
  name: "landingDetailsSlice",
  initialState: {
    landingSliderDetails: [],
    landingHeroDetails: [],
    landingServicesDetails: [],
    landingFaqsDetails: [],
    landingShipServices: [],
    countryDeatils: [],
    stateName: [],
    countryPostal: [],
    countryPostalTo: [],
    homepageserviceTitle: [],
    footerData: [],
    deletecard: [],
    isHeroLoading: false,
    isSliderLogin: false,
    isPostalLoading: false,
    isPostalToLoading: false,
    isCountryLoading: false,
    isStateLoading: false,
    isFooterLoading: false,
    isDeleteCardLoading: false,
    isLoading: {
      isHeroLoading: false,
      isShiippingLoading: false,
      isServicesLoading: false,
      iscouponcodeLoading: false,
      faqLoading: false
    },
  },
  reducers: {
    resetErrors: (state) => {
      return initialState.error;
    },
  },
  extraReducers: {
    [fetchSlidingDetails.fulfilled]: (state, { payload }) => {
      return { ...state, landingSliderDetails: payload, isSliderLogin: false };
    },
    [fetchSlidingDetails.pending]: (state) => {
      return { ...state, landingSliderDetails: [], isSliderLogin: true };
    },
    [fetchSlidingDetails.rejected]: (state, { payload }) => {
      if (payload && payload.errors) {
        return { landingSliderDetails: payload?.errors, isSliderLogin: false };
      } else {
        return state.error
        //  = "An error occurred during Signup";
      }
    },

    [fetchHerosectionDetails.fulfilled]: (state, { payload }) => {
      return { ...state, landingHeroDetails: payload, isHeroLoading: false };
    },
    [fetchHerosectionDetails.pending]: (state) => {
      return { ...state, landingHeroDetails: [], isHeroLoading: true };
    },
    [fetchHerosectionDetails.rejected]: (state, { payload }) => {
      if (payload && payload.errors) {
        return { landingHeroDetails: payload.errors, isHeroLoading: false };
      } else {
        return state.error
        //  = "An error occurred during Signup";
      }
    },
    [fetchlandingServices.fulfilled]: (state, { payload }) => {
      return { ...state, landingServicesDetails: payload };
    },
    [fetchServiceTitle.fulfilled]: (state, { payload }) => {
      return { ...state, homepageserviceTitle: payload };
    },
    [fetchFaqsDetails.fulfilled]: (state, { payload }) => {
      return { ...state, landingFaqsDetails: payload, faqLoading: false };
    },
    [fetchFaqsDetails.pending]: (state, { payload }) => {
      return { ...state, faqLoading: true };
    },
    [fetchCountryDetails.fulfilled]: (state, { payload }) => {
      return { ...state, countryDeatils: payload, isCountryLoading: false };
    },
    [fetchCountryDetails.pending]: (state) => {
      return { ...state, countryDeatils: [], isCountryLoading: true };
    },
    [fetchCountryDetails.rejected]: (state) => {
      return {
        ...state,
        isCountryLoading: false,
      };
    },
    [fetchStateName.fulfilled]: (state, { payload }) => {
      return { ...state, stateName: payload, isStateLoading: false };
    },
    [fetchStateName.pending]: (state) => {
      return { ...state, stateName: [], isStateLoading: true };
    },
    [fetchStateName.rejected]: (state) => {
      return {
        ...state,
        isStateLoading: false,
      };
    },
    [fetchPostalDetails.fulfilled]: (state, { payload }) => {
      return { ...state, countryPostal: payload, isPostalLoading: false };
    },
    [fetchPostalDetails.pending]: (state) => {
      return {
        ...state,
        countryPostal: [],
        isPostalLoading: true,
      };
    },
    [fetchPostalDetails.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        isPostalLoading: false,
      };
    },
    [fetchPostalDetailsTo.fulfilled]: (state, { payload }) => {
      return { ...state, countryPostalTo: payload, isPostalToLoading: false, };
    },
    [fetchPostalDetailsTo.pending]: (state) => {
      return {
        ...state,
        countryPostalTo: [],
        isPostalToLoading: true,
      };
    },
    [fetchPostalDetailsTo.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        isPostalToLoading: false,
      };
    },
    [fetchShippingRates.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        landingShipServices: payload,
        isLoading: { isShippingLoading: false, isServicesLoading: false },
      };
    },
    [fetchShippingRates.pending]: (state) => {
      return {
        ...state,
        landingShipServices: [],
        isLoading: { isShippingLoading: true, isServicesLoading: true },
      };
    },
    [fetchShippingRates.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        rates_error: payload?.data?.serviceerrors,
        // measurement_error:payload?data?.error,
        error: payload?.data?.errors,
        isLoading: { isShippingLoading: false, isServicesLoading: false },
        serverError: payload
      };
    },

    [fetchShippingRatescoupon.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        landingShipServices: payload,
        isLoading: { iscouponcodeLoading: false },
      };
    },
    [fetchShippingRatescoupon.pending]: (state) => {
      return {
        ...state,
        landingShipServices: [],
        isLoading: { iscouponcodeLoading: true },
      };
    },
    [fetchShippingRatescoupon.rejected]: (state, { payload, errors }) => {
      return {
        ...state,
        rates_error: payload?.data?.serviceerrors,
        error: payload?.data?.errors,
        isLoading: { iscouponcodeLoading: false },
        serverError: payload
      };
    },
    [deletecartdetail.fulfilled]: (state, { payload }) => {
      return { ...state, deletecard: payload, isDeleteCardLoading: false };
    },
    [deletecartdetail.pending]: (state) => {
      return { ...state, deletecard: [], isDeleteCardLoading: true };
    },
    [deletecartdetail.rejected]: (state) => {
      return {
        ...state,
        isDeleteCardLoading: false,
      };
    },
    [fetchfooterdata.fulfilled]: (state, { payload }) => {
      return { ...state, footerData: payload, isFooterLoading: false };
    },
    [fetchfooterdata.pending]: (state) => {
      return { ...state, footerData: [], isFooterLoading: true };
    },
    [fetchfooterdata.rejected]: (state) => {
      return {
        ...state,
        isFooterLoading: false,
      };
    },
  },
});

export const { resetErrors } = landingDetailsSlice.actions;

export default landingDetailsSlice.reducer;
