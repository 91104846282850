import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../plugins/axios";
import CustomAlert from "../../components/common/CustomAlert";

export const fetchUserAddressDetails = createAsyncThunk(
  "ladding/fetchUserAddressDetails",

  async (addressValidations, { rejectWithValue }) => {

    try {
      const response = await axiosInstance.post(`/customer-address/${addressValidations?.store}`, addressValidations.addressData);

      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const fetchAddressDetails = createAsyncThunk(
  "ladding/fetchAddressDetails",
  async (addressValidations, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/service-address-validation",
        addressValidations
      );
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const bulkAddress = createAsyncThunk(
  "ladding/bulkAddress",
  async (bulk_address, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/customer-address/${bulk_address?.store}`, bulk_address?.formData);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else if (error?.response) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue("An error occurred while processing your request.");
    }
  }
);

// export const editAddressDetails = createAsyncThunk(
//   "ladding/editAddressDetails",
//   async (addressValidations, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post(
//         "/update-customer-address",
//         addressValidations
//       );
//       return response.data;
//     } catch (errors) {
//       if (errors.response) {
//         return rejectWithValue(errors.response.data);
//       }
//       return rejectWithValue(
//         "An error occurred while processing your request."
//       );
//     }
//   }
// );


export const fetchAddressList = createAsyncThunk(
  "ladding/fetchAddressList",
  async (addressList, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/customer-address/list/${addressList?.customer_id}`, addressList);
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const deleteAddress = createAsyncThunk(
  "ladding/deleteAddress",
  async (deleteAddress, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/delete-customer-address",
        deleteAddress
      );
      return response.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors.response) {
          return rejectWithValue(errors.response.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const fetchUserCardDetails = createAsyncThunk(
  "ladding/fetchUserCardDetails",
  async (customer_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/customer-card/list/ ${customer_id.customer_id}`
        // ${customer_id.customer_id}
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === 401 && error?.response?.data?.error === "Invalid token" && error?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        return rejectWithValue(error.message);
    }
  }
);

export const makeDefaultUserCard = createAsyncThunk(
  "ladding/makeDefaultUserCard",
  async (default_Card, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `update-default-stripe-card`,
        default_Card
      );
      return response?.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors?.response) {
          return rejectWithValue(errors?.response?.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);

export const deleteUserCard = createAsyncThunk(
  "ladding/deleteUserCard",
  async (delete_Card, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `delete-stripe-card`,
        delete_Card
      );
      return response?.data;
    } catch (errors) {
      if (errors?.response?.data?.code === 401 && errors?.response?.data?.error === "Invalid token" && errors?.response?.data?.status === "fail") {
        localStorage.removeItem("user");
        localStorage.removeItem("cartdetail_id");
        localStorage.removeItem("cart_id");
        localStorage.removeItem("token");
        CustomAlert('Please log in and try again.')

        localStorage.clear()
      }
      else
        if (errors?.response) {
          return rejectWithValue(errors?.response?.data);
        }
      return rejectWithValue(
        "An error occurred while processing your request."
      );
    }
  }
);
