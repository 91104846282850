import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUserCard,
  fetchAddressDetails,
  fetchAddressList,
  fetchUserCardDetails,
  makeDefaultUserCard,
  deleteAddress,
  fetchUserAddressDetails
} from "./adressThunk";

const initialState = {
  error: "",
  validationError: "",
  profileFieldError: "",
  colFieldError: "",
  delFieldError: "",
  collValidationError: "",
  dellValidationError: "",

};
export const addressDetailsSlice = createSlice({
  name: "addressDetailsSlice",
  initialState: {
    addresslice: [],
    addressList: [],
    userCard: [],
    default_Card: [],
    deleteAddress: [],
    delete_Card: [],
    isCardLoading: false,
    isSaveLoading: false,
    isDeleteAddLoadig: false,
    isAddressLoading: false,
    isMakeDefaultLoading: false,
    isDeleteCardLoading: false
  },

  reducers: {
    resetErrors: (state) => {
      return {
        ...state,
        error: initialState.error,
        validationError: initialState.validationError,
        profileFieldError: initialState.profileFieldError,
        colFieldError: initialState.colFieldError,
        delFieldError: initialState.delFieldError,
        collValidationError: initialState.collValidationError,
        dellValidationError: initialState.dellValidationError,
      };
    },

    resetErrorsAddress: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchAddressDetails.fulfilled]: (state, { payload }) => {
      return { ...state, addresslice: payload, isSaveLoading: false };
    },
    [fetchAddressDetails.pending]: (state) => {
      return { ...state, addresslice: [], isSaveLoading: true };
    },
    [fetchAddressDetails.rejected]: (state, { payload, error }) => {
      return {
        isSaveLoading: false,
        colFieldError: payload?.data?.collectionerror,
        delFieldError: payload?.data?.deliveryerror,
        collValidationError: payload?.data?.collectionvalidationerror,
        dellValidationError: payload?.data?.deliveryvalidationerror,
        profileFieldError: payload?.data?.profileaddresserror,
      };
    },

    [fetchUserAddressDetails.fulfilled]: (state, { payload }) => {
      return { ...state, addresslice: payload, isSaveLoading: false };
    },
    [fetchUserAddressDetails.pending]: (state) => {
      return { ...state, addresslice: [], isSaveLoading: true };
    },
    [fetchUserAddressDetails.rejected]: (state, { payload, error }) => {
      return {
        isSaveLoading: false,
        colFieldError: payload?.data?.collectionerror,
        delFieldError: payload?.data?.deliveryerror,
        collValidationError: payload?.data?.collectionvalidationerror,
        dellValidationError: payload?.data?.deliveryvalidationerror,
        profileFieldError: payload?.data?.profileaddresserror,
      };
    },

    [fetchAddressList.fulfilled]: (state, { payload }) => {
      return { ...state, addressList: payload, isAddressLoading: false };
    },
    [fetchAddressList.pending]: (state) => {
      return { ...state, addressList: [], isAddressLoading: true };
    },
    [fetchAddressList.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        error: payload.error,
        isAddressLoading: false,
      };
    },
    [makeDefaultUserCard.fulfilled]: (state, { payload }) => {
      return { ...state, default_Card: payload, isMakeDefaultLoading: false };
    },
    [makeDefaultUserCard.pending]: (state) => {
      return { ...state, default_Card: [], isMakeDefaultLoading: true };
    },
    [makeDefaultUserCard.rejected]: (state, { payload, error }) => {
      return {
        error: payload.error, isMakeDefaultLoading: false
      };
    },
    [deleteUserCard.fulfilled]: (state, { payload }) => {
      return { ...state, delete_Card: payload, isDeleteCardLoading: false };
    },
    [deleteUserCard.pending]: (state) => {
      return { ...state, delete_Card: [], isDeleteCardLoading: true };
    },
    [deleteUserCard.rejected]: (state, { payload, error }) => {
      return {
        error: payload.error,
      };
    },
    [deleteAddress.fulfilled]: (state, { payload }) => {
      return { ...state, deleteAddress: payload, isDeleteAddLoadig: false };
    },
    [deleteAddress.pending]: (state) => {
      return { ...state, deleteAddress: [], isDeleteAddLoadig: true };
    },
    [deleteAddress.rejected]: (state, { payload, error }) => {
      return {
        error: payload.error,
        isDeleteAddLoadig: false
      };
    },

    [fetchUserCardDetails.fulfilled]: (state, { payload }) => {
      return { ...state, userCard: payload, isCardLoading: false };
    },
    [fetchUserCardDetails.pending]: (state) => {
      return {
        ...state, isCardLoading: true
      };
    },
    [fetchUserCardDetails.rejected]: (state, { payload, error }) => {
      return {
        ...state,
        isCardLoading: false
      };
    },
  },
});

export const { resetErrors, resetErrorsAddress } = addressDetailsSlice.actions;
export default addressDetailsSlice.reducer;
