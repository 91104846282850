import { useNavigate } from 'react-router-dom';

let navigateFunction;

export const setNavigateFunction = (navigate) => {
    navigateFunction = navigate;
};

export const navigateToHome = () => {
    // if (navigateFunction) {
    //     navigateFunction('/');
    // }
    
};
