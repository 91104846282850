import React, { createContext, useContext, useState } from 'react';
const ModelContext = createContext();
export const ModelProvider = ({ children }) => {
  const [modalname, setModalname] = useState("");
  const [showFailedPaymentModal, setShowFailedPaymentModal] = useState(false);
  const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);
  const [activeSection, setActiveSection] = useState("myorders");
  const [activeSectionfinal, setActiveSectionfinal] = useState("myorders")
  return (
    <ModelContext.Provider value={{ modalname, setModalname, showAuthenticationModal, setShowAuthenticationModal, showFailedPaymentModal, setShowFailedPaymentModal, activeSection, setActiveSection, activeSectionfinal, setActiveSectionfinal }}>
      {children}
    </ModelContext.Provider>
  );
};

export const useModel = () => {
  const context = useContext(ModelContext);
  if (!context) {
    throw new Error('useModel must be used within a ModelProvider');
  }
  return context;
};
