import { createSlice } from "@reduxjs/toolkit";
import { trackshipment, trackshipmentsingle } from "./TrackshipThunk";

const initialState = {
    error: "",
};
export const TrackShipSlice = createSlice({
    name: "Trackship_details",
    initialState: {
        TrashipData: [],
        TrashipDatasingle: [],
        isTrackshipLoading: false,
        isTrackshipLoadingsingle: false,
    },

    reducers: {
        reseterrortrack: (state) => {
            return initialState;
        },
    },
    extraReducers: {
        [trackshipment.pending]: (state) => {
            return { ...state, TrashipData: [], isTrackshipLoading: true };
        },
        [trackshipment.fulfilled]: (state, { payload }) => {
            return { ...state, TrashipData: payload, isTrackshipLoading: false };
        },
        [trackshipment.rejected]: (state, { payload, error }) => {
            return {
                ...state,
                error: payload?.response?.data?.errors,
                isTrackshipLoading: false
            };
        },
        [trackshipmentsingle.pending]: (state) => {
            return { ...state, TrashipDatasingle: [], isTrackshipLoadingsingle: true };
        },
        [trackshipmentsingle.fulfilled]: (state, { payload }) => {
            return { ...state, TrashipDatasingle: payload, isTrackshipLoadingsingle: false };
        },
        [trackshipmentsingle.rejected]: (state, { payload, error }) => {
            return {
                ...state,
                error: payload?.response?.data?.errors,
                isTrackshipLoadingsingle: false
            };
        },
    }
})
export const { reseterrortrack } = TrackShipSlice.actions;

export default TrackShipSlice.reducer;