import React, { useEffect, useState } from "react";
import { Card, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getparcellabelsize,
  updateparcellabelsize,
} from "../../redux/user_profile/ProfileThunk";
import "../../assets/styles/userProfile/userWallet.css";
import Swal from "sweetalert2";
import { Box, Skeleton } from "@mui/material";

const UserSettings = () => {
  const [data, setData] = useState({});
  const [selectedValues, setSelectedValues] = useState({
    ups: "",
    fedex: "",
  });

  const dispatch = useDispatch();
  const getparcellabelsizeData = useSelector(
    (state) => state?.profileData?.getparcellabelsize
  );
  const getparcellabelsizeDataLoading = useSelector(
    (state) => state?.profileData?.isgetparcellabelsizeLoading
  );
  useEffect(() => {
    dispatch(getparcellabelsize());
  }, [dispatch]);

  useEffect(() => {
    if (getparcellabelsizeData?.data) {
      setData(getparcellabelsizeData.data);
      setSelectedValues({
        ups: getparcellabelsizeData?.ups_parcel_size || "",
        fedex: getparcellabelsizeData?.fedex_parcel_size || "",
      });
    }
  }, [getparcellabelsizeData]);

  const handleSelectionChange = (e, type) => {
    const parcel_size = e.target.value;
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [type]: parcel_size,
    }));
    dispatch(updateparcellabelsize({ type, parcel_size }))
      .unwrap()
      .then((res) => {
        // if (response.statusCode === 200 && response.status === true) {
        Swal.fire({
          toast: true,
          icon: "success",
          title: `Parcel label size updated successfully`,
          animation: true,
          position: "top-right",
          showConfirmButton: false,
          showCloseButton: true,
          timer: 2000,
          timerProgressBar: true,
          customClass: {
            timerProgressBar: "custom-progress-bar",
          },
        });
        // }
      })
      .catch((error) => {
        console.error("change label size update error", error);
      });
  };

  const renderDropdowns = () => {
    return Object.keys(data)?.map((key) => (
      <div key={key} className="user-wallet-type" style={{ flex: "4" }}>
        <label htmlFor={key} style={{ display: "block", marginTop: "15px" }}>
          <p style={{ color: "#222222", fontWeight: 500 }}>
            For {key.toUpperCase()}:
          </p>
        </label>
        <select
          id={key}
          name={key}
          className="px-2 wallet_select card-border is-valid-paper w-100 height-44"
          style={{ borderRadius: "6px", color: "black" }}
          value={selectedValues[key]}
          onChange={(e) => handleSelectionChange(e, key)}
        >
          {data[key].map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    ));
  };

  return (
    <div className="user-changepassword-holder mb-5 h-100">
      <Card className="px-3 py-4 ps-4 h-100" style={{ border: "none" }}>
        <div className="d-flex flex-column gap-4">
          <Row>
            <h5 className="personal-text m-0 mb-3">Setting</h5>
            <hr />
          </Row>
        </div>
        {!getparcellabelsizeDataLoading ? (
          <Card className="card-border">
            <div className="m-3">
              <h5 className="personal-text m-0">Select Download Label Type:</h5>
              {renderDropdowns()}
            </div>
          </Card>
        ) : (
          <div>
            <Card className="card-border" style={{ flex: "3" }}>
              <Box className="p-3 d-flex flex-column gap-4 justify-content-center">
                <Skeleton
                  animation="wave"
                  className="w-50"
                  height="26px"
                  variant="rounded"
                />
                <Skeleton
                  animation="wave"
                  className="w-25"
                  height="20px"
                  variant="rounded"
                />
                <Skeleton
                  animation="wave"
                  className="w-100"
                  height="20px"
                  variant="rounded"
                />
                <Skeleton
                  animation="wave"
                  className="w-25"
                  height="20px"
                  variant="rounded"
                />
                <Skeleton
                  animation="wave"
                  className="w-100"
                  height="20px"
                  variant="rounded"
                />
              </Box>
            </Card>
          </div>
        )}
      </Card>
    </div>
  );
};

export default UserSettings;
