import { createSlice } from "@reduxjs/toolkit";
import {
  CreateCharge, couponCode, labelDownload, orderConfirm, placeOrder,
  removecouponCode,

} from "./PaymentThunk";
const initialState = {
  error: "",
};
export const CreatePayment = createSlice({
  name: "Login_Registration",
  initialState: {
    CreatePayment: [],
    isLoadingPayment: false,
    isorderConfirmLoading: false,
    orderConfirmData: [],
    iscouponCodeLoading: false,
    couponCodeData: [],
    removecouponCodeData: [],
    isremovecouponCodeLoading: false,
    isplaceOrderLoading: false,
    placeOrderData: [],
    islabelDownloadLoading: false,
    labelDownloadData: []

  },
  reducers: {
    resetErrors: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [CreateCharge.fulfilled]: (state, { payload }) => {
      return { ...state, CreatePayment: payload, isLoadingPayment: false };
    },
    [CreateCharge.pending]: (state) => {
      return { ...state, CreatePayment: [], isLoadingPayment: true };
    },
    [CreateCharge.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        isLoadingPayment: false
      };
    },
    [orderConfirm.fulfilled]: (state, { payload }) => {
      return { ...state, orderConfirmData: payload, isorderConfirmLoading: false };
    },
    [orderConfirm.pending]: (state) => {
      return { ...state, orderConfirmData: [], isorderConfirmLoading: true };
    },
    [orderConfirm.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        isorderConfirmLoading: false
      };
    },
    [couponCode.fulfilled]: (state, { payload }) => {
      return { ...state, couponCodeData: payload, iscouponCodeLoading: false };
    },
    [couponCode.pending]: (state) => {
      return { ...state, couponCodeData: [], iscouponCodeLoading: true };
    },
    [couponCode.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        iscouponCodeLoading: false
      };
    },
    [removecouponCode.fulfilled]: (state, { payload }) => {
      return { ...state, removecouponCodeData: payload, isremovecouponCodeLoading: false };
    },
    [removecouponCode.pending]: (state) => {
      return { ...state, couponCodeData: [], isremovecouponCodeLoading: true };
    },
    [removecouponCode.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        isremovecouponCodeLoading: false
      };
    },
    [placeOrder.fulfilled]: (state, { payload }) => {
      return { ...state, placeOrderData: payload, isplaceOrderLoading: false };
    },
    [placeOrder.pending]: (state) => {
      return { ...state, placeOrderData: [], isplaceOrderLoading: true };
    },
    [placeOrder.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        isplaceOrderLoading: false
      };
    },
    [labelDownload.fulfilled]: (state, { payload }) => {
      return { ...state, labelDownloadData: payload, islabelDownloadLoading: false };
    },
    [labelDownload.pending]: (state) => {
      return { ...state, labelDownloadData: [], islabelDownloadLoading: true };
    },
    [labelDownload.rejected]: (state, { payload, error }) => {

      return {
        ...state,
        error: payload?.error,
        islabelDownloadLoading: false
      };
    },

  },
});
export const { resetErrors } = CreatePayment.actions;
export default CreatePayment.reducer;
