/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Nav, Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../assets/styles/userProfile/userProfile.css";
import AddressBook from "../../../components/profileComponent/UserAddressCards";
import UserSavedCard from "../../../components/profileComponent/UserSavedCard";
import UserChangePassword from "../../../components/profileComponent/UserChangePassword";
import UserMyOrderDetails from "../../../components/profileComponent/UserMyOrderDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useModel } from "../../../utils/modalContext";
import {
  fetchAddressList,
  fetchUserCardDetails,
} from "../../../redux/address/adressThunk";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountryDetails } from "../../../redux/landing/landingThunk";
import {
  getParcels,
  orderDetails,
} from "../../../redux/user_profile/ProfileThunk";
import { resetErrors } from "../../../redux/address/addressSlice";
import { resetErrors as resetlanding } from "../../../redux/landing/landingSlice";
import UserProfile from "../../../components/profileComponent/UserProfile";
import UserParcels from "../../../components/profileComponent/UserParcels";
import DashboardUser from "../../../components/profileComponent/DashboardUser";
import UserWallet from "../../../components/profileComponent/UserWallet";
import SettingsIcon from "@mui/icons-material/Settings";
import UserSettings from "../../../components/profileComponent/UserSettings";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newsection = useParams();
  const country_Name = useSelector(
    (state) => state.landingReducer?.countryDeatils?.data
  );
  const userdata = useSelector(
    (state) => state?.profileData?.profileDetailData?.Profile?.data
  );
  const { activeSectionfinal, setActiveSectionfinal } = useModel("myorders");
  const [datachange, setdatachange] = useState();
  const [showBreadcrumb, setShowBreadcrumb] = useState(false);
  const [country_details, setCountry_details] = useState();
  const [service_Data, setservice_Data] = useState();
  const [activenewSection, setActivenewSection] = useState("");
  const [user_id, setUser] = useState();

  useEffect(() => {
    if (newsection?.section) {
      // setActiveSectionfinal(newsection?.section || activeSection);
      setActiveSectionfinal(newsection?.section);
      finalshow(newsection?.section);
      // setActiveSection(newsection?.section);
    } else {
      // setActiveSection("");
    }
  }, [newsection?.section]);

  useEffect(() => {
    if (activeSectionfinal) {
      setActivenewSection(newsection?.section || activeSectionfinal);
      finalshow(newsection?.section ||activeSectionfinal);
    }
  }, [activeSectionfinal]);

  useEffect(() => {
    dispatch(fetchCountryDetails());
  }, [dispatch]);

  useEffect(() => {
    const countrydetails = JSON.parse(localStorage.getItem("searchData"));
    const serviceData = JSON.parse(localStorage.getItem("serviceData"));
    const useriD = JSON.parse(localStorage.getItem("user"));

    if (countrydetails) {
      setCountry_details(countrydetails);
    }
    if (serviceData) {
      setservice_Data(serviceData);
    }
    if (useriD) {
      setUser(useriD);
      dispatch(orderDetails(useriD?.id));
    }
    if (userdata) {
      setUser(userdata);
    }
  }, [datachange, userdata]);

  useEffect(() => {
    dispatch(resetErrors());
    dispatch(resetlanding());
    if (user_id) {
      dispatch(
        fetchAddressList({
          customer_id: user_id?.id,
        })
      );
      dispatch(
        fetchUserCardDetails({
          customer_id: user_id?.id,
        })
      );
      dispatch(getParcels({ customer_id: user_id?.id }));
    }
  }, [user_id]);

  const handleSectionClick = (section) => {
    setActivenewSection(section);
    setActiveSectionfinal(section); // This updates the `useModel` state
    setShowBreadcrumb(false);
    navigate(`/user/${section}`);
  };

  const finalshow = (section) => {
    setActivenewSection(section);
    setActiveSectionfinal(section);
  };

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.clear();
    navigate("/");
    // window.location.reload();
  };

  return (
    <>
      <div className="user-profile-outer py-5">
        <Container className="px-3 px-sm-0 px-lg-0">
          <div className=" d-flex flex-column flex-lg-row mx-2 mx-sm-1 mx-lg-0 gap-4">
            <div className="" style={{ flex: "1" }}>
              <Card
                className="border-0 rounded-3 position-sticky"
                style={{ top: "70px" }}
              >
                <div className="profile-sidenav">
                  <div className="d-flex px-2 mt-3 mb-3">
                    <div className="px-2">
                      <img
                        src={`${window.location.origin}/assets/user-profile/maleusericon.svg`}
                        alt="user-icon"
                      />
                    </div>
                    <div className="user-profile-username-holder">
                      <p className="mb-1 m-0 lh-1">Hello</p>
                      <p className="m-0 lh-1">
                        <b className="me-2">{user_id?.first_name}</b>
                        <b>{user_id?.last_name}</b>
                      </p>
                    </div>
                  </div>
                  <Nav className="flex-column user-profile-navs">
                    {/* <Nav.Link eventKey="dashboard"
                      className={activeSectionfinal === "dashboard" ? "active" : ""}
                      onClick={() => handleSectionClick("dashboard")} >
                      <div className="d-flex navbar-tab-user">
                        <div className="user-sidenav-icon-holder  " style={{ flex: "1" }}>
                          <img src={`${window.location.origin}/assets/user-profile/dashboard.svg`}
                            alt="icon" className="nav-icon" />
                        </div>
                        <div className="d-flex justify-content-between w-100  " style={{ flex: "4" }}>
                          <div className="w-100">
                            <p className="m-0 text-nowrap ">Dashboard</p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link> */}

                    <Nav.Link
                      eventKey="myorders"
                      className={` ${
                        activenewSection === "myorders" ? "active" : ""
                      } myorder-nav`}
                      onClick={() => handleSectionClick("myorders")}
                    >
                      <div className="d-flex navbar-tab-user">
                        <div
                          className="user-sidenav-icon-holder"
                          style={{ flex: "1" }}
                        >
                          <img
                            src={`${window.location.origin}/assets/user-profile/myordericon.svg`}
                            alt="icon"
                            className="nav-icon"
                          />
                        </div>
                        <div
                          className="d-flex justify-content-between w-100"
                          style={{ flex: "4" }}
                        >
                          <div className="w-100">
                            <p className="m-0 text-nowrap ">My Orders</p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                    <Nav.Link
                      eventKey="wallet"
                      className={` ${
                        activenewSection === "wallet" ? "active" : ""
                      }`}
                      onClick={() => handleSectionClick("wallet")}
                    >
                      <div className="d-flex navbar-tab-user">
                        <div
                          className="user-sidenav-icon-holder  "
                          style={{ flex: "1" }}
                        >
                          <img
                            src={`${window.location.origin}/assets/user-profile/wallet.svg`}
                            alt="icon"
                            className="nav-icon"
                          />
                        </div>
                        <div
                          className="d-flex justify-content-between w-100  "
                          style={{ flex: "4" }}
                        >
                          <div className="w-100">
                            <p className="m-0 text-nowrap ">Wallet</p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                    {/* <Accordion defaultActiveKey={['0']} alwaysOpen>
                      <Accordion.Item eventKey="0" className="shadow-none border-0">
                        <Accordion.Header className="p-0 d-flex justify-content-center">
                          <div className="d-flex profile-account-information w-100 ps-2">
                            <div className="user-sidenav-icon-holder ps-2">
                              <img src={`${window.location.origin}/assets/user-profile/accountinsformation.svg`} alt="icon" className="nav-icon" />
                            </div>
                            <div className="text-nowrap ps-1">
                              <p className="m-0">Dashboard</p>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <>
                            <Nav.Link eventKey="dashboard"
                              className={activeSectionfinal === "dashboard" ? "" : ""}
                              onClick={() => handleSectionClick("dashboard")} >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div className="navbar-tab-user">
                                  <p className="m-0" >Dashboard</p>
                                </div>
                              </div>
                            </Nav.Link>

                            <Nav.Link eventKey="wallet"
                              className={activeSectionfinal === "wallet" ? "" : ""}
                              onClick={() => handleSectionClick("wallet")} >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div className="navbar-tab-user">
                                  <p className="m-0" >Wallet</p>
                                </div>
                              </div>
                            </Nav.Link>
                            <Nav.Link eventKey="Parcels"
                              className={activeSectionfinal === "Parcels" ? "" : ""}
                              onClick={() => handleSectionClick("Parcels")} >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div className="navbar-tab-user">
                                  <p className="m-0" > Parcels </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion> */}
                    <Accordion defaultActiveKey={["0"]} alwaysOpen>
                      <Accordion.Item
                        eventKey="0"
                        className="shadow-none border-0"
                      >
                        <Accordion.Header className="p-0 d-flex justify-content-center">
                          <div className="d-flex profile-account-information w-100 ps-2">
                            <div className="user-sidenav-icon-holder ps-2">
                              <img
                                src={`${window.location.origin}/assets/user-profile/accountinsformation.svg`}
                                alt="icon"
                                className="nav-icon"
                              />
                            </div>
                            <div className="text-nowrap ps-1">
                              <p className="m-0">Account Information </p>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="p-0">
                          <>
                            <Nav.Link
                              eventKey="profile"
                              className={` ${
                                activenewSection === "profile" ? "active" : ""
                              }`}
                              onClick={() => handleSectionClick("profile")}
                            >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div>
                                  <p style={{ margin: "0px" }}> Profile</p>
                                </div>
                              </div>
                            </Nav.Link>
                            <Nav.Link
                              eventKey="save-address"
                              className={
                                activenewSection === "save-address"
                                  ? "active"
                                  : ""
                              }
                              onClick={() => handleSectionClick("save-address")}
                            >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div>
                                  <p className="m-0">Address Book </p>
                                </div>
                              </div>
                            </Nav.Link>
                            <Nav.Link
                              eventKey="savecard"
                              className={
                                activenewSection === "savecard" ? "active" : ""
                              }
                              onClick={() => handleSectionClick("savecard")}
                            >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div>
                                  <p className="m-0">Saved Cards</p>
                                </div>
                              </div>
                            </Nav.Link>
                            <Nav.Link
                              eventKey="parcels"
                              className={
                                activenewSection === "parcels" ? "active" : ""
                              }
                              onClick={() => handleSectionClick("parcels")}
                            >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div className="navbar-tab-user">
                                  <p className="m-0">Saved Parcels </p>
                                </div>
                              </div>
                            </Nav.Link>
                            <Nav.Link
                              eventKey="change-password"
                              className={
                                activenewSection === "change-password"
                                  ? "active"
                                  : ""
                              }
                              onClick={() =>
                                handleSectionClick("change-password")
                              }
                            >
                              <div className="d-flex navbar-tab-user">
                                <div className="user-sidenav-icon-holder"></div>
                                <div className="navbar-tab-user">
                                  <p className="m-0"> Change Password </p>
                                </div>
                              </div>
                            </Nav.Link>
                          </>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <Nav.Link
                      eventKey="setting"
                      className={` ${
                        activenewSection === "setting" ? "active" : ""
                      }`}
                      onClick={() => handleSectionClick("setting")}
                    >
                      <div className="d-flex navbar-tab-user">
                        <div
                          className="user-sidenav-icon-holder  "
                          style={{ flex: "1", color: "#113468", width: "26px" }}
                        >
                          {/* <img src={`${window.location.origin}/assets/user-profile/wallet.svg`}
                            alt="icon" className="nav-icon" /> */}
                          <SettingsIcon fontSize="large" />
                        </div>
                        <div
                          className="d-flex justify-content-between w-100  "
                          style={{ flex: "4" }}
                        >
                          <div className="w-100">
                            <p className="m-0 text-nowrap ">Settings</p>
                          </div>
                        </div>
                      </div>
                    </Nav.Link>
                    <hr className="m-0" />
                    <Nav.Link onClick={logOut}>
                      <div className="d-flex">
                        <div className="user-sidenav-icon-holder">
                          <img
                            src={`${window.location.origin}/assets/user-profile/logouticon.svg`}
                            alt="icon"
                            className="nav-icon"
                            style={{ width: "26px" }}
                          />
                        </div>
                        <div>
                          <p className="m-0">Log Out </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav>
                </div>
              </Card>
            </div>
            <div className="" style={{ flex: "3" }}>
              {activenewSection === "myorders" && (
                <>
                  <UserMyOrderDetails />
                </>
              )}
              {activenewSection === "AccountInformation" && (
                <>
                  <>
                    <UserProfile setdatachange={setdatachange} />
                  </>
                </>
              )}
              {activenewSection === "profile" && (
                <>
                  <>
                    <UserProfile setdatachange={setdatachange} />
                  </>
                </>
              )}
              {activenewSection === "save-address" && (
                <>
                  <>
                    <AddressBook
                      country_Name={country_Name}
                      country_data={country_details}
                      userId={user_id}
                      serviceData={service_Data}
                      // addressList={addressList}
                    />
                  </>
                </>
              )}
              {activenewSection === "savecard" && (
                <>
                  <>
                    <UserSavedCard />
                  </>
                </>
              )}
              {activenewSection === "change-password" && (
                <>
                  <>
                    <UserChangePassword />
                  </>
                </>
              )}
              {activenewSection === "parcels" && (
                <>
                  <>
                    <UserParcels />
                  </>
                </>
              )}
              {activenewSection === "dashboard" && (
                <>
                  <>
                    <DashboardUser />
                  </>
                </>
              )}
              {activenewSection === "wallet" && (
                <>
                  <>
                    <UserWallet />
                  </>
                </>
              )}
              {activenewSection === "setting" && (
                <>
                  <>
                    <UserSettings />
                  </>
                </>
              )}
            </div>
          </div>
          {/* <new  *****************8end */}
        </Container>
      </div>
    </>
  );
};

export default Profile;
