import React, { useEffect, useRef, useState } from "react";
import { fetchShippingRates } from "../../../redux/landing/landingThunk";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { formatPrice } from "../../../utils/formatPrice";
import "../../../assets/styles/bulkOrders/bulkOrders.css";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { useModel } from "../../../utils/modalContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  fetchShippingServices,
  fetchpickupdropdata,
} from "../../../redux/services/ServicesThunk";

const BulkOrderBookServiceModal = ({
  // allerrors,
  singleId,
  showModalService,
  bookserviceid,
  setShipmentErrorsModal,
  filterData,
  showAllModalService,
  formData,
  bulkData,
  userAddressList,
  setisinsuranceopen,
  // setAllerrors,
  setshowpickupdrop,
  setBookserviceloading,
  bookServiceLoad,
  setbookServiceLoadingPopup,
  bookServiceLoadingPopup,
  ispickupdropLoading,
  bookserviceloading,
  setshowAddressModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uniquesessionid = localStorage.getItem("uniquesessionid");
  const useriD = JSON.parse(localStorage.getItem("user"));
  const hasFetchedShippingRates = useRef(false);
  const [allerrors , setAllerrors] = useState("")
  const serviceData = useSelector(
    (state) => state?.landingReducer?.landingShipServices
  );

  // const serviceDatanew = useSelector(
  //   (state) => state?.landingReducer?.landingShipServices
  // );

  const serviceDataLoading = useSelector(
    (state) => state?.landingReducer?.isLoading?.isShippingLoading
  );

  const getShipingRateserror = useSelector(
    (state) => state?.landingReducer?.landingShipServices?.errors?.fromCountryId
  );

  // const bookservicedatarates = useSelector(
  //   (state) => state?.landingReducer?.landingShipServices
  // );
  const user_id = JSON.parse(localStorage.getItem("user"));

  const [selectedService, setSelectedService] = useState(
    serviceData?.data?.[0] || ""
  );

  useEffect(() => {
    setSelectedService(serviceData?.data?.[0]);
  }, [serviceData]);


  const cart_id = localStorage.getItem("cart_id");
  const addservice = () => {
    setAllerrors("")
    if (selectedService) {
      setbookServiceLoadingPopup(true);
      dispatch(
        fetchShippingServices({
          channel_rate_option: filterData?.channel_rate_option,
          channel_courier_id: filterData?.courier_id,
          customer_id: user_id?.id,
          cart_id: cart_id ? cart_id : "",
          calculatedSubtotal: selectedService?.calculatedSubtotal,
          baseSubtotal: selectedService?.baseSubtotal,
          service_title: selectedService?.title,
          length: selectedService?.length,
          height: selectedService?.height,
          weight: selectedService?.weight,
          width: selectedService?.width,
          weight_in: selectedService?.weight_in,
          size_in: selectedService?.size_in,
          courier_name: selectedService?.courier_name,
          courier_service_code: selectedService?.courier_service_code,
          delivery_date: selectedService?.delivery_date,
          customer_account_type: user_id?.account_type,
          // coupon_code:selectedService?.coupon_code,
          courier_id: selectedService?.courier_id,
          courier_service_name: selectedService?.courier_service_name,
          fromPostalCode: selectedService?.fromPostalCode,
          fromCountryCode: selectedService?.fromCountryCode,
          toCountryCode: selectedService?.toCountryCode,
          toPostalCode: selectedService?.toPostalCode,
          fromCountryId: userAddressList?.country?.id,
          toCountryId: bulkData?.country?.id,
          qty: selectedService?.qty,
          uniquesessionid: uniquesessionid,
          channelType: "Bulk",
          channel_order_id: bulkData?.id,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.status === true && response.statusCode === 200) {
            // setBookserviceloading(false)
            const cart_id = localStorage.setItem(
              "cart_id",
              response?.data?.cart_details?.cart_id
            );
            const cartdetail_id = localStorage.setItem(
              "cartdetail_id",
              response?.data?.cart_details?.id
            );

            dispatch(
              fetchpickupdropdata({
                // is_pickup_rate: "Yes",
                customer_id: user_id?.id,
                cart_id: response?.data?.cart_details?.cart_id,
                cartdetail_id: response?.data?.cart_details?.id,
                uniquesessionid: uniquesessionid,
              })
            )
              .unwrap()
              .then((response) => {
                setbookServiceLoadingPopup(false);
                if (response?.DropPickupData?.data?.pickup_date?.length) {
                  if (!response?.DropPickupData?.data?.pickup_date[0]?.error || response?.DropPickupData?.data?.pickup_date[0]?.value !== null) {
                    setshowpickupdrop(true);
                    setisinsuranceopen(false);
                  } else {
                    setAllerrors(response?.DropPickupData?.data?.pickup_date[0]?.error || "Your Address is not valid please check");
                  }
                }
              });
            // Swal.fire({
            //     toast: true,
            //     icon: "success",
            //     title: "Service Added Successfully",
            //     animation: true,
            //     position: "top-right",
            //     showConfirmButton: false,
            //     showCloseButton: true,
            //     timer: 2000,
            //     timerProgressBar: true,
            //     customClass: {
            //         timerProgressBar: "custom-progress-bar",
            //     },
            // });
          } else if (response?.statusCode === 400 && response.status === false) {
            setAllerrors(response?.errors);
            // setBookserviceloading(false)
          } else if (response.statusCode === 440 && response.status === false) {
            // setBookserviceloading(false)
            Swal.fire({
              icon: "warning",
              title: "Oops...",
              text: "Something went wrong! please try some time later",
              timer: 5000,
            }).then((result) => {
              if (
                result.isConfirmed ||
                result.dismiss === Swal.DismissReason.timer
              ) {
                // navigate("/");
                window.location.reload();
              }
            });
          }
        })
        .catch((error) => {
          setbookServiceLoadingPopup(false);
          setBookserviceloading(false);
          setAllerrors(error?.errors);
        });
    } else {
      setAllerrors("Please Select Service First");
    }
  };
  useEffect(() => {
    if (hasFetchedShippingRates.current) return;
    if (bulkData) {
      dispatch(
        fetchShippingRates({
          fromCountryCode: userAddressList?.country?.code,
          fromPostalCode: userAddressList?.postcode,
          toPostalCode: bulkData?.postcode || "",
          toCountryCode: bulkData?.country?.code || "",
          fromCountryId: userAddressList?.country?.id,
          toCountryId: bulkData?.country?.id,
          package_type: "Package",
          customer_id: useriD ? useriD?.id : "",
          weight: [String(bulkData?.parcel_weight)] || [""],
          height: [String(bulkData?.parcel_height)] || [""],
          length: [String(bulkData?.parcel_length)] || [""],
          width: [String(bulkData?.parcel_width)] || [""],
          weight_in: bulkData?.weight_in ? [bulkData?.weight_in] : ["LBS"],
          size_in: bulkData?.size_in ? [bulkData?.size_in] : ["IN"],
          // view_all: "Yes",
          qty: ["1"],
          coupon_code: "",
          uniquesessionid: uniquesessionid,
          channel_order_id: bulkData?.id,
          channel_courier_id: filterData?.courier_id,
          channel_rate_option: filterData?.channel_rate_option,
        })
      )
        .unwrap()
        .then((response) => {
          setBookserviceloading(false);
          if (response?.statusCode === 503 && response.status === false) {
            setAllerrors(response?.errors?.invalid_data);
            // setBookserviceloading(false)
          }
        })
        .catch((error) => {
          setBookserviceloading(false);
        });

      hasFetchedShippingRates.current = true;
    }
  }, [bulkData, dispatch, userAddressList, useriD, uniquesessionid]);
 
  const handleDropdownSelect = (service) => {
    setSelectedService(service);
  };

  const setopenaddressmodel = () => {
    setshowAddressModal(true);
  };

  const matchedItem = formData.find(item => item.id === bookserviceid);
  return (
    <>
      {/* {!showAllModalService && (
      {allerrors && matchedItem ? (
        <>
          <p className="text-danger error6">
            {allerrors}
          </p>
        </>
      ) : ( */}
        <>
        {allerrors && matchedItem && singleId &&(
        <>
          <p className="text-danger error6">
            {allerrors}
          </p>
        </>
      )}
          {singleId && (
            <>
              {!bookServiceLoad ? (
                <>
                  {!getShipingRateserror ? (
                    <>
                      <div className="bulk-address">
                        <div className="d-flex w-100 d-flex align-items-center">
                          <div className="me-4">
                            <p className="collectoinh5 blue-text" style={{ width: "max-content" }}  >
                              <span>Choose Service</span>
                            </p>
                          </div>
                          <div className="w-100">
                            <hr className="w-100" style={{ border: "2px solid #bababa" }} />
                          </div>
                        </div>

                        <div className="addressbook-outer-holder ">
                          <div
                            className={`address-bg box-shadow is-valid-paper mb-3 w-100 d-flex align-items-center ${selectedService ? "h-auto p-0" : "height-44"}`} style={{ border: "1px solid #e9e9e9" }}  >
                            <Dropdown className="w-100">
                              <Dropdown.Toggle
                                id="dropdown-button-dark-example1"
                                variant="secondary"
                                className="d-flex dropdown-toggle py-0 w-100 justify-content-between align-items-center border-0"
                              >
                                <div className="d-flex align-items-center">
                                  <p className="mb-0 select-input">
                                    {selectedService ? (
                                      <div className="d-flex w-auto gap-3">
                                        <div className="d-flex align-items-center">
                                          <img src={selectedService?.cardlogo} alt="Service Icon"
                                            style={selectedService?.courier_name === "UPS" ? { width: "25px", height: "40px" } : { width: "40px", height: "40px" }} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {selectedService?.title}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          •
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {formatPrice(selectedService?.calculatedSubtotal)}
                                        </div>
                                      </div>
                                    ) : ("Select Service")}
                                  </p>
                                </div>
                                <div className="dropdown-icon">
                                  <img src={`${window.location.origin}/assets/dropdownicon.svg`} alt="Dropdown" />
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="dropdown-menu-collection">
                                {serviceDataLoading && (
                                  <Dropdown.Item>
                                    <Spinner animation="border" size="sm" />
                                  </Dropdown.Item>
                                )}
                                {serviceData?.data === undefined ? (
                                  <Dropdown.Item>No Data Found...</Dropdown.Item>
                                ) : (
                                  serviceData?.data?.map((service, index) => (
                                    <Dropdown.Item className="address-dropdown-item py-0" key={index} onClick={() => handleDropdownSelect(service)}   >
                                      <div className="d-flex w-auto gap-3">
                                        <div className="d-flex align-items-center">
                                          <img src={service?.cardlogo} alt="Service Icon"
                                            style={service?.courier_name === "UPS" ? { width: "25px", height: "40px" } : { width: "40px", height: "40px" }} />
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {service?.title}
                                        </div>
                                        <div className="d-flex align-items-center">
                                          •
                                        </div>
                                        <div className="d-flex align-items-center">
                                          {formatPrice(service?.calculatedSubtotal)}
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                  ))
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <Button className="primarybtn btn height-40 w-100 py-2" onClick={() => addservice()} >
                            {bookserviceloading || ispickupdropLoading ? (
                              <>
                                <Spinner animation="border" size="sm" />
                              </>
                            ) : (
                              <>Add This Service</>
                            )}
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {getShipingRateserror && (
                        <>
                          <p className="text-danger error7 ">{getShipingRateserror}</p>
                          <div className="d-flex gap-3 align-items-center mb-2">
                            <p className="text-danger">
                              Please add collection address
                            </p>
                            <Button className="address_btn border text-nowrap" variant="link" onClick={() => { setopenaddressmodel(); }}  >
                              Add collection address
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="py-3">
                    <Box className="gap-3 d-flex align-items-center">
                      <Skeleton animation="wave" className="w-25" variant="rounded" />
                      <Skeleton
                        animation="wave"
                        className="w-75"
                        height="7px"
                        variant="rounded"
                      />
                    </Box>
                    <Box className="mt-2 d-flex align-items-center">
                      <Skeleton
                        animation="wave"
                        className="w-100"
                        height="40px"
                        variant="rounded"
                      />
                    </Box>
                    <Box className="mt-2 d-flex align-items-center">
                      <Skeleton
                        animation="wave"
                        className="w-100"
                        height="30px"
                        variant="rounded"
                      />
                    </Box>
                  </div>
                </>
              )}
            </>
          )}
        </>
      {/* )} */}
      {/* )} */}
    </>
  );
};

export default BulkOrderBookServiceModal;
