import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Card } from "react-bootstrap";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import "../../assets/styles/addressBook/selectDateTime.css";
import { Accordion } from "react-bootstrap";
import "../../assets/styles/userProfile/userProfile.css";

const UserTrackShip = ({ isshowfirst, trackdataold }) => {
  const trackdata = [{ ...trackdataold }];
  const [selectOpen, setSelectOpen] = useState(false);

  const stepActive = trackdataold?.currentStatusNumber - 1;
  const activeStep = stepActive >= 1 ? stepActive : 0;

  const getTrackColor = (trackdataold) => {
    if (trackdataold?.currentStatus === "Exception" && activeStep === 3) {
      return "#D92C41";
    } else if (trackdataold?.currentStatus === "Delivered" & activeStep === 5) {
      return "#36A635";
    } else {
      return "#36A635";
    }
  }
  const StepperDesktop = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-55% + 16px)",
      right: "calc(40% + 10px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: getTrackColor(trackdataold),
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: "#36A635",
        transition: "border-color 300s ease",
        // borderColor: activeStep === 3 ? "#D92C41" : "#36A635",
        borderColor: getTrackColor(trackdataold),

      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 4,
      borderRadius: 1,
    },
  }));
  const StepperMobile = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 100,
      left: "calc(-55% + 89px)",
      right: "calc(60% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: activeStep === 3 ? "#D92C41" : "#36A635",
        borderColor: getTrackColor(trackdataold),

      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        // borderColor: activeStep === 3 ? "#D92C41" : "#36A635",
        borderColor: getTrackColor(trackdataold),
        borderTopWidth: 5,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[900] : "#eaeaf0",
      borderRadius: 1,
      borderWidth: 4,
      marginLeft: -2,
      marginTop: -10,
      height: 110,
    },
  }));
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 20,
    alignItems: "center",
    ...(ownerState.active && {
      color: getTrackColor(trackdataold),

    }),
    "& .QontoStepIcon-completedIcon": {
      borderRadius: "50%",
      backgroundColor: getTrackColor(trackdataold),

      width: 25,
      height: 25,
      zIndex: 2,
    },
    "& .QontoStepIcon-circle": {
      width: 20,
      height: 20,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      zIndex: 3,
    },
  }));
  const QontoStepIconRootmobile = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 20,
    alignItems: "center",
    ...(ownerState.active && {
      // color: "#36A635",
      color: getTrackColor(trackdataold),

    }),
    "& .QontoStepIcon-completedIcon": {
      borderRadius: "50%",
      // backgroundColor: "#36A635",
      backgroundColor: getTrackColor(trackdataold),

      width: 25,
      height: 25,
      zIndex: 2,
    },
    "& .QontoStepIcon-circle": {
      width: 25,
      height: 25,
      borderRadius: "50%",
      backgroundColor: "currentColor",
      zIndex: 3,
    },
  }));
  const QontoStepLabel = styled(StepLabel)({
    labelPlacementStart: {
      flexDirection: "column",
      alignItems: "center",
    },
  });
  const toggleAccordion = () => {
    setSelectOpen(!selectOpen);
  };

  const steps = [
    "Ordered",
    "Dispatched",
    "In-Transsit",
    "Exception",
    "Out for Delivery",
    "Delivered",
  ];

  const getTrackImage = (trackdataold) => {
    // return trackdata.map((item, index) => {
    let imageUrl;
    if (trackdataold?.currentStatus === "Exception" && activeStep === 3) {
      imageUrl = `${window.location.origin}/assets/track/inactivearrow.svg`;
    } else if (trackdataold?.currentStatus === "Delivered" && activeStep === 5) {
      imageUrl = `${window.location.origin}/assets/track/completed.svg`;
    } else {
      imageUrl = `${window.location.origin}/assets/track/activearrow.svg`;
    }
    return { imageUrl };
    // });
  };

  const TrackImageUrl = getTrackImage(trackdataold, activeStep);
  function QontoStepIconDesktop(props) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="QontoStepIcon-completedIcon" />
        ) : active ? (
          <img
            src={TrackImageUrl?.imageUrl}
            alt="Personal Icon"
            className="QontoStepIcon-image"
          />
        ) : (
          <Check className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  function QontoStepIconmobile(props) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRootmobile ownerState={{ active }} className={className}>
        {completed ? (
          <div className="QontoStepIcon-completedIcon" />
        ) : active ? (
          <img
            src={TrackImageUrl?.imageUrl}
            alt="Personal Icon"
            className={`QontoStepIcon-image-mobile ${(activeStep === 3 || activeStep === 5) && "finalStep-mobile"
              }`}
          />
        ) : (
          <Check className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRootmobile>
    );
  }

  return (
    <>
      <div className="d-flex gap-4 flex-column">
        {trackdata?.length &&
          <>
            {trackdata?.map((track, index) => (
              <>
                <Card key={index} className="card-border">
                  <Card.Body className="d-flex flex-column align-items-center p-4 gap-4">
                    <div className="trackship-component user-trackship w-100 ">
                      <>
                        <div>
                          <div className="d-flex justify-content-between flex-column flex-lg-row">
                            {isshowfirst && (
                              <>
                                <div style={{ flex: "3" }}>
                                  <div>
                                    <p className="order-confirm-p-2 paragraph2 d-flex text-nowrap">
                                      <span className="order-confirm-p-head paragraph2 order-details-track">
                                        Order ID
                                      </span>{" "}
                                      <span className="me-2">:</span>
                                      <span className="text-wrap">
                                        #{track?.order_id}
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="order-confirm-p-2 paragraph2 d-flex text-nowrap">
                                      <span className="order-confirm-p-head paragraph2 order-details-track">
                                        Order Date & Time
                                      </span>
                                      <span className="me-2">:</span>
                                      <span className="text-wrap flex-lg-nowrap ">
                                        {track?.order_date}
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p className="order-confirm-p-2 paragraph2 d-flex text-nowrap">
                                      <span className="order-confirm-p-head paragraph2 order-details-track">
                                        Expected Delivery
                                      </span>
                                      <span className="me-2">:</span>
                                      <span className="text-wrap flex-lg-nowrap ">
                                        {track?.deliveryDate}
                                      </span>
                                    </p>
                                  </div>
                                </div>

                                <div className="" style={{ flex: "2" }}>
                                  <h5>{""}</h5>
                                </div>

                                <div>
                                  <div className="d-flex justify-content-center">
                                    <div className="track-ship-img-holder">
                                      <img src={track?.courier_logo} alt="Track Ship" />
                                    </div>
                                  </div>
                                  <div>
                                    <p>{track?.courier_service}</p>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>

                      <div className="trackpad d-none d-sm-block d-lg-block ">
                        <Box className="w-100">
                          <Stack sx={{ width: "100%" }} className="">
                            <Stepper className=""
                              alternativeLabel
                              activeStep={activeStep}
                              connector={<StepperDesktop />}
                            >
                              {steps?.map((label, index) => (
                                <Step className=""
                                  key={label}
                                  data-tooltip-id={`my-tooltip-${index}`}
                                >
                                  <div className="parent-tooltip m-0 ">
                                    {/* <Tooltip
                                      title={`Tooltip for ${label}`}
                                      arrow
                                      classes={{ arrow: "custom-tooltip-arrow" }}
                                      className="trackship-tooltip"
                                    > */}
                                    <QontoStepLabel className=""
                                      StepIconComponent={QontoStepIconDesktop}
                                      classes={{ labelPlacementStart: "labelPlacementStart" }}
                                      optional={<div className="track-label">{label}</div>}
                                    />
                                    {/* </Tooltip> */}
                                  </div>
                                </Step>
                              ))}
                            </Stepper>
                            {/* <Stepper alternativeLabel activeStep={activeStep} connector={<StepperDesktop />}>
                              {steps?.map((label, index) => (
                                <Step key={label}>
                                  <Tooltip title={label} placement="top" arrow enterTouchDelay={0}>
                                    <StepLabel StepIconComponent={QontoStepIconDesktop}>{label}</StepLabel>
                                  </Tooltip>
                                </Step>
                              ))}
                            </Stepper> */}

                          </Stack>
                        </Box>
                      </div>



                      <div className="d-block d-lg-none d-sm-none mt-4 ms-4 d-sm-block">
                        <Box className="w-100">
                          <Stepper
                            activeStep={activeStep}
                            orientation="vertical"
                            connector={<StepperMobile />}
                          >
                            {steps?.map((label, index) => (
                              <Step
                                key={label}
                                data-tooltip-id={`my-tooltip-${index}`}
                              >
                                <div className="parent-tooltip position-relative">
                                  <QontoStepLabel
                                    StepIconComponent={QontoStepIconmobile}
                                    classes={{
                                      labelPlacementStart: "labelPlacementStart",
                                    }}
                                    optional={<div>{label} </div>}
                                  />
                                  {/* {activeStep === index && (
                                    <>
                                      <Card className={`label-active-mgs  ms-4 position-absolute p-2 ${activeStep === 4 && `thisclass`}`} >
                                        {`Active Step: ${label}`}
                                        <p className="lh-1">test </p>
                                        <p className="lh-1">test </p>
                                        <p className="lh-1">test </p>
                                        <p className="lh-1">test </p>
                                      </Card>
                                    </>
                                  )} */}
                                </div>
                              </Step>
                            ))}
                          </Stepper>
                        </Box>
                      </div>


                    </div>
                    <div className="d-flex justify-content-center w-100">
                      <div className="shipprogressouter" style={{ width: "90%" }}>
                        <div className="shipmentprogresscomponent">
                          <div>
                            <Accordion defaultActiveKey="0" className="py-0">
                              <Accordion.Item eventKey="1" style={{ border: "none" }}>
                                <div className="select-accordion  progress-table-ship">
                                  <div className="shipprogress-header-holder">
                                    <Accordion.Header>
                                      <div className="d-flex justify-content-between align-items-center w-100" style={{ color: "#E7EBF0" }} onClick={toggleAccordion} >
                                        <div className="w-100">
                                          <p className="blue-text mb-0 paragraph1"
                                            style={{ fontWeight: "400", fontSize: "20px" }} >
                                            Shipment Progress
                                          </p>
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                  </div>
                                  <Accordion.Body className="usertrack-table" style={{ padding: "0px" }}>
                                    <table style={{ height: "20px" }}>
                                      <thead className="user-table-head">
                                        <tr className="text-nowrap user-table-row w-100">
                                          <th width={"70px"} className="text-start">No</th>
                                          <th className="text-start" width={"300px"}>Date & Time</th>
                                          <th className="text-start" width={"200px"} >Location</th>
                                          <th className="text-start" width={"500px"}>Activity</th>
                                        </tr>
                                      </thead>

                                      <tbody>
                                        {track?.activities && track.activities.length > 0 ? (
                                          track.activities.map((activities, index) => (
                                            <tr className="user-wallet-data " key={index}>
                                              <td className="text-start">{index + 1}</td>
                                              <td className="text-start text-nowrap">{activities?.date} {"  "} {activities?.time}</td>
                                              <td className="text-nowrap text-start">{activities?.location?.city},{" "}{activities?.location?.country}</td>
                                              <td className="text-start">{activities.status?.description}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <>
                                            <tr className="border border-2">
                                              <td colSpan="4" className="text-center">
                                                <h5 className="py-5">No data found....</h5>
                                              </td>
                                            </tr>

                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </Accordion.Body>
                                </div>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card >
              </>
            ))}
          </>
        }
      </div >
    </>
  );
};

export default UserTrackShip;
